import React, { useState, useRef,useEffect } from 'react';
import {
    DUITNOW_LOGO_PATH,
    DUITNOW_LOGO2_PATH,
    PAYNET_LOGO2_PATH,
    PHONE_LAPTOP_LOGO_PATH,
    SERVICES_LOGO_PATH,
    UOB_BANK_LOGO_PATH,
    RHB_BANK_LOGO2_PATH,
    RHB_BANK_LOGO_PATH
} from "../../../constants/logo";
import { ClockIcon } from "@heroicons/react/24/outline";

const DuitnowInstructions: React.FC = () => {
    
    const instructionsSet1 = useRef<HTMLDivElement>(null);
    const instructionsSet2 = useRef<HTMLDivElement>(null);
    const [buttonText1, setButtonText1] = useState<string>('view more');
    const [buttonText2, setButtonText2] = useState<string>('view more');
    const [seconds, setSeconds] = useState<number>(300); // 300 seconds = 5 minutes
    const [isActive, setIsActive] = useState<boolean>(true);
  
    useEffect(() => {
      let intervalId: ReturnType<typeof setTimeout> | null = null;
      
      if (isActive) {
        intervalId = setTimeout(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);
      }
  
      return () => {
        if (intervalId) {
          clearTimeout(intervalId);
        }
      };
    }, [isActive, seconds]);
  
    const toggleTimer = () => {
      setIsActive(!isActive);
    };
  
    const resetTimer = () => {
      setIsActive(false);
      setSeconds(300);
    };
  
    const formatTime = (time: number): string => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleClick = (
        ref: React.RefObject<HTMLDivElement>,
        setButtonText: React.Dispatch<React.SetStateAction<string>>,
        currentText: string,
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (ref.current) {
            ref.current.classList.toggle('h-0');
        }

        const button = e.currentTarget;
        if (button.classList.contains('bg-green-800')) {
            button.classList.remove('bg-green-800');
            button.classList.remove('text-white');
            button.classList.remove('px-8');
            button.classList.add('text-green-800');
            button.classList.add('px-7');
            button.classList.add('bg-white');
        }
        else if (button.classList.contains('bg-white')) {
            button.classList.add('bg-green-800');
            button.classList.add('text-white');
            button.classList.add('px-8');
            button.classList.remove('px-7');
            button.classList.remove('text-green-800');
            button.classList.remove('bg-white');
        }

        const newText = currentText === 'click to hide' ? 'view more' : 'click to hide';
        setButtonText(newText);
    };

    return (
        <div>
            <div id="timer" className='text-center'>
                <ClockIcon className='bg-red-400 p-3 rounded-full h-20 border-red-200 border-[12px] text-white mx-auto' />
            </div>
            <h4 className='text-xl font-bold text-center my-4 line tracking-tighter'>Please go to your linked banking app for DuitNow ID consent</h4>
            <h5 className='text-black  text-center text-[15px] p-2  tracking-tight'>
                The indicated amount is the transaction limit;
                <br />
                <span className='text-md text-gray-600 font-bold'>NO</span> money will be deducted upon giving consent
            </h5>

            <span className="relative top-[10px] mt-1 left-4 px-1 text-black text-sm font-bold bg-white tracking-tight">RHB users</span>
            <div className="p-3 rounded-xl border-1 border-gray-400">
                <div className='flex justify-between items-center gap-x-4'>
                    <img src={RHB_BANK_LOGO_PATH} alt="RHB Bank Logo" className='h-20' />
                    <button
                        type="button"
                        className='bg-green-800 text-white border-1 border-green-800  text-sm font-bold tracking-tight py-3 px-8 rounded-full'
                        onClick={(e) => handleClick(instructionsSet1, setButtonText1, buttonText1, e)}
                    >
                        {buttonText1}
                    </button>
                </div>
                <div ref={instructionsSet1} className='overflow-hidden h-0'>
                    <div className='flex justify-center items-center gap-x-4'>
                        <img src={RHB_BANK_LOGO2_PATH} alt="RHB Bank Logo 2" className='h-16' />
                        <p className='tracking-tight'>Please look out for a notification from RHB mobile banking app</p>
                    </div>
                    <div className='flex justify-center items-center gap-x-4'>
                        <img src={PHONE_LAPTOP_LOGO_PATH} alt="Phone and Laptop" className='h-12' />
                        <p className='tracking-tight'>Follow the instructions to approve the consent request on both web and in-app</p>
                    </div>
                </div>
            </div>

            <span className="relative top-[10px] mt-1 left-4 px-1 text-black text-sm font-bold bg-white tracking-tight">UOB users</span>
            <div className="p-3 rounded-xl border-1 border-gray-400">
                <div className='flex justify-between items-center gap-x-4'>
                    <img src={UOB_BANK_LOGO_PATH} alt="UOB Bank Logo" className='h-20' />
                    <button
                        type="button"
                        className='text-white border-1 border-green-800 bg-green-800 text-sm font-bold tracking-tight py-3 px-8 rounded-full'
                        onClick={(e) => handleClick(instructionsSet2, setButtonText2, buttonText2, e)}
                    >
                        {buttonText2}
                    </button>
                </div>
                <div className="overflow-hidden transition-all ease-in-out delay-150 h-0"  style = {{ transition: 'height 3s ease-in-out' }} ref={instructionsSet2}>
                    <div className='flex justify-center items-center gap-x-4'>
                        <img src={UOB_BANK_LOGO_PATH} alt="UOB Bank Logo" className='h-16' />
                        <p className='tracking-tight'>Login to <span className='text-gray-600 font-bold text-md'>UOB TMRW</span> mobile banking app</p>
                    </div>
                    <div className='flex justify-center items-center gap-x-4'>
                        <img src={SERVICES_LOGO_PATH} alt="Services" className='h-20 ms-[-5px]' />
                        <p className='tracking-tight'>Go to <span className='text-gray-600 font-bold text-md'>Services</span> and click on <span className='text-gray-600 font-bold text-md'>DuitNow AutoDebit</span></p>
                    </div>
                    <div className='flex justify-center items-center gap-x-4'>
                        <img src={DUITNOW_LOGO2_PATH} alt="DuitNow Logo 2" className='h-20' />
                        <div>
                            <p className='tracking-tight'>Click on the Pending request from Razer Merchant Services Sdn Bhd</p>
                            <p className='tracking-tight'>Proceed to review and complete the approval request</p>
                        </div>
                    </div>
                </div>
            </div>

            <h4 className='text-md font-semibold text-center text-gray-600 mt-5'>Estimated time for confirmation: <span className='text-red-500'>{formatTime(seconds)}</span></h4>
            {/* <div className='text-center text-sm font-bold text-gray-600 mb-5 mt-5'>
        Authorised by {' '}
        <img src={PAYNET_LOGO2_PATH} alt="Paynet" className='inline h-[14px] align-middle' />
      </div> */}
        </div>
    );
}

export default DuitnowInstructions;
