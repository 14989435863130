import React, { useState } from 'react';

const LoadingPopup: React.FC = () => {   
    return (

        <div className='submiting'>
            <div className='spinner   mx-auto mt-10  '>
            </div>

            <h5 className='text-xl p-10 pb-0 text-center text-green-800 my-2 font-semibold' >Redirecting  </h5>
            <p className='text-center  text-[15px] text-wrap font-semibold' >Please do not <span className='text-red-500' >close </span  >or <span className='text-red-500' >refresh </span>the window while we are saving your changes</p>

        </div>

    );
};

export default LoadingPopup;
