//@ts-check
import { Link, useParams } from 'react-router-dom'
import CampaignCard from '../../../components/campaign/card'
import { campaignData as cd } from '../../../dummy/campaignData'

const ActiveCampaigns = ({ campaignData = cd }) => {
  const params = useParams()
  console.log('Params', params)
  return (
    <div className="mx-auto flex max-w-[calc(100%_-_2rem)] flex-wrap">
      { campaignData.map((campaign, index) => (
        <Link
          to={ `${campaign.campaignId}` }
          className="basis-1/2 p-2 sm:basis-1/3 md:basis-1/5 lg:basis-1/5"
          key={ index }>
          <CampaignCard key={ index } campaign={ campaign } />
        </Link>
      )) }
    </div>
  )
}


export default ActiveCampaigns