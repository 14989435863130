import React from 'react'
import { SUCCESS_LOGO_PATH } from '../../../constants/logo'
import { getCurrentDateAndTime } from '../../../utils/getCurrentDateAndTime'
interface SuccessPopupParams {
  onClose: () => void;
  transactionLimit?: number,
  status?: string,
  type: string,
}
const SuccessPopup: React.FC<SuccessPopupParams> = ({ onClose, transactionLimit, status, type }) => {
  return (
    <div>
      <img src={SUCCESS_LOGO_PATH} className='h-32  mx-auto' alt="" />
      {
        type === 'resetPIN' ? (
          <h2 className='text-sm  md:text-lg text-center font-bold mt-2 mb-3 ' >Your PIN has been updated successfully</h2>

        ) : (
          status === 'turnedOn' ? (
            <>
              <h2 className='text-sm  md:text-lg text-center font-bold mt-2 mb-5 ' >PIN is no longer required for transactions below the selected limit</h2>
              <div className='flex justify-between items-center' >
                <span className=' text-sm md:text-md' >Status</span>
                <span className='font-bold text-sm md:text-md' >Success</span>
              </div>
              <div className='flex justify-between items-center' >
                <span className=' text-sm md:text-md' >Date & Time</span>
                <span className='font-bold text-sm md:text-md' >{getCurrentDateAndTime()}</span>
              </div>
              <div className='flex justify-between items-center' >
                <span className=' text-sm md:text-md' >Transaction Limit</span>
                <span className=' text-sm font-bold  md:text-md' >RM  {transactionLimit}</span>
              </div>
            </>
          ) : (
            <>
              <h2 className='text-sm  md:text-lg text-center font-bold mt-2 mb-3 ' >PIN is now required for all transactions</h2>
              <div className='flex justify-between items-center' >
                <span className=' text-sm md:text-md' >Status</span>
                <span className='font-bold text-sm md:text-md' >Success</span>
              </div>
              <div className='flex justify-between items-center' >
                <span className=' text-sm md:text-md' >Date & Time</span>
                <span className='font-bold text-sm md:text-md' >{getCurrentDateAndTime()}</span>
              </div>
            </>
          ))
      }
      <button
        type="button"
        className=' bg-green-800 text-white capitalize mx-auto rounded-3xl py-3 px-9 mt-10 text-center font-bold block border-green-800 border-1 w-full'
        style={{ opacity: true ? 1 : 0.5 }} // Adjust opacity condition as needed
        onClick={onClose}
      >
        Done
      </button>
    </div>
  )
}

export default SuccessPopup
