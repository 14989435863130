import React, { useState } from 'react'
import { DUITNOW_LOGO_PATH } from '../../../constants/logo'
import { styled } from '@mui/material/styles'
import { TrashIcon } from '@heroicons/react/24/outline'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import DeleteConfirmation from './DeleteConfirmation'
import SetPreferedConfirmation from './SetPreferredConfirmation'
import type { PaymentConsent } from '../../../core/api/http-methods/get-payment-methods'

interface MethodProps {
  method: PaymentConsent
  handlePaymentMethodsModified: () => void
}
// Rename IOSSwitch to SwitchButton
const SwitchButton = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#9A9A9A',
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#5CB891',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

const DuitnowMethod: React.FC<MethodProps> = ({
  method,
  handlePaymentMethodsModified,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isPreferredMethodModelOpen, setIsPreferredMethodModelOpen] =
    useState<boolean>(false)
  const handleChangePreferredMethod = () => {
    setIsPreferredMethodModelOpen(true)
  }
  const handleDeleteMethod = () => {
    setIsDeleteModalOpen(true)
  }
  return (
    <div
      className="sm:w-100 my-4 min-w-[340px] max-w-[370px] rounded-3xl bg-gradient-to-r px-6 pb-1 pt-4 text-white shadow-lg sm:mx-auto md:mx-1 md:w-1/2 lg:w-1/2"
      style={{
        background: `linear-gradient(-55.329669692156095deg, rgba(248, 99, 64, 1) 0.000005960464117517737%, rgba(254, 199, 69, 1) 100.00000000000234%)`,
      }}>
      <div className="flex items-center justify-between">
        <h6 className="text-xs">Your Preferred Payment Method</h6>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={() => handleDeleteMethod()}
            aria-label="Delete">
            <TrashIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-1 px-3">
        <FormControlLabel
          control={
            <SwitchButton
              checked={method?.preferred}
              onChange={handleChangePreferredMethod}
            />
          }
          label=""
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="">
          <span className="mt-4 block text-xs font-light">Method</span>
          <h2 className="text-xl font-bold tracking-wider">Duitnow</h2>
        </div>
        <div>
          <img
            src={DUITNOW_LOGO_PATH}
            alt="Duitnow Logo"
            className="inline-block h-[40px] object-contain align-middle"
          />
        </div>
      </div>
      <div className="mb-3">
        <h6 className="text-xs"> Duitnow ID (National Identity Card Number)</h6>
        <h6 className="mt-1 text-xs font-bold">
          {method?.payment_consent_id}{' '}
        </h6>
      </div>
      <div className="mb-2 flex items-center justify-start gap-x-10">
        <div>
          <h4 className="text-xs font-light">Status</h4>
          <h3 className="text-md font-bold tracking-wide"> {method?.status}</h3>
        </div>
        <div>
          <h4 className="text-xs font-light">Added</h4>
          <h3 className="text-md font-bold tracking-wide">
            {' '}
            {method?.created_at?.toString()}
          </h3>
        </div>
      </div>
      {isDeleteModalOpen ? (
        <DeleteConfirmation
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          consentId={method?.payment_consent_id}
          handlePaymentMethodsModified={handlePaymentMethodsModified}
        />
      ) : (
        <></>
      )}
      {isPreferredMethodModelOpen ? (
        <SetPreferedConfirmation
          isAlreadyPreferred={method?.preferred}
          open={isPreferredMethodModelOpen}
          setOpen={setIsPreferredMethodModelOpen}
          consentId={method?.payment_consent_id}
          method="Duitnow"
          handlePaymentMethodsModified={handlePaymentMethodsModified}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default DuitnowMethod
