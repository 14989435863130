import React, { useEffect, useRef, useState } from 'react'
import OtpInputField from './OTPInputField'
import { useApp } from '../../../context/usePortal';
import SuccessPopup from './SuccessPopup';
import { GetOtpResponse } from '../../../core/api/http-methods/get-otp';

interface CumulativeRangePopupProps {
  pin: string,
  onClose: () => void
}

const VerifyOTPAndResetPIN: React.FC<CumulativeRangePopupProps> = ({ pin, onClose }) => {

  const { resetPIN, user, getCode } = useApp();
  const [otp, setOtp] = useState<string>('');
  const [actionButtonAction, setActionButtonAction] = useState<string>('Verify');
  const [seconds, setSeconds] = useState<number>(60);
  const [timerActive, setTimerActive] = useState<boolean>(false); // State to control timer
  const [verifyOtpStatus, setVerifyOtpStatus] = useState<string>('');
  const isMounted = useRef<boolean>(false); // Ref to track mount status
  const numberWithoutCCode = user?.phoneNumber?.split('-')[1];
  const [otpAttempt, setOtpAttempt] = useState<number>(0);
  const [resetOtp, setResetOtp] = useState<boolean>(true);
  const [limitReached, setLimitReached] = useState<boolean>(true);
  useEffect(() => {
    if (!isMounted.current) {
      sendOTP();
      isMounted.current = true; // Set to true after first render
    }
  }, []);

  useEffect(() => {
    if (otp.length === 6 && actionButtonAction.toLowerCase().trim() === 'verify') {
      VerifyOTP();
    }
  }, [otp, actionButtonAction]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (timerActive && seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
    } else if (seconds <= 0) {
      setActionButtonAction('resend OTP');
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [timerActive, seconds]);

  const handleChangeOtp = (newValue: string = '') => {
    setOtp(newValue);
  }

  const handleVerifyOtpAndResetPIN = () => {
    if (actionButtonAction.toLowerCase().trim() === 'send otp' || actionButtonAction.toLowerCase().trim() === 'resend otp') {
      sendOTP();
    } else if (actionButtonAction.toLowerCase().trim() === 'verify') {
      VerifyOTP();
    }
  }

  const sendOTP = async () => {
    setResetOtp(true);
    const data = await getCode(numberWithoutCCode || '');
    // if(response?.codeSent);
    if (data.isOk()) {
      setOtpAttempt(0);
      setOtp('');
      setVerifyOtpStatus('');
      setActionButtonAction('verify');
      setSeconds(60); // Reset timer
      setLimitReached(false);
      setTimerActive(true); // Start timer
    }
    else {
      setActionButtonAction('resend OTP');
      if (data?.error?.limitReached) {
        setLimitReached(true);
      }
    }
    // console.log(response?.message); // Log the decoded JSON

  }

  const VerifyOTP = async () => {
    // Ensure OTP length is exactly 6 characters
    if (otp.length !== 6) {
      console.error('Invalid OTP length');
      return;
    }

    // Change button action to 'verifying' and stop the timer
    setOtpAttempt(prev => prev + 1);
    setActionButtonAction('verifying');
    const responseData = await resetPIN(pin, otp, user?.phoneNumber || '');
    console.log(responseData)
    if (responseData?.response?.updated) {
      setActionButtonAction('');
      setVerifyOtpStatus("succeed")
    }
    else {
      if (otpAttempt > 3) {
        setActionButtonAction('resend OTP');
        setTimerActive(false);
        setVerifyOtpStatus("failed")
      }
      else {
        setActionButtonAction('verify');
        setVerifyOtpStatus("failed")
      }
      setResetOtp(prev => !prev);
    }

  }

  const censorNumber = (input: string, placeholder = '*', separator = '_') => {
    // Convert input to a string if it's not already
    const str = input.toString();
    let numericPart = '';
    let nonNumericPart = '';

    // Collect numeric and non-numeric characters separately
    for (const char of str) {
      if (/\d/.test(char)) {
        numericPart += char;
      } else {
        nonNumericPart += char;
      }
    }

    // Mask all numeric characters except the last two
    const maskedNumericPart = numericPart.length > 2
      ? placeholder.repeat(numericPart.length - 2) + numericPart.slice(-2)
      : numericPart;

    // Replace the numeric parts in the original string with the masked version
    let result = '';
    let numericIndex = 0;

    for (const char of str) {
      if (/\d/.test(char)) {
        result += maskedNumericPart[numericIndex++];
      } else {
        result += char;
      }
    }
    return result;
  };

  return (
    (verifyOtpStatus.toLowerCase().trim() === 'succeed') ? (
      <SuccessPopup
        onClose={onClose}
        type='resetPIN'
      />
    ) : (
      <div>
        <h2 className='text-xl font-bold text-center my-2'>Mobile Phone Verification</h2>
        {/* <p className='text-center'>We sent you a One Time Password on this mobile number</p>
      <h3 className='text-center font-bold text-lg mb-2 mt-1'>{censorNumber(user?.phoneNumber)}</h3> */}
        <h3 className='text-center font-bold text-md'>One Time Password</h3>
        <OtpInputField length={6} onChangeOtp={handleChangeOtp} editable={actionButtonAction.toLowerCase().trim() !== 'verify'} resetOtp={resetOtp} />
        {/* <OtpInputField length={6} onChangeOtp={handleChangeOtp} editable={actionButtonAction.toLowerCase().trim() !== 'verify' }   /> */}
        {
          actionButtonAction.toLowerCase().trim() === 'verify' ? (
            <>
              <p className='text-center text-green-800 text-xs'>
                A OTP (One Time Passcode) has been sent to {censorNumber(user?.phoneNumber || '')}.
                Please enter the OTP in the field above to verify your phone.
              </p>
              <p className='text-center'>Resend OTP in <span className='font-bold'>{seconds}s</span></p>
              {verifyOtpStatus.toLowerCase().trim() === 'failed' && (
                <p className='text-center text-red-500 text-xs mt-2'>
                  Incorrect OTP. Please try again.
                </p>
              )}
            </>
          ) : (actionButtonAction.toLowerCase().trim() === 'resend otp') ? (
            limitReached ? (
              <p className='text-center text-red-500 text-xs mt-2'>
                Suspicious activity was detected on your account. Limited attempts are now allowed for added security.
              </p>
            ) : verifyOtpStatus.toLowerCase().trim() === 'failed' ? (
              <p className='text-center text-red-500 text-xs mt-2'>
                Too many incorrect attempts. Please request for a new OTP.
              </p>
            ) : verifyOtpStatus.toLowerCase().trim() !== 'failed' ? (
              <p className='text-center text-red-500 text-xs'>
                OTP has expired. Please try again.
              </p>
            ) : null
          ) : (
            <></> // Fallback loader or content
          )
        }

        {
          actionButtonAction.toLowerCase().trim() !== 'verifying' && (
            <button
              type="button"
              className={`${actionButtonAction.toLowerCase().trim() === 'resend otp' ? 'hover:bg-green-800 hover:text-white text-green-800' : 'bg-green-800 text-white'
                } capitalize mx-auto rounded-3xl py-3 px-9 mt-5 text-center font-bold block border-green-800 border-1 w-full`}
              style={{ opacity: actionButtonAction.toLowerCase().trim() === 'verifying' ? 0.5 : 1 }}
              onClick={handleVerifyOtpAndResetPIN}
            >
              {actionButtonAction}
            </button>
          )
        }
        {
          (actionButtonAction.toLowerCase().trim() === 'verifying' && actionButtonAction.toLowerCase().trim() !== '') && (
            <div className='flex flex-col justify-center items-center'>
              <span className="loader border-green-800 border-5"></span>
              <span className='text-green-800 text-lg font-bold mt-2'>Verifying</span>
            </div>
          )
        }
      </div>)
  )
}

export default VerifyOTPAndResetPIN;
