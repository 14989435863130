import React, { useState, useRef } from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { terminateMethod } from '../api/calls';
import { useApp } from '../context/usePortal';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<import('react').SetStateAction<boolean>>;
  consentRef: string | undefined;
  onClose?: () => void;
}

const GeneralModal: React.FC<Props> = ({
  open,
  setOpen,
  consentRef,
  onClose,
}) => {
  const [pin, setPin] = useState(new Array(6).fill(''));

  const inputRefs = useRef(new Array(6).fill(null));
  const [isLoading, setLoading] = useState(false);
  const { token } = useApp();

  const handleChange = (digit: string, index: number) => {
    const newPin = [...pin];
    newPin[index] = digit;
    setPin(newPin);

    if (digit.length === 1 && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async () => {
    const pinString = pin.join('');
    if (!consentRef) return;
    try {
      const data = await terminateMethod(pinString, consentRef);
      // setMethods(data.methods);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  if (!open) return null;

  return (
    <div className='fixed inset-0 z-50 overflow-y-auto'>
      <div className='flex items-end justify-center min-h-screen p-4 text-center sm:items-center sm:p-0'>
        <div
          className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75'
          aria-hidden='true'
        ></div>

        <div className='inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
          <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
            <div className='sm:flex sm:items-start'>
              <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                <h3
                  className='text-lg mb-6 font-medium leading-6 text-gray-700'
                  id='modal-title'
                >
                  Confirmation
                </h3>
                <p>
                  You are on the verge of terminating a payment method
                  associated with your Aye Face account. To proceed securely,
                  please enter your PIN for confirmation
                </p>
                <div className='border-l-4 mt-4 border-yellow-400 bg-yellow-50 p-4'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <ExclamationTriangleIcon
                        className='h-5 w-5 text-yellow-400'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='ml-3'>
                      <p className='text-sm text-yellow-700'>
                        <p className='font-medium text-yellow-700 hover:text-yellow-600'>
                          Terminating this payment method will also delete your
                          Aye Face account.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                <div className='px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4'>
                  <h3
                    className='text-lg mb-6 font-medium text-gray-700'
                    id='modal-title'
                  >
                    Enter Your 6-Digit Pin
                  </h3>
                  <div>
                    {pin.map((_, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type='password'
                        maxLength={1}
                        value={pin[index]}
                        onChange={(e) => handleChange(e.target.value, index)}
                        className='w-8 h-8 text-center border border-gray-300 rounded m-1'
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6'>
            <button
              type='button'
              onClick={handleSubmit}
              className='inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm'
            >
              Terminate
            </button>
            <button
              type='button'
              className='inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
              onClick={() => {
                setOpen(false);
                if (onClose) {
                  onClose();
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralModal;
