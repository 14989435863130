import {AllPointsHistory, ReceivedPointsHistory, UsedPointsHistory} from "../../components/points-history";

export default function TiersHistory() {
  return (
    <div className="mx-auto w-full gap-y-4 mt-4 flex-col flex">
      <AllPointsHistory/>
      <ReceivedPointsHistory/>
      <UsedPointsHistory/>
    </div>
  )
}