import React, { useEffect, useState } from 'react'
import PINInputField from './PINInputField'
import { useApp } from '../../../context/usePortal';

interface PINVerifactionPopupProps {
  handleVerificationProcessStatusChange: (status: boolean) => void;
  handleAuthStatusChange: (status: string) => void;
  transactionLimit:number,
  resetPIN:()=>void;
}

const PINVerificationPopup: React.FC<PINVerifactionPopupProps> = ({ handleVerificationProcessStatusChange, handleAuthStatusChange ,transactionLimit,resetPIN }) => {
  const { updatePIN  } = useApp();
  const [pin, setPin] = useState<string>('');
  useEffect(  () => {
    if (pin.length === 6 ) {
       VerifyPin();
    }
  }, [pin]);
  const VerifyPin= async()=>{
    handleVerificationProcessStatusChange(true);
    //verify the 
    const responseData = await updatePIN(pin ,pin,transactionLimit);
    console.log(responseData)
    if(responseData?.response?.updated)
    {
      
      handleAuthStatusChange('succeed');  
    }
    else{
      handleAuthStatusChange('failed');
    }

  }
  const handleChangePin = (newValue: string = '') => {
    setPin(newValue);
  }
   const  handleForgetPin = ()=>{
    resetPIN();
  }
  return (
    <div>
      <h2 className='text-xl font-bold text-center my-2 capitalize'>Enter PIN to continue</h2>
      <p className='text-center'>Please enter your 6-digit PIN</p>
      <PINInputField length={6} onChangePin={handleChangePin} />
      <button
            type="button"
            className=  ' capitalize mx-auto underline  mt-2 text-center font-semibold block w-full'
            onClick={handleForgetPin}
          >
        Forget PIN?
          </button>
    </div>
  )
}

export default PINVerificationPopup;
