import React, { useState } from 'react';
import { XMarkIcon } from "@heroicons/react/24/solid";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useApp } from '../../../context/usePortal';
import { useNavigate } from 'react-router-dom';
import { DeletePaymentMethodRequestResponse } from '../../../core/api/http-methods/delete-payment-method';


type DeleteConfirmationProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  consentId?: string;
  handlePaymentMethodsModified: () => void;
}
function DeleteConfirmation({ open, setOpen, consentId, handlePaymentMethodsModified }: DeleteConfirmationProps) {
  const { paymentMethods: ayePaymentMethods, setDefault } = useApp();
  const [paymentMethods, setPaymentMethods] =
    useState<Awaited<ReturnType<typeof ayePaymentMethods>>>();
  const navigate = useNavigate();

  const { deletePaymentMethod } = useApp();
  const [deletedPaymentMethod, setDeletedPaymentMethod] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (deletedPaymentMethod === 'succeed') {
      handlePaymentMethodsModified();
    }
    setOpen(false);
  };

  const handleDelete = async () => {
    // Put your delete logic here
    try {
      setLoading(true)
      const res = await deletePaymentMethod({ consentId: consentId! });
      console.log(res);
      if (res.response?.deleted) {
        setDeletedPaymentMethod('succeed');
      }
      else {
        setDeletedPaymentMethod('failed')
      }
    } finally {
      setLoading(false)
      // setOpen(false)
    }

  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >


        <DialogTitle id="alert-dialog-title" className='flex justify-between' >
          <span>{
            deletedPaymentMethod === 'succeed' ? ('Success') : (deletedPaymentMethod === 'failed' ? 'Failure' : 'Confirm Delete')
          }</span>

          {(deletedPaymentMethod === 'failed' || deletedPaymentMethod === 'succeed') && (
            <button onClick={() => { setOpen(false) }} className='ms-auto block'>
              <XMarkIcon className='h-6 text-end' />
            </button>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              deletedPaymentMethod === 'succeed' ? ('Payment method deleted successfully') : (deletedPaymentMethod === 'failed' ? 'Sorry, we encountered an issue in deleting your payment method' : 'Are you sure you want to delete this payment method?')
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>  {
            deletedPaymentMethod === 'succeed' ? ('Done') : (deletedPaymentMethod === 'failed' ? 'Try Again' : 'No')
          }</Button>
          {
            deletedPaymentMethod.trim() === '' &&

            <Button disabled={loading} onClick={handleDelete} color="primary" autoFocus>
              Yes
            </Button>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmation;