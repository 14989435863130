import { useState } from 'react'
import { useApiCache } from '../context/api-context'

interface UseMutationOptions<TData, TVariables> {
  cacheKey?: string
  onSuccess?: (data: TData) => void
  onError?: (error: any) => void
  onSettled?: (data: TData | null, error: any) => void
  optimisticUpdate?: (variables: TVariables) => void
}

export function useMutation<TData, TVariables>(
  mutationFn: (variables: TVariables) => Promise<TData>,
  options?: UseMutationOptions<TData, TVariables>,
) {
  const { dispatch } = useApiCache()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>(null)
  const [data, setData] = useState<TData | null>(null)

  const mutate = async (variables: TVariables) => {
    setIsLoading(true)
    setError(null)

    if (options?.optimisticUpdate && options.cacheKey) {
      options.optimisticUpdate(variables)
      dispatch({ type: 'SET_LOADING', key: options.cacheKey, loading: true })
    }

    try {
      const result = await mutationFn(variables)
      setData(result)

      if (options?.onSuccess) {
        options.onSuccess(result)
      }

      if (options?.cacheKey) {
        dispatch({ type: 'SET_DATA', key: options.cacheKey, data: result })
      }
    } catch (err) {
      setError(err)

      if (options?.onError) {
        options.onError(err)
      }

      if (options?.cacheKey) {
        dispatch({ type: 'SET_ERROR', key: options.cacheKey, error: err })
      }
    } finally {
      setIsLoading(false)

      if (options?.onSettled) {
        options.onSettled(data, error)
      }
    }
  }

  return { mutate, isLoading, error, data }
}
