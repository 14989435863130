import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { Tab, TabGroup, TabList, TabPanels } from '@headlessui/react';

const categories = [
  {
    name: 'Tier Benefits',
    href: '/tiers',
  },
  {
    name: 'Points History',
    href: '/tiers/history',
  },
];

const TiersLayout = () => {
  /**
   * Loader data is the data passed to the loader function in the router
   * It is the data returned from the async loader function
   * @var  {number} loader
   */
  const loaderData = useLoaderData() as number;
  console.log('Loader data: ', loaderData);
  const navigate = useNavigate();
  return (
    <section className={'flex flex-col gap-y-2'}>
      <div className='mx-3 mt-4 flex items-center gap-2'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          strokeWidth={1.5}
          stroke='currentColor'
          className='size-5'
          onClick={() => {
            navigate(-1);
          }}
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M15.75 19.5 8.25 12l7.5-7.5'
          />
        </svg>
      </div>
      <TabGroup
        selectedIndex={loaderData}
        onChange={(index) => {
          const tab = categories[index];
          navigate(tab.href);
        }}
      >
        <TabList className='mx-4 flex gap-x-4'>
          {categories.map(({ name }) => (
            <Tab
              key={name}
              className='rounded-none px-2 py-1 text-sm/6 font-semibold focus:outline-none data-[selected]:border-b-2 data-[selected]:border-primary data-[selected]:text-primary'
            >
              {name}
            </Tab>
          ))}
        </TabList>
        <TabPanels className={'max-w-[50%]'}>
          <Outlet />
        </TabPanels>
      </TabGroup>
    </section>
  );
};
export default TiersLayout;
