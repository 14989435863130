import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import LoadingPopup from './components/LoadingPopup'
import { useApp } from '../../context/usePortal'
import { DuitnowPopup } from './components/DuitnowPopup'
import { DebitCardEWalletPopup } from './components/DebitCardEWalletPopup'
import SelectMethod from './components/SelectMethod'
import FailedPopup from './components/FailedPopup'
import SucceedPopup from './components/SucceedPopup'
import ClosePopupConfirmationPopup from './components/ClosePopupConfirmationPopup'
import DuitnowInstructions from './components/DuitnowInstructions'
import RecentActivities from './components/RecentActivities'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Greeting from './components/Greeting'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import type { PaymentConsent } from '../../core/api/http-methods/get-payment-methods'
import HeaderList from '../../components/reward/header-list'
import JoinedRewards from '../../components/rewards-hub/joined-rewards'
import { PaymentMethods } from '../../components/payment-method/payment-card'
import { Button } from '../../components/ui/button'

// Define the shape of the response from verifyConsent
interface VerifyConsentResponse {
  isOk: () => boolean
  response?: PaymentConsent
  error?: any
}

const deliveryMethods = [
  {
    id: 1,
    title: 'Duitnow',
    turnaround: 'Available once consent is approved through your bank app.',
    price: '',
  },
  {
    id: 2,
    title: 'TNG / Credit or Debit Cards',
    turnaround:
      'Activate by entering card details; RM 1.00 fee refunded after successful transaction.',
    price: 'Fee RM 1.00',
  },
]

const Home: React.FC = () => {
  const [AyeFacePoints, setAyeFacePoints] = useState(330)
  const { paymentMethods: ayePaymentMethods, verifyConsent } = useApp()
  const [paymentMethods, setPaymentMethods] =
    useState<Awaited<ReturnType<typeof ayePaymentMethods>>>()
  const { token } = useParams()
  const isTokenParamPresent: boolean = token !== undefined
  const [succeed, SetSucceed] = useState(false)
  const [isSelecting, SetIsSelecting] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isTimerStarted, setIsTimerStarted] = useState(false)
  const { addPaymentMethod } = useApp()
  const selectedDeliveryMethod = useRef(deliveryMethods[0])
  const idNumber = useRef<string>()
  const phoneNumber = useRef<string>('60-1116668523')
  const [open, setOpen] = useState<boolean>(false)
  const [paymentMethodsModified, setPaymentMethodsModified] =
    useState<boolean>(true)
  const [recentlyAddedPaymentMethod, setRecentlyAddedPaymentMethod] =
    useState<string>('')
  const idList = [
    {
      label: 'Phone Number',
      value: '5',
    },
    {
      label: 'Passport',
      value: '3',
    },
    {
      label: 'IC',
      value: '1',
    },
  ]
  const idType = useRef<string>(idList[0]?.value)
  const location = useLocation()
  const navigate = useNavigate()
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const onClose = () => {
    if (!(isSubmitted && !isTimerStarted)) {
      if (showConfirmation) {
        setOpen(false)
        setShowConfirmation(false)
      } else {
        setShowConfirmation(true)
      }
    } else {
      handleConfirmClose()
    }
  }

  useEffect(() => {
    if (isTokenParamPresent) {
      // verifyConsentConfirmation(token)
      console.log('handle token ', token)
    }
  }, [token])

  // const verifyConsentConfirmation = async (tokenParam: string | undefined) => {
  //   if (isTokenParamPresent) {
  //     // For the case where token parameter is present
  //     const response = await verifyConsent(tokenParam || '')
  //     console.log(response)
  //     if (response?.response?.status === 'ACTIVE') {
  //       let addedMethod: string =
  //         response?.response?.cardType || response?.response?.method || ''
  //       if (
  //         addedMethod.toLowerCase() === 'debit' ||
  //         addedMethod.toLowerCase() === 'credit'
  //       ) {
  //         addedMethod = addedMethod + ' CARD'
  //       }
  //       setRecentlyAddedPaymentMethod(addedMethod)
  //       setOpen(true)
  //       setIsSubmitted(true)
  //       SetSucceed(true)
  //       setIsLoading(false) // Ensure loading is stopped
  //     } else {
  //       setOpen(true)
  //       setIsSubmitted(true)
  //       SetSucceed(false)
  //       setIsLoading(false) // Ensure loading is stopped
  //     }
  //   } else {
  //     let approved = false
  //     const intervalId = setInterval(async () => {
  //       try {
  //         const response = await verifyConsent(tokenParam || '')
  //         console.log(response)
  //         if (response.response?.status === 'ACTIVE') {
  //           setRecentlyAddedPaymentMethod(response.response?.method || '')
  //           setIsSubmitted(true)
  //           setIsTimerStarted(false)
  //           SetSucceed(true)
  //           clearInterval(intervalId) // Clear the interval if successful
  //           setIsLoading(false) // Ensure loading is stopped
  //           approved = true
  //         }
  //       } catch (error) {
  //         console.error('Error verifying consent:', error)
  //         // Handle the error if necessary
  //       }
  //     }, 3000)

  //     setIsSubmitted(true)
  //     setIsTimerStarted(true)
  //     setIsLoading(false)

  //     const timeoutId = setTimeout(() => {
  //       clearInterval(intervalId) // Stops the interval after 30 seconds
  //       setIsTimerStarted(false)
  //       if (!approved) {
  //         // Only show failure if not succeeded
  //         SetSucceed(false)
  //       }
  //     }, 300000)
  //     return () => {
  //       clearInterval(intervalId)
  //       clearTimeout(timeoutId)
  //     }
  //   }
  // }

  const handleConfirmClose = () => {
    //if current url is ../proal/home/23343
    if (!location.pathname.endsWith('/portal')) {
      navigate('/portal')
    }
    setOpen(false)
    setIsLoading(false)
    setShowConfirmation(false)
    setIsSubmitting(false)
    setIsSubmitted(false)
    setIsTimerStarted(false)
  }

  const setSelectedDeliveryMethod = (id: number) => {
    selectedDeliveryMethod.current = deliveryMethods[id]
  }
  useEffect(() => {
    // console.log('payment method hcnaged')
    ayePaymentMethods().then((data) => {
      setPaymentMethods(data)
      // console.log('payment method hcnaged')
    })
  }, [paymentMethodsModified])
  const setPhoneNumber = (value: string) => {
    phoneNumber.current = value
    console.log(value)
  }

  const setID = (value: string) => {
    idNumber.current = value
  }
  const handleSubmit = useCallback(() => {
    setIsSubmitting(false)
    setIsLoading(true)
    // Simulating an asynchronous operation with a delay (1 second)
    _addPaymentMethod()
  }, [])

  const goBackToSelectMethod = () => {
    SetIsSelecting(true)
    setIsSubmitting(false)
  }

  const selectMethod = (id: number) => {
    SetIsSelecting(false)
    setSelectedDeliveryMethod(id)
    setIsSubmitting(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    idType.current = event.target.value as string
  }

  const idTypeError = () => {
    return idNumber.current ? !(idNumber.current.length >= 7) : false
  }

  const phoneNumberError = () => {
    return phoneNumber.current
      ? !(phoneNumber.current.length === 9 || phoneNumber.current.length === 10)
      : false
  }
  const _addPaymentMethod = useCallback(async () => {
    console.log({ selectedDeliveryMethod: selectedDeliveryMethod.current })
    const response = await addPaymentMethod({
      method: selectedDeliveryMethod.current?.id?.toString(),
      idtp: idType.current!,
      idv: idType.current === '5' ? phoneNumber.current : idNumber.current!,
      phone_number: phoneNumber.current!,
    })

    if (response?.isOk()) {
      if (response.response?.pay_url) {
        window.location.href = response.response.pay_url
        setOpen(false)
        handleConfirmClose()
        //suspend reredoner here
      } else if (response.response?.token) {
        setIsLoading(false)
        console.log('old one will verifyConsentConfirmation this', response.response.token)
        // verifyConsentConfirmation(response.response?.token)
      } else {
        handleConfirmClose()
      }
    } else {
      handleConfirmClose()
    }
  }, [])

  const tryAgain = () => {
    setIsSubmitted(false)
    SetIsSelecting(true)
  }
  const onOpen = () => {
    SetIsSelecting(true)
    setOpen(true)
  }
  const sortPaymentMethods = () => {
    // Sort the paymentMethods array
    const sortedPaymentMethods = paymentMethods?.slice().sort((a, b) => {
      if (a.preferred && b.preferred) {
        return 0 // If both are the same, keep their order
      }
      return a.preferred ? -1 : 1 // Sort 'true' values before 'false'
    })

    return sortedPaymentMethods
  }

  const handlePaymentMethodsModified = () => {
    console.log('handle payment changed')
    setPaymentMethodsModified(!paymentMethodsModified)
  }
  return (
    <div className="add_method px-4">
      <div>
        <Greeting />
      </div>
      {/* <div className='md:flex   justify-between  items-center '>
        <h1 className='ml-3 xs:text-2xl sm:text-3xl  font-bold leading-7 text-gray-900 sm:truncate sm:leading-9'>
          Payment Methods Overview
        </h1>
        <button
          className='  m-3 bg-green-800  xs:w-full sm:w-full md:w-auto rounded-full inline-flex items-center justify-center border border-transparent px-6 py-3 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-offset-2 min-w-[160px]'
          onClick={() => onOpen()}
        >
          Add New Payment Method
        </button>
      </div> */}
      <div className="flex flex-col gap-y-4 md:flex-row md:justify-between px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-bold leading-6 text-gray-900 md:text-xl">
          Payment Methods Overview
        </h2>
        <Button onClick={() => onOpen()} variant="outline"
          className={
            'text-primary-foreground max-w-[13rem] rounded-full bg-[#5CB891] px-2 hover:bg-[#5CB891]/80'
          }>
          <span className="text-sm font-semibold">Add New Payment Method</span>
        </Button>
      </div>
      <PaymentMethods />
      <div className="mx-auto mt-8 max-w-full px-4 pb-6 sm:px-6 lg:px-8">
        <section>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            Ayeface Rewards
          </h1>
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex flex-nowrap gap-1">
              <p>You have</p>
              <h2 className="text-lg font-bold leading-6 text-gray-600">
                {AyeFacePoints || 0}
              </h2>
              <p>AyeFace Points</p>
            </div>
            <button
              className="rounded-full bg-white px-3 py-1 shadow-xl"
              onClick={() => {
                navigate('/aye-face-reward')
              }}>
              <p className="bg-gradient-to-r from-[#F76443] to-[#FEC745] bg-clip-text text-transparent">
                See More
              </p>
            </button>
          </div>
        </section>
      </div>
      <div className="mx-auto mt-8 max-w-full px-4 pb-6 sm:px-6 lg:px-8">
        <section className={'mx-auto flex w-full max-w-full flex-col gap-y-4'}>
          <HeaderList
            title="My Rewards Hub"
            href={'/my-rewards-hub'}
            description={undefined}
          />
          <JoinedRewards className={'[&>h3]:hidden'} />
        </section>
      </div>
      <RecentActivities />
      {open && (
        <div className="rounded-2xl p-0">
          <Dialog
            open={open}
            onClose={onClose}
            className="boder-red-300 rounded-xl border-1 p-0">
            <DialogContent dividers className="max-w-[400px] p-0">
              {!(isSubmitted && !isTimerStarted) && (
                <button onClick={onClose} className="ms-auto block">
                  <XMarkIcon className="h-6 text-end" />
                </button>
              )}
              {!isSubmitted && isSubmitting && !isSelecting ? (
                selectedDeliveryMethod.current?.id === 2 ? (
                  <DebitCardEWalletPopup
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberError={phoneNumberError}
                    handleSubmit={handleSubmit}
                    goBackToSelectMethod={goBackToSelectMethod}
                  />
                ) : (
                  <DuitnowPopup
                    handleSubmit={handleSubmit}
                    idTypeError={idTypeError}
                    handleChange={handleChange}
                    setID={setID}
                    idList={idList}
                    idType={idType.current}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumberError={phoneNumberError}
                    goBackToSelectMethod={goBackToSelectMethod}
                  />
                )
              ) : (
                // ) : isSelecting && <DuitnowInstructions/>
                isSelecting && <SelectMethod selectMethod={selectMethod} />
              )}

              {isLoading && <LoadingPopup />}

              {/* {
               (isTimerStarted && isSubmitted) && <DuitnowInstructions/>
            } */}
              {isSubmitted &&
                (isTimerStarted ? (
                  <DuitnowInstructions />
                ) : succeed ? (
                  <SucceedPopup
                    onClose={onClose}
                    addedPaymentMethod={recentlyAddedPaymentMethod}
                  />
                ) : (
                  <FailedPopup onClose={onClose} tryAgain={tryAgain} />
                ))}
            </DialogContent>
          </Dialog>
        </div>
      )}
      <ClosePopupConfirmationPopup
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirmClose}
      />
    </div>
  )
}

export default Home
