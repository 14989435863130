import { useNavigate } from 'react-router-dom'
import { TabPanel } from '@headlessui/react'
import { TierBenefit } from '../../components/tier-benefits'

const TiersBenefits = () => {
  const navigate = useNavigate()
  return (
    <TabPanel as={'div'} className={'my-4 flex flex-col gap-y-2'}>
      <TierBenefit />
      <TierBenefit />
      <TierBenefit />
    </TabPanel>
  )
}
export default TiersBenefits