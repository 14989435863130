//@ts-check
import { Link, useParams } from 'react-router-dom';
import Branches from '../../../components/branch';
import CampaignCard from '../../../components/campaign/card';
import MembershipCard from '../../../components/membership/card';
import { RewardCard } from '../../../components/reward/card';
import HeaderList from '../../../components/reward/header-list';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../../../components/ui/carousel';
import SectionHeader from '../../../components/ui/section-header';
import { campaignData } from '../../../dummy/campaignData';
import { myRewards } from '../../../dummy/my-rewards';

const getBrandDetails = (brandId?: string) => {
  const rewards = [
    ...myRewards.joinedRewards,
    ...myRewards.participatingBrands,
  ];
  return rewards.find((reward) => reward.id === brandId);
};

export default function RewardTierDetails() {
  const params = useParams<{ id: string }>();
  const brand = getBrandDetails(params.id);

  if (!brand) {
    return null;
  }

  return (
    <section className='flex flex-col gap-y-8'>
      <SectionHeader
        className={'items-start'}
        href={'/my-rewards-hub'}
        title={
          <div className='size-14 md:size-16'>
            <img
              src={brand.logo}
              alt={brand.brand}
              className='size-full object-contain'
            />
          </div>
        }
      />
      <Link
        to={`/my-rewards-hub/${brand.id}/tiers`}
        className='w-full md:max-w-[60%] px-2 xl:max-w-[40%]'
      >
        <MembershipCard
          isBrand
          brandName={brand.brand}
          rewardName={'Level 1'}
          image={brand.logo}
          // rewardPoints={brand.rewardPoints}
          description={`Earn points when you shop at ${brand.brand?.toLowerCase()}`}
        />
      </Link>
      <div className='mx-auto flex w-full max-w-[calc(100%-2rem)] flex-col gap-y-4'>
        <HeaderList
          description={
            'Redeem rewards instantly at the store when you pay with AyeFace'
          }
          href={`/my-rewards-hub/${brand.id}/rewards`}
        />
        <div className='flex flex-wrap px-2'>
          <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
            <RewardCard type='cashback' />
          </div>
          <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
            <RewardCard type='discount' />
          </div>
          <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
            <RewardCard type='cashback' />
          </div>
        </div>
      </div>
      <div className='mx-auto flex w-full max-w-[calc(100%-2rem)] flex-col gap-y-4'>
        <HeaderList
          title='Active Campaigns'
          href={`/my-rewards-hub/${brand.id}/active-campaigns?type=active`}
          description={undefined}
        />
        <Carousel
          opts={{
            align: 'start',
          }}
          className='z-20 max-w-full bg-inherit'
        >
          <CarouselContent className={'items-stretch pl-2'}>
            {campaignData?.map((campaign, index) => (
              <CarouselItem
                key={index}
                className={`basis-[45%] scroll-pl-6 shadow-none drop-shadow-none md:basis-[65%] lg:basis-1/5`}
              >
                {/* <div className="h-full"> */}
                <Link to={`active-campaigns/${campaign.campaignId}`}>
                  <CampaignCard
                    campaign={campaign}
                    className={''}
                  />
                </Link>
                {/* </div> */}
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
      <div className='mx-auto flex w-full max-w-[calc(100%-2rem)] flex-col gap-y-4 pb-4'>
        <HeaderList
          title='Locations'
          href={'/all-locations'}
          description={`Find ${brand.brand}'s stores near you that accept AyeFace`}
        />
        <Branches className={'px-2'} />
      </div>
    </section>
  );
}
