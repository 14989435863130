import { useCallback, useState } from 'react';
import { suspend } from 'suspend-react';
import { ayeApi } from '../core';
import {
  AddPaymentMethodRequest,
  AddPaymentMethodResponse,
} from '../core/api/http-methods/add-payment';
import { DeletePaymentMethodRequestResponse } from '../core/api/http-methods/delete-payment-method';
import { GetOtpResponse } from '../core/api/http-methods/get-otp';
import { GetPaymentMethodsResponse } from '../core/api/http-methods/get-payment-methods';
import { GetUserResponse } from '../core/api/http-methods/get-user';
import { LoginPortalResponse } from '../core/api/http-methods/login';
import { ResetPINResponse } from '../core/api/http-methods/reset-pin';
import { SaveUserResponse } from '../core/api/http-methods/save-user-info';
import { SetDefaultResponse } from '../core/api/http-methods/set-default';
import { UpdatePINResponse } from '../core/api/http-methods/update-pin';
import { VerifyConsentResponse } from '../core/api/http-methods/verify-consent';
import catchAsync from '../utils/catchAsync';
import useGuardedCallback, { GuardedReturn } from '../utils/useGuardedCallback';

export function usePortalHook(): {
  token: string | undefined;
  user: GetUserResponse | undefined;
  loading: boolean;
  getCode: (phoneNumber: string) => Promise<GuardedReturn<GetOtpResponse>>;
  login: (
    phoneNumber: string,
    code: string
  ) => Promise<GuardedReturn<LoginPortalResponse>>;
  logout: () => void;
  saveUser: (args_0: {
    name: string;
    email: string;
    sendNewsletter: boolean;
    sendMarketing: boolean;
  }) => Promise<GuardedReturn<SaveUserResponse | undefined>>;
  addPaymentMethod: (
    args_0: AddPaymentMethodRequest['body']
  ) => Promise<GuardedReturn<AddPaymentMethodResponse | undefined>>;
  deletePaymentMethod: (args_0: {
    consentId: string;
  }) => Promise<GuardedReturn<DeletePaymentMethodRequestResponse | undefined>>;
  transactions: () => Promise<
    | readonly {
        id: string;
        amount: string | null;
        ref: string | null;
        customerId: string | null;
        createdAt: Date;
        refundAmount: string;
        deviceId: string | null;
        storeName: string | null;
        paymentMethod: string | null;
        paid: boolean;
      }[]
    | undefined
  >;
  paymentMethods: () => Promise<
    GetPaymentMethodsResponse['payment_consents'] | undefined
  >;
  verifyConsent: (
    token: string
  ) => Promise<GuardedReturn<VerifyConsentResponse | undefined>>;
  setDefault: (
    consentId: string
  ) => Promise<GuardedReturn<SetDefaultResponse | undefined>>;
  updatePIN: (
    oldPin: string,
    newPin: string,
    transactionLimit: number
  ) => Promise<GuardedReturn<UpdatePINResponse | undefined>>;
  resetPIN: (
    newPin: string,
    otp: string,
    phoneNumber: string
  ) => Promise<GuardedReturn<ResetPINResponse | undefined>>;
} {
  const [counter, setCounter] = useState<number>(0);
  const [token, setToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const user = suspend(async () => {
    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${localStorageToken}`);
      setToken(localStorageToken);
      try {
        const userResponse = await ayeApi.getUser({ headers });
        return userResponse;
      } catch (error) {
        // localStorage.removeItem('token');
        // setToken(undefined);
      }
    }
  }, [counter]);
  const getCode = useGuardedCallback(
    async (phoneNumber: string) => {
      const [country_code, user_phone_number] = phoneNumber.split('-');
      const response = await ayeApi.getOTP({
        body: {
          user_phone_number,
          country_code: country_code.startsWith('+')
            ? country_code
            : `+${country_code}`,
        },
      });
      return response;
    },
    [],
    setLoading
  );

  const login = useGuardedCallback(
    async (phoneNumber: string, code: string) => {
      const [country_code, user_phone_number] = phoneNumber.split('-');
      const response = await ayeApi.loginPortal({
        body: {
          user_phone_number,
          country_code: country_code.startsWith('+')
            ? country_code
            : `+${country_code}`,
          otp: code,
        },
      });
      localStorage.setItem('LoggedIn', 'true');
      console.log('====================================');
      console.log(response);
      console.log('====================================');
      console.debug(
        'ℹ️ ~ file: usePortalHook.tsx:127 ~ response.auth_token:',
        response.auth_token
      );
      if (response.auth_token) {
        console.debug(
          'ℹ️ ~ file: usePortalHook.tsx:132 ~ response.auth_token:',
          response.auth_token
        );
        localStorage.setItem('token', response.auth_token);

        setCounter((prev) => prev + 1);
      }
      return response;
    },
    [],
    setLoading
  );
  const logout = () => {
    console.log('Logging out...');
    localStorage.removeItem('token');
    setToken(undefined);
    setCounter((prev) => prev + 1);
  };

  const saveUser = useGuardedCallback(
    async ({
      name,
      email,
      sendNewsletter,
      sendMarketing,
    }: {
      name: string;
      email: string;
      sendNewsletter: boolean;
      sendMarketing: boolean;
    }) => {
      const localStorageToken = localStorage.getItem('token');
      if (localStorageToken) {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${localStorageToken}`);
        const response = await ayeApi.saveUser({
          body: { name, email, sendNewsletter, sendMarketing },
          headers,
        });
        setCounter((prev) => prev + 1);
        return response;
      }
    },
    [token]
  );
  const deletePaymentMethod = useGuardedCallback(
    async ({ consentId }: { consentId: string }) => {
      const localStorageToken = localStorage.getItem('token');
      if (localStorageToken) {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${localStorageToken}`);

        const response = await ayeApi.deletePaymentMethod({
          body: { consentId },
          headers,
        });
        // setCounter(prev=> prev+1)
        return response;
      }
    },
    [token]
  );
  const addPaymentMethod = useGuardedCallback(
    async (args: AddPaymentMethodRequest['body']) => {
      const localStorageToken = localStorage.getItem('token');
      if (localStorageToken) {
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${localStorageToken}`);
        const response = await ayeApi.addPaymentMethod({ body: args, headers });
        setCounter((prev) => prev + 1);
        return response;
      }
    },
    [],
    setLoading
  );
  const paymentMethods = useCallback(async () => {
    const localStorageToken = localStorage.getItem('token');

    if (localStorageToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${localStorageToken}`);
      const response = await catchAsync(
        async () => await ayeApi.getPaymentMethods({ headers })
      )();
      if (response.isOk()) return response.response?.payment_consents;
      return [];
    }

    return [];
  }, [token]);
  const transactions = useCallback(async () => {
    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${localStorageToken}`);
      const response = await catchAsync(
        async () => await ayeApi.getUserTransactions({ body: {}, headers })
      )();
      if (response.isOk()) return response.response;
      return [];
    }
  }, [token]);
  const verifyConsent = useGuardedCallback(async (token: string) => {
    const headers = new Headers();
    console.log('====================================');
    console.log({ token });
    console.log('====================================');
    // headers.append('Authorization', `Bearer ${token}`);
    const response = await ayeApi.verifyConsent({
      body: {
        token: token,
      },
      headers,
    });
    return response;
  }, []);

  const setDefault = useGuardedCallback(async (consentId: string) => {
    const headers = new Headers();
    console.log('====================================');
    console.log({ consentId });
    console.log('====================================');
    const localStorageToken = localStorage.getItem('token');
    if (localStorageToken) {
      headers.append('Authorization', `Bearer ${localStorageToken}`);
    }
    const response = await ayeApi.setDefault({
      body: {
        consentId: consentId,
      },
      headers,
    });
    return response;
  }, []);
  const updatePIN = useGuardedCallback(
    async (oldPin: string, newPin: string, transactionLimit: number) => {
      const headers = new Headers();
      console.log('====================================');
      console.log({ newPin });
      console.log('====================================');
      const localStorageToken = localStorage.getItem('token');
      if (localStorageToken) {
        headers.append('Authorization', `Bearer ${localStorageToken}`);
      }

      const response = await ayeApi.UpdatePIN({
        body: {
          oldPin,
          newPin,
          transactionLimit,
        },
        headers,
      });
      return response;
    },
    []
  );
  const resetPIN = useGuardedCallback(
    async (newPin: string, otp: string, phoneNumber: string) => {
      const headers = new Headers();
      console.log('====================================');
      console.log({ newPin });
      console.log('====================================');
      const localStorageToken = localStorage.getItem('token');
      if (localStorageToken) {
        headers.append('Authorization', `Bearer ${localStorageToken}`);
      }

      const response = await ayeApi.ResetPIN({
        body: {
          newPin,
          otp,
          phoneNumber,
        },
        headers,
      });
      return response;
    },
    []
  );

  return {
    token,
    user,
    loading,
    getCode,
    login,
    logout,
    saveUser,
    addPaymentMethod,
    deletePaymentMethod,
    paymentMethods,
    transactions,
    verifyConsent,
    setDefault,
    updatePIN,
    resetPIN,
  };
}
