//@ts-nocheck
import MembershipCard from './membership/card';
import { cn } from '../lib/utils';
import type React from 'react';

interface Props {
  className?: string;
}

const TierBenefit: React.FC<Props> = ({ className }) => {
  return (
    <div
      className={cn(
        'flex flex-col gap-y-4 bg-[rgba(217,217,217,20%)] px-4 py-4 md:bg-transparent',
        className
      )}
    >
      <MembershipCard
        className={'max-w-full'}
        tier='Bronze'
        benefits={[
          'Free shipping on all orders',
          'Early access to new products',
          'Exclusive discounts',
        ]}
      />
      <div className='mx-auto flex w-full max-w-[95%] flex-col gap-y-4'>
        <p className='text-md font-normal text-gray-500'>Tier Benefits</p>
        {Array(3)
          .fill(0)
          .map((_, i) => (
            <TierBenefitsDetail key={i} />
          ))}
      </div>
    </div>
  );
};

const TierBenefitsDetail = () => {
  return (
    <div className='flex h-14 items-center gap-x-4'>
      <div className='flex size-12 items-center justify-center rounded-full bg-white shadow drop-shadow-sm' />
      <p className={'text-gray-400'}>Tier benefits description</p>
    </div>
  );
};

export { TierBenefit, TierBenefitsDetail };
