// @ts-check
import { useEffect, useState, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// import { refreshAccessToken } from '../api/calls';
import { jwtDecode } from 'jwt-decode'; // Add this import

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [token, setToken] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(true);

  const isTokenExpired = (token: string) => {
    try {
      const decoded = jwtDecode(token);
      console.debug(
        'ℹ️ ~ file: protectedRoute.js:20 ~ isTokenExpired ~ decoded:',
        decoded
      );
      if (!decoded || !decoded.exp) return true;
      return decoded?.exp * 1000 < Date.now();
    } catch {
      return true;
    }
  };

  useEffect(() => {
    const verifyAuthentication = async () => {
      const accessToken = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refresh_token');

      if (!accessToken && !refreshToken) {
        console.log('No tokens found, redirecting to login');
        navigate('/login');
        setIsVerifying(false);
        return;
      }

      // if (
      //   (!accessToken && refreshToken) ||
      //   (accessToken && isTokenExpired(accessToken))
      // ) {
      //   // Try to refresh if no token or token is expired
      //   const newAccessToken = await refreshAccessToken();
      //   if (newAccessToken) {
      //     setToken(newAccessToken);
      //   } else {
      //     localStorage.removeItem('token');
      //     localStorage.removeItem('refresh_token');
      //     navigate('/login');
      //   }
      // } else {
      //   setToken(accessToken);
      // }
      setToken(accessToken);
      setIsVerifying(false);
    };

    verifyAuthentication();
  }, [navigate]);

  if (isVerifying) return null;
  if (!token) return null;

  return <>{children}</>;
};

export default ProtectedRoute;
