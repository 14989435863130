export const LOGO_PATH = process.env.PUBLIC_URL + "/logo.png";
export const PAYNET_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/paynet-logo.png";
export const PAYNET_LOGO2_PATH = process.env.PUBLIC_URL + "/assets/logos/paynet-logo-2.png";
export const DUITNOW_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/duitnow-logo.png";
export const VISA_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/visa-logo.png";
export const VISA_LOGO2_PATH = process.env.PUBLIC_URL + "/assets/logos/Visa_Logo_2.png";
export const UOB_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/uob_logo.png";
export const RHB_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/rhb_logo.png";
export const AMERICAN_EXPRESS_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/american_express.png";
export const TNG_EWALLET_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/TNG-EWALLET-logo.png";
export const MASTERCARD_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/MASTERCARD-logo.png";
export const UNION_PAY_LOGO = process.env.PUBLIC_URL + "/assets/logos/union_pay_logo.png";
export const PHONE_LAPTOP_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/phone-laptop-logo.png";
export const SERVICES_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/Services-logo.png";
export const UOB_BANK_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/UOB-Bank-logo.png";
export const RHB_BANK_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/RHB-Bank-logo.png";
export const DUITNOW_LOGO2_PATH = process.env.PUBLIC_URL + "/assets/logos/Duitnow-logo2.png";
export const RHB_BANK_LOGO2_PATH = process.env.PUBLIC_URL + "/assets/logos/UOB-Bank-logo2.png";
export const SUCCESS_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/success-logo.png";
export const FAILURE_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/failure-logo.png";
export const TNG_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/TNG_eWallet.svg.png";
export const GRAB_PAY_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/GP_eWallet.png ";
export const SHOPEE_PAY_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/SP_eWallet.png";
export const GCASH_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/GCash_Logo.png";
export const TRUE_MONEY_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/TM_eWallet_logo.png";
export const WECAHT_PAY_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/WCP_eWallet_logo.png";
export const ALI_PAY_LOGO_PATH = process.env.PUBLIC_URL + "/assets/logos/AP_eWallet_logo.png";

