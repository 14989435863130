import React, { useEffect, useState } from 'react';
import PINInputField from './PINInputField';
import VerifyOTPAndResetPIN from './VerifyOTPAndResetPIN';
interface ResetPINPopupProps {
    onClose:()=>void,
    handleShowResetPINPopupChange:(vale:string)=>void,
    showResetPINPopup:string
  }
const ResetPINPopup:React.FC<ResetPINPopupProps>= ({onClose, handleShowResetPINPopupChange, showResetPINPopup}) => {
    const [pin1, setPin1] = useState<string>('');
    const [pin2, setPin2] = useState<string>('');
    useEffect(()=>{
        handleShowResetPINPopupChange('pin1Input');
    },[])

    // Update showResetPINPopup to 'pin2Input' when pin1 length is 6
    useEffect(() => {
        if (pin1.length === 6) {
            handleShowResetPINPopupChange('pin2Input');
        }
    }, [pin1]);

    // Update showResetPINPopup to 'verifyOTPAndResetPIN' when pin1 and pin2 match
    useEffect(() => {
        if (pin1.length === 6 && pin1 === pin2) {
            handleShowResetPINPopupChange('verifyOTPAndResetPIN');
        }
    }, [pin2]);
    // Handle changes in pin1
    const handleChangePin1 = (newValue: string = '') => {
        setPin1(newValue);
    };

    // Handle changes in pin2
    const handleChangePin2 = (newValue: string = '') => {
        setPin2(newValue);
    };

    const startAgain = ()=>{
        handleShowResetPINPopupChange('pin1Input');
        setPin1('');
        setPin2('');
    }
    // Conditional rendering based on showResetPINPopup value
    return (
        <div>
            {showResetPINPopup === 'pin1Input' && (
                <>
                    <h2 className='text-xl font-bold text-center my-2 capitalize'>Please enter new 6-digit PIN</h2>
                    <p className='text-center'>Please enter the new 6-digit PIN</p>
                    <PINInputField length={6} onChangePin={handleChangePin1} />
                </>
            )}

            {showResetPINPopup === 'pin2Input' && (
                <>
                    <h2 className='text-xl font-bold text-center my-2 capitalize'>Enter New PIN Again</h2>
                    <p className='text-center'>Please re-enter the new 6-digit PIN</p>
                    <PINInputField length={6} onChangePin={handleChangePin2} />
                    {((pin2.length === 6) && (pin1 !== pin2)) && (
                        <p className='text-center text-red-500'>PIN do not match</p>
                    )}
                    <button
                        type="button"
                        className='capitalize mx-auto underline mt-2 text-center font-semibold block w-full'
                        onClick={startAgain}
                    >
                        Start Again?
                    </button>
                </>
            )}

            {showResetPINPopup === 'verifyOTPAndResetPIN' && (
                <VerifyOTPAndResetPIN pin={pin1}  onClose = {onClose} />
            )}

        </div>
    );
};

export default ResetPINPopup;
