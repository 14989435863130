import type { RewardsResponse } from '../types/rewards'
import { api } from './calls'
import { queryFunction } from './utils'

/**
 *
 *
 * @param queryData
 * @returns
 */
const getUserRewards = async <T extends 'active' | 'past'>(
  queryData?:
    | {
        query: {
          status: T
        }
      }
    | undefined,
) =>
  await api.get<RewardsResponse<T> | null>(
    `/users/me/rewards${queryFunction(queryData?.query)}`,
  )

export { getUserRewards }
