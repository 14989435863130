import { getUserRewards } from '../../../api/rewards.api'
import { RewardCard } from '../../../components/reward/card'
import HeaderList from '../../../components/reward/header-list'
import { useApiFetch } from '../../../hooks/useApiFetch'

const MyRewards = () => {
  const { data, isLoading, mutate } = useApiFetch(getUserRewards, {
    query: {
      status: 'past' as const,
    },
  })
  console.debug('ℹ️ ~ file: my-aye-rewards.tsx:8 ~ MyRewards ~ data:', data)

  const handleMutate = () => {
    mutate((d) => {
      d?.point_rewards.pop()
      return d
    })
  }

  return (
    <div className="mx-auto flex w-full max-w-[calc(100%-2rem)] flex-col gap-y-4">
      <HeaderList
        description={
          'Redeem rewards instantly at the store when you pay with AyeFace'
        }
      />
      <button onClick={handleMutate}>GGGFG</button>
      {/* {isLoading ? <>Loading User Rewards</> : JSON.stringify(data)} */}
      <div className="flex flex-wrap px-2">
        <div className="basis-full py-2 sm:basis-1/2 sm:pr-2 lg:basis-1/3">
          <RewardCard type="cashback" />
        </div>
        <div className="basis-full py-2 sm:basis-1/2 sm:pr-2 lg:basis-1/3">
          <RewardCard type="discount" />
        </div>
        <div className="basis-full py-2 sm:basis-1/2 sm:pr-2 lg:basis-1/3">
          <RewardCard type="cashback" />
        </div>
      </div>
    </div>
  )
}

export default MyRewards
