import React, { useState } from 'react';

interface PINInputFieldProps {
  length: number;
  onChangePin: (PIN: string) => void;
}

const PINInputField: React.FC<PINInputFieldProps> = ({ length, onChangePin }) => {
  const [pin, setPin] = useState<string[]>(new Array(length).fill(""));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    //check if it it not number then retunr 
    const numValue = Number(value);
    // Check if it's not a non-negative integer
    if (!Number.isInteger(numValue) || numValue < 0) return;
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    // Move focus to the next input
    if (index < length - 1 && value) {
      const nextInput = document.getElementById(`PIN-${index + 1}`) as HTMLInputElement;
      nextInput?.focus();
    }

    onChangePin(newPin.join(""));
  };

  // Handle backspace key press
  const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const newPin = [...pin];
    newPin[index] = "";
    setPin(newPin);

    // Move focus to the previous input
    if (index > 0) {
      const prevInput = document.getElementById(`PIN-${index - 1}`) as HTMLInputElement;
      prevInput?.focus();
    }

    onChangePin(newPin.join(""));
  };

  return (
    <div className='flex justify-center items-center  my-4' >
      {pin.map((data, index) => (
        <input
          key={index}
          id={`PIN-${index}`}
          type="text"
          maxLength={1}
          value={data}
          className="w-[35px] h-[35px] border-1 mr-2 text-lg  rounded-xl text-center border-gray-600"
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              handleBackspace(e, index);
            }
          }}
        />
      ))}
    </div>
  );
};

export default PINInputField;
