// ClosePopupConfirmationPopup.tsx

import React from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';

interface ClosePopupConfirmationPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ClosePopupConfirmationPopup: React.FC<ClosePopupConfirmationPopupProps> = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <h5 className='text-md font-bold text-black'>Quit Adding New Payment Method? </h5>
        <p className='text-sm text-black'>Changes you made so far will not be saved </p>
        <div className="flex justify-end mt-4">
          <Button onClick={onClose} color="primary" className="mr-4 text-sm font-bold ">
            No
          </Button>
          <Button onClick={onConfirm} color="primary" className=' text-sm font-bold ' >
            Yes
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ClosePopupConfirmationPopup;
