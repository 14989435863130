import SectionHeader from '../../components/ui/section-header'
import JoinedRewards from '../../components/rewards-hub/joined-rewards'
import ParticipatingBrands from '../../components/rewards-hub/participating-brands'

export default function MyRewardsHub() {
  return (
    <section className="flex flex-col  gap-y-4">
      <SectionHeader
        href={'/'}
        title={<>
          <h1 className="text-md md:text-lg font-bold leading-6 text-gray-900">
            My Rewards Hub
          </h1>
        </>}
      />
      <div className="flex flex-col max-w-[calc(100%_-_2rem)]  w-full mx-auto gap-y-8">
        <JoinedRewards />
        <ParticipatingBrands />
      </div>
    </section>
  )
}