import { useEffect } from 'react';
import { RouterProvider, useNavigate } from 'react-router-dom';
import './App.css';
import { useApp } from './context/usePortal';
import { router } from './app-router';

function App() {
  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  );
}

export default App;
