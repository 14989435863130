import { useState, useEffect } from 'react'
// import { useApp } from "../context/appContext";
// import AvatarModal from "../components/AvatarModel";
import { Disclosure, Switch } from '@headlessui/react'
import { useApp } from '../../context/usePortal'
import { Loader } from '../../shared/Loader'
// import { saveSettings } from "../api/calls";
// import { Loader } from "../components/loader";
import toast, { Toaster } from 'react-hot-toast'
import { Dialog, DialogContent } from '@mui/material'
import { XMarkIcon } from '@heroicons/react/24/solid'
import TransactionRangePopup from './components/TransactionRangePopup'
import LoadingPopup from './components/LoadingPopup'
import FailurePopup from './components/FailurePopup'
import PINVerificationPopup from './components/PINVerificationPopup'
import ClosePopupConfirmationPopup from './components/ClosePopupConfirmationPopup'
import ResetPINPopup from './components/ResetPINPopup'
import SuccessPopup from './components/SuccessPopup'
import { UserProfile } from '../../components/settings/user-profile'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {
  const { user } = useApp()
  const [isLoading, setIsLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const { saveUser } = useApp()
  const [open, setOpen] = useState<boolean>(false)
  const [transactionLimit, setTransactionLimit] = useState<number>(
    user?.pinTransactionLimit || 0,
  )
  const [transactionLimitConfirmed, setTransactionLimitConfirmed] =
    useState<boolean>(false)
  const [verificationProcessCompleted, setVerificationProcessCompleted] =
    useState<boolean>(false)
  const [authStatus, setAuthStatus] = useState<string>('')
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const [isLimitSet, setIsLimitSet] = useState<boolean>(
    isPositiveNumber(user?.pinTransactionLimit),
  )
  const [hasDisablingLimitFailed, setHasDisablingLimitFailed] =
    useState<boolean>(false)
  const [showResetPINPopup, setShowResetPINPopup] = useState<string>('')
  const [settings, setSettings] = useState({
    name: '',
    avatar: '',
    email: '',
    notifications: {
      news_letter: user?.sendNewsletter,
      sms: true,
      emails: user?.sendMarketing,
    },
  })

  useEffect(() => {
    if (authStatus === 'failed') {
      setTransactionLimit(user?.pinTransactionLimit || 0)
      setIsLimitSet(isPositiveNumber(user?.pinTransactionLimit))
      if (user?.pinTransactionLimit !== 0) {
        setHasDisablingLimitFailed(true)
      }
    } else {
      if (user && authStatus === 'succeed') {
        user.pinTransactionLimit = transactionLimit
      }
      setIsLimitSet(isPositiveNumber(transactionLimit))
    }
  }, [authStatus])

  const handleShowResetPINPopupChange = (value: string) => {
    setShowResetPINPopup(value)
  }
  const onClose = () => {
    if (
      authStatus.toLocaleLowerCase().trim() === 'failed' ||
      authStatus.toLocaleLowerCase().trim() === 'succeed'
    ) {
      handleConfirmClose()
    } else {
      setShowConfirmation(true)
    }
  }

  const handleConfirmClose = () => {
    setOpen(false)
    setTransactionLimitConfirmed(false)
    setVerificationProcessCompleted(false)
    setAuthStatus('')
    setShowConfirmation(false)
    setHasDisablingLimitFailed(false)
    handleShowResetPINPopupChange('')
    setTransactionLimit(user?.pinTransactionLimit || 0)
  }

  const handleScanAndPayClick = () => {
    if (isLimitSet) {
      setTransactionLimitConfirmed(true)
      setTransactionLimit(0)
    }
    setOpen(true)
  }

  const handleTransactionLimitChange = (limit: number) => {
    if (limit) {
      setTransactionLimit(limit)
    }
  }

  const handleTransactionLimitStatusChange = (status: boolean) => {
    setTransactionLimitConfirmed(status)
  }

  const handleVerificationProcessStatusChange = (status: boolean) => {
    setVerificationProcessCompleted(status)
  }
  const handleAuthStatusChange = (status: string) => {
    if (status) {
      setAuthStatus(status)
    }
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }))
    setDataChanged(true)
  }

  const handleNotificationChange = (checked: any, event: any) => {
    const name = event.target.name
    setSettings((prevSettings) => ({
      ...prevSettings,
      notifications: {
        ...prevSettings.notifications,
        [name]: checked,
      },
    }))
    setDataChanged(true)
  }
  const resetPIN = () => {
    handleConfirmClose()
    handleShowResetPINPopupChange('pin1Input')
  }
  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setButtonLoading(true)
    console.log('Settings', settings)
    const response = await saveUser({
      name: settings.name,
      email: settings.email,
      sendNewsletter: settings.notifications.news_letter || false,
      sendMarketing: settings.notifications.emails || false,
    })
    if (response.isOk()) {
      setButtonLoading(false)
      toast.success('Successfully Saved!')
      return
    }
    // console.error("Error updating settings:", error);
    setButtonLoading(false)
    //   const response = await saveSettings(token, settings);
  }
  function isPositiveNumber(value: any): boolean {
    return typeof value === 'number' && isFinite(value) && value > 0
  }
  return settings ? (
    <>
      {isLoading ? (
        <div
          style={{ minHeight: `calc(100vh - 64px)` }}
          className="flex items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div>
          <Toaster position="top-center" reverseOrder={false} />
          <Disclosure
            as="div"
            className="relative overflow-hidden bg-orange-600 pb-32">
            {({ open }) => (
              <>
                <div
                  aria-hidden="true"
                  className={classNames(
                    open ? 'bottom-0' : 'inset-y-0',
                    'absolute inset-x-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:inset-y-0',
                  )}>
                  <div className="absolute inset-0 flex">
                    <div
                      className="h-full w-1/2"
                      style={{ backgroundColor: '#f77f00' }}
                    />
                    <div
                      className="h-full w-1/2"
                      style={{ backgroundColor: '#fd9d01' }}
                    />
                  </div>
                  <div className="relative flex justify-center">
                    <svg
                      className="flex-shrink-0"
                      width={1750}
                      height={308}
                      viewBox="0 0 1750 308"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M284.161 308H1465.84L875.001 182.413 284.161 308z"
                        fill="#f77f00" // Adjusted to orange shade
                      />
                      <path
                        d="M1465.84 308L16.816 0H1750v308h-284.16z"
                        fill="#fd7e14" // Adjusted to another orange shade
                      />
                      <path
                        d="M1733.19 0L284.161 308H0V0h1733.19z"
                        fill="#fb8c00" // Adjusted to another orange shade
                      />
                      <path
                        d="M875.001 182.413L1733.19 0H16.816l858.185 182.413z"
                        fill="#ff9f00" // Adjusted to another orange shade
                      />
                    </svg>
                  </div>
                </div>
                <header className="relative py-10">
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold tracking-tight text-white">
                      Settings
                    </h1>
                  </div>
                </header>
              </>
            )}
          </Disclosure>

          <main className="relative -mt-32">
            <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-x lg:divide-y-0">
                  <form
                    className="divide-y divide-gray-200 lg:col-span-12"
                    onSubmit={(e) => handleSave(e)}>
                    {/* Profile section */}
                    <UserProfile />

                    {/* Privacy section */}
                    <div className="divide-y divide-gray-200">
                      <div className="px-4 pb-3 pt-2 sm:px-6">
                        <div>
                          <h2 className="text-lg font-medium leading-6 text-gray-900">
                            Manage Your PIN
                          </h2>
                          <Switch.Group
                            as="li"
                            className="flex items-center justify-between py-4">
                            <div className="flex flex-col">
                              <Switch.Label
                                as="p"
                                className="text-sm font-medium text-gray-900"
                                passive>
                                Scan & Pay without PIN
                              </Switch.Label>
                              <Switch.Description className="text-sm text-gray-500">
                                Turn it on to opt out PIN requirement for
                                transactions below the set limit
                              </Switch.Description>
                            </div>
                            <Switch
                              name="news_letter"
                              checked={isLimitSet}
                              //onchange function is temprory will be removed
                              onChange={handleScanAndPayClick}
                              className={classNames(
                                isLimitSet ? 'bg-orange-500' : 'bg-gray-200',
                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  isLimitSet
                                    ? 'translate-x-5'
                                    : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                          <div className="flex items-center justify-between border-t-1 py-2">
                            <div>
                              <h3 className="text-sm font-medium text-gray-900">
                                Reset PIN
                              </h3>
                              <p className="text-sm text-gray-500">
                                Forgot your PIN? Reset here to continue using
                                your AyeFace account for payment
                              </p>
                            </div>
                            <button
                              type="button"
                              className="whitespace-nowrap rounded-md bg-orange-500 p-2 text-xs font-bold text-white"
                              onClick={resetPIN}>
                              Reset PIN
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 pt-2 sm:px-6">
                        <div>
                          <h2 className="mt-1 text-lg font-medium leading-6 text-gray-900">
                            Manage Your Notifications
                          </h2>
                        </div>
                        <ul className="mt-2 divide-y divide-gray-200">
                          <Switch.Group
                            as="li"
                            className="flex items-center justify-between py-4">
                            <div className="flex flex-col">
                              <Switch.Label
                                as="p"
                                className="text-sm font-medium text-gray-900"
                                passive>
                                Text Message Alerts
                              </Switch.Label>
                              <Switch.Description className="text-sm text-gray-500">
                                Receive essential, real-time alerts and
                                reminders directly to your phone, keeping you
                                informed on what matters most and protecting
                                your rights.
                              </Switch.Description>
                            </div>
                            <Switch
                              name="sms"
                              checked={true}
                              onChange={() => {}}
                              className={classNames(
                                settings?.notifications?.sms
                                  ? 'bg-orange-500'
                                  : 'bg-gray-200',
                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  settings?.notifications?.sms
                                    ? 'translate-x-5'
                                    : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                          <Switch.Group
                            as="li"
                            className="flex items-center justify-between py-4">
                            <div className="flex flex-col">
                              <Switch.Label
                                as="p"
                                className="text-sm font-medium text-gray-900"
                                passive>
                                Newsletter Subscriptions
                              </Switch.Label>
                              <Switch.Description className="text-sm text-gray-500">
                                Get the scoop on the latest trends in tech,
                                security, privacy, payments, and financial
                                literacy. Receive curated content from us that
                                keeps you both informed and engaged.
                              </Switch.Description>
                            </div>
                            <Switch
                              name="news_letter"
                              checked={settings?.notifications?.news_letter}
                              onChange={(checked) =>
                                handleNotificationChange(checked, {
                                  target: { name: 'news_letter' },
                                })
                              }
                              className={classNames(
                                settings?.notifications?.news_letter
                                  ? 'bg-orange-500'
                                  : 'bg-gray-200',
                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  settings?.notifications?.news_letter
                                    ? 'translate-x-5'
                                    : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                          <Switch.Group
                            as="li"
                            className="flex items-center justify-between py-4">
                            <div className="flex flex-col">
                              <Switch.Label
                                as="p"
                                className="text-sm font-medium text-gray-900"
                                passive>
                                Marketing & Offers
                              </Switch.Label>
                              <Switch.Description className="text-sm text-gray-500">
                                Be the first to know about new features, offers,
                                and opportunities so you can maximize your
                                potential and reap the rewards.
                              </Switch.Description>
                            </div>
                            <Switch
                              name="sms"
                              checked={settings.notifications?.emails}
                              onChange={(checked) =>
                                handleNotificationChange(checked, {
                                  target: { name: 'emails' },
                                })
                              }
                              className={classNames(
                                settings.notifications?.emails
                                  ? 'bg-orange-500'
                                  : 'bg-gray-200',
                                'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
                              )}>
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  settings.notifications?.emails
                                    ? 'translate-x-5'
                                    : 'translate-x-0',
                                  'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                          </Switch.Group>
                        </ul>
                      </div>
                      <div className="mt-4 flex justify-end px-4 py-4 sm:px-6">
                        <button
                          type="submit"
                          className={`ml-5 inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            dataChanged
                              ? 'bg-green-700 hover:bg-green-800 focus:ring-green-500'
                              : 'cursor-not-allowed bg-gray-400'
                          }`}
                          disabled={!dataChanged}>
                          {buttonLoading ? <>Saving...</> : <>Save</>}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {showResetPINPopup.toLowerCase().trim() !== '' && (
              <div className="rounded-2xl p-0">
                <Dialog
                  open={showResetPINPopup.toLowerCase().trim() !== ''}
                  onClose={onClose}
                  className="boder-red-300 rounded-xl border-1 p-0">
                  <DialogContent
                    dividers
                    className="min-w-[200px] max-w-[420px] p-0">
                    <button onClick={onClose} className="ms-auto block">
                      <XMarkIcon className="h-6 text-end" />
                    </button>
                    <ResetPINPopup
                      onClose={handleConfirmClose}
                      handleShowResetPINPopupChange={
                        handleShowResetPINPopupChange
                      }
                      showResetPINPopup={showResetPINPopup}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            )}

            {open && (
              <div className="rounded-2xl p-0">
                <Dialog
                  open={open}
                  onClose={onClose}
                  className="boder-red-300 rounded-xl border-1 p-0">
                  <DialogContent
                    dividers
                    className="min-w-[200px] max-w-[420px] p-0">
                    <button onClick={onClose} className="ms-auto block">
                      <XMarkIcon className="h-6 text-end" />
                    </button>

                    {!transactionLimitConfirmed ? (
                      <TransactionRangePopup
                        handleTransactionLimitChange={
                          handleTransactionLimitChange
                        }
                        handleTransactionLimitStatusChange={
                          handleTransactionLimitStatusChange
                        }
                        transactionLimit={transactionLimit}
                      />
                    ) : !verificationProcessCompleted ? (
                      <PINVerificationPopup
                        handleVerificationProcessStatusChange={
                          handleVerificationProcessStatusChange
                        }
                        handleAuthStatusChange={handleAuthStatusChange}
                        transactionLimit={transactionLimit}
                        resetPIN={resetPIN}
                      />
                    ) : authStatus.toLocaleLowerCase().trim() === 'succeed' ? (
                      <SuccessPopup
                        transactionLimit={transactionLimit}
                        onClose={handleConfirmClose}
                        status={isLimitSet ? 'turnedOn' : 'turnedOff'}
                        type="setLimit"
                      />
                    ) : authStatus.toLocaleLowerCase().trim() === 'failed' ? (
                      <FailurePopup
                        hasDisablingLimitFailed={hasDisablingLimitFailed}
                        onClose={handleConfirmClose}
                      />
                    ) : authStatus.toLocaleLowerCase().trim() === '' ? (
                      <LoadingPopup />
                    ) : null}
                  </DialogContent>
                </Dialog>
              </div>
            )}
            {showResetPINPopup.toLowerCase().trim() !== '' ? (
              <ClosePopupConfirmationPopup
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={handleConfirmClose}
                message="Quit Resetting PIN?"
              />
            ) : (
              open && (
                <ClosePopupConfirmationPopup
                  open={showConfirmation}
                  onClose={() => setShowConfirmation(false)}
                  onConfirm={handleConfirmClose}
                  message="Quit Setting Scan And Pay Transaction Limit?"
                />
              )
            )}
          </main>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
