import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AyeProvider } from './context/usePortal'
import { UserProvider } from './context/app-context'
import { ApiCacheProvider } from './context/api-context'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    {/* <Suspense
      fallback={
        <div
          style={{ minHeight: `calc(100vh - 64px)` }}
          className="flex items-center justify-center"
        >
          <Loader />
        </div>
      }
    > */}
    <ApiCacheProvider>
      <AyeProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </AyeProvider>
    </ApiCacheProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
