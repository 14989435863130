// import { LOGO_PATH } from "../constants/logo";
// import { PAYMET_LOGO_PATH } from "../constants/logo";

// import { Loader } from "../components/loader";
// import Footer from "../components/footer";
// import { PAYNET_LOGO_PATH } from "../constants/logo";
// import { requestOtp } from "../api/calls";
import { useCallback, useEffect, useState } from 'react'
// import { UserContext, useApp } from "../context/appContext";
import { PhoneNumberUtil } from 'google-libphonenumber'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { useNavigate } from 'react-router-dom'
import { LOGO_PATH, PAYNET_LOGO_PATH } from '../../constants/logo'
import { useApp } from '../../context/usePortal'

import 'react-phone-input-2/lib/bootstrap.css' // for material design
import { useMutation } from '../../hooks/useMutation'
import { ayeApi } from '../../core'
// LOGO_PATH
// import ReCAPTCHA from "react-google-recaptcha";
// import axios from "axios";
// import { baseURL } from "../api/url";

// const statusStyles = {jnmhujnuhmmhu
//   success: 'text-green-600',
//   failed: 'text-red-600',
// };
const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}
function classNames(...classNamees: any) {
  return classNamees.filter(Boolean).join(' ')
}

export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState<any>('+60')
  const [otp, setOtp] = useState<any>({})
  const [otpValue, setOtpValue] = useState<any>('')
  const [countdown, setCountdown] = useState<any>(60)
  const [isTimerActive, setIsTimerActive] = useState<any>(false)
  const [messageVisibilityTimer, setMessageVisibilityTimer] =
    useState<any>(null)
  const [recaptchaToken, setRecaptchaToken] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState<any>('')
  const [phoneValidationMessage, setPhoneValidationMessage] = useState<any>('')
  const [otpValidationMessage, setOtpValidationMessage] = useState<any>('')
  const [otpAttempt, setOtpAttempt] = useState<number>(0)
  const [recaptchaValidationMessage, setRecaptchaValidationMessage] =
    useState<any>('')

  const isValid = isPhoneValid(phoneNumber)
  const [countryCode, setCountryCode] = useState<string | null>(null)

  const { getCode, login } = useApp()
  const loginUser = useMutation(ayeApi.loginPortal, {
    onSuccess: () => {
      console.log('Success')
    },
  })
  const navigate = useNavigate()

  useEffect(() => {
    let timer: any
    if (isTimerActive && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else if (countdown === 0) {
      setIsTimerActive(false)
    }
    return () => clearTimeout(timer)
  }, [isTimerActive, countdown])

  const handlePhoneNumberChange = (value: any, data: CountryData) => {
    // Ensure the value starts with a '+'
    const formattedValue = value.startsWith('+') ? value : `+${value}`
    setCountryCode(data.dialCode!)
    // Check if the formatted phone number is valid
    if (formattedValue) {
      if (isPhoneValid(formattedValue)) {
        setPhoneValidationMessage('')
      } else {
        setPhoneValidationMessage('Please enter a valid phone number.')
      }
    } else {
      setPhoneValidationMessage('Phone number is required.')
    }

    // Update state with the formatted phone number
    setPhoneNumber(formattedValue)
  }

  const handleOtpValueChange = (event: any) => {
    setOtpValue(event.target.value)
    if (event.target.value) {
      setOtpValidationMessage('')
    }
  }

  const handleRecaptchaChange = useCallback((token: any) => {
    setRecaptchaToken(token)
    if (token) {
      setRecaptchaValidationMessage('')
    }
  }, [])

  const handleGetCode = async () => {
    if (!phoneNumber || !isPhoneValid(phoneNumber)) {
      const isValidInput = !isPhoneValid(phoneNumber)
      console.log(isValidInput)
      if (!isValidInput) {
        setPhoneValidationMessage('Please enter only digits.')
        return
      }
      setPhoneValidationMessage('Phone number must have correct digits.')
      return
    }
    setPhoneValidationMessage('')
    setOtpValidationMessage('')

    const data = await getCode(
      countryCode + '-' + phoneNumber.replace(`+${countryCode}`, ''),
    )
    console.log('====================================')
    console.log({ data })
    console.log('====================================')

    setOtp({
      message: data.isOk() ? data.response?.message : data.error?.detail,
      tag: data.isOk() ? 'success' : 'failed',
    })

    if (data.isOk()) {
      setIsTimerActive(true)
      setCountdown(60)
      setOtpAttempt(0)
    }

    // Set message visibility timer
    setMessageVisibilityTimer(
      setTimeout(() => {
        setOtp((otp: any) => ({ ...otp, message: '' }))
      }, 5000),
    )
    // } catch (error) {
    //   setOtp({ message: error.message, tag: "failed" });
    //   setMessageVisibilityTimer(
    //     setTimeout(() => {
    //       setOtp((otp) => ({ ...otp, message: "" }));
    //     }, 5000)
    //   );
    // }
  }

  const verifyOtp = async () => {
    // if (!isTimerActive) {
    //   return
    // }

    let isValid = true

    if (!otpValue) {
      setOtpValidationMessage('OTP is required')
      isValid = false
      return
    } else {
      setOtpValidationMessage('')
    }

    if (otpAttempt >= 3) {
      setOtpValidationMessage(
        'Too many incorrect attempts. Please request for a new OTP.',
      )
      setIsTimerActive(false)
      return
    } else {
      setOtpAttempt((prev) => prev + 1)
    }

    // if (!recaptchaToken) {
    //   setRecaptchaValidationMessage("Please complete the reCAPTCHA");
    //   isValid = false;
    // } else {
    //   setRecaptchaValidationMessage("");
    // }

    // if (!isValid) {
    //   return;
    // }
    const response = await login(
      countryCode + '-' + phoneNumber.replace(`+${countryCode}`, ''),
      otpValue,
    )
    if (response.isOk()) return navigate('/portal')
    if (response.error) {
      setOtpValue('')
      setOtpValidationMessage('Incorrect OTP. Please try again.')
    }
  }
  return (
    <>
      <div className="flex w-screen items-center justify-center bg-gray-50 text-gray-600">
        <div className="relative">
          <div className="relative mt-24 flex flex-col rounded-lg border-gray-400 bg-white px-4 shadow-sm sm:w-[30rem]">
            <div className="flex-auto p-6">
              <div className="mb-3 flex flex-shrink-0 flex-grow-0 items-center justify-center overflow-hidden">
                <img className="mb-3 h-12 w-auto" src={LOGO_PATH} alt="Logo" />{' '}
              </div>
              <h4 className="mb-2 text-center font-medium text-gray-700 xl:text-2xl">
                Welcome to{' '}
                <span className="inline-block bg-gradient-to-r from-orange-600 to-orange-300 bg-clip-text font-medium text-transparent xl:text-2xl">
                  Aye Face
                </span>{' '}
                User Portal
              </h4>
              <p className="mb-6 text-center text-gray-500">
                Please sign-in using OTP to access your account
              </p>

              <form id="" className="mb-4" action="#" method="POST">
                <div className="mb-4">
                  <span className="mb-2 block text-xs font-medium uppercase text-gray-700">
                    Phone Number
                  </span>
                  <PhoneInput
                    country={'bd'}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    inputStyle={{ width: '100%' }}
                    enableSearch={false}
                    disableCountryCode={true}
                    countryCodeEditable={true} // Allow users to edit the country code
                    placeholder="Enter your phone number"
                    autoFormat={false}
                  />
                  {/* {!isValid && (
                    <div style={{ color: 'red' }}>Phone is not valid</div>
                  )} */}
                  {phoneValidationMessage && (
                    <div className="text-sm text-red-600">
                      {phoneValidationMessage}
                    </div>
                  )}
                </div>
                {/* <div className="mb-4">
                  <div className="flex rounded-md border border-gray-400">
                    <span className="flex items-center bg-gray-100 rounded-l-md border-gray-400 px-3 text-sm text-gray-600">
                      <span className="mr-2 h-4 w-4">🇲🇾</span>
                      +60
                    </span>
                    <input
                      type="tel"
                      maxLength={11}
                      inputMode="numeric"
                      minLength={11}
                      className="w-full appearance-none rounded-r-md bg-white py-2 px-3 text-sm outline-none focus:border-orange-500 focus:shadow"
                      id="phone-number"
                      name="phone-number"
                      onChange={handlePhoneNumberChange}
                      placeholder="Enter your phone number"
                      autoFocus
                    />
                  </div>
                  {phoneValidationMessage && (
                    <div className="text-sm text-red-600">
                      {phoneValidationMessage}
                    </div>
                  )}
                </div> */}

                <div className="mb-4">
                  <label
                    htmlFor="otp"
                    className="mb-2 block text-xs font-medium uppercase text-gray-700">
                    OTP Code
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      onChange={handleOtpValueChange}
                      value={otpValue}
                      placeholder="Enter your OTP"
                      className="focus:shadow-outline block w-full cursor-text appearance-none rounded-md border border-gray-400 bg-white px-3 py-2 text-sm text-gray-700 focus:border-gray-500 focus:outline-none"
                      autoFocus
                    />
                    <button
                      type="button"
                      onClick={() => handleGetCode()}
                      className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-3 text-sm ${
                        isTimerActive
                          ? 'border border-gray-400 bg-gray-100 text-gray-900 hover:bg-gray-200'
                          : 'bg-orange-500 text-white hover:bg-orange-600'
                      }`}
                      disabled={isTimerActive}>
                      {isTimerActive ? `${countdown} seconds` : 'Get OTP'}
                    </button>
                  </div>
                  {otpValidationMessage && (
                    <div className="text-sm text-red-600">
                      {otpValidationMessage}
                    </div>
                  )}
                  {otp.message ? (
                    <div
                      className={classNames(
                        otp.tag === 'failed' ? 'text-red-500' : '', // If otp.tag is not 'failed', apply 'text-red-500'
                        'mt-3 text-sm', // Always apply 'mt-3 text-xl'
                      )}>
                      {otp.message}
                    </div>
                  ) : (
                    <></>
                  )}
                  {errorMessage && isTimerActive && (
                    <div className="mt-3 text-sm text-red-600">
                      {errorMessage}
                    </div>
                  )}
                  {/* <div className="pt-5 flex justify-center items-center">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={handleRecaptchaChange}
                    />
                  </div>
                  {recaptchaValidationMessage && (
                    <div className="text-sm text-red-600">
                      {recaptchaValidationMessage}
                    </div>
                  )} */}
                </div>

                <div className="mb-3 mt-5">
                  <button
                    type="button"
                    onClick={verifyOtp}
                    className="grid w-full cursor-pointer select-none rounded-md border border-orange-500 bg-orange-500 px-5 py-2 text-center align-middle text-sm font-bold text-white shadow hover:border-orange-600 hover:bg-orange-600 hover:text-white focus:border-orange-600 focus:bg-orange-600 focus:text-white focus:shadow-none">
                    Sign in
                  </button>
                </div>
                <p className="mb-3 text-center">
                  Don't have an account?
                  <a
                    href="https://dev-register.ayeface.com"
                    className="cursor-pointer text-orange-500 no-underline hover:text-orange-600">
                    {' '}
                    Register Now{' '}
                  </a>
                  <br></br>
                  <span className="mt-2 text-sm">
                    Use your phone for an optimal registration experience
                  </span>
                </p>
              </form>
            </div>

            <p className="mb-4 text-center text-sm">
              Need help?
              <a
                href="https://api.whatsapp.com/send?phone=60162666192"
                className="cursor-pointer text-orange-500 no-underline hover:text-orange-600">
                {' '}
                Get in touch!{' '}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 pb-5 pt-10 text-center text-xs text-gray-500 sm:text-sm">
        <div className="mb-2 font-bold">
          Authorised by{' '}
          <img
            src={PAYNET_LOGO_PATH}
            alt="Paynet"
            className="inline h-8 align-middle sm:h-10"
          />
        </div>
        © {new Date().getFullYear()} Aye Solutions. All rights reserved.
      </div>
    </>
  )
}
