import { Outlet } from 'react-router-dom';
import Nav from './Nav';
import Footer from './Footer';
import { memo } from 'react';

const Layout = memo(() => {
  return (
    <div className='min-h-full'>
      <Nav />
      <main>
        <div className='flex flex-1 flex-col lg:pl-64'>
          <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
});

export default Layout;
