import { CircleArrowLeft } from 'lucide-react';
import { useNavigate, type To } from 'react-router-dom';
import { cn } from '../../lib/utils';
import type { ReactNode } from 'react';

interface Props {
  href?: To;
  title?: ReactNode;
  className?: string;
}

const SectionHeader: React.FC<Props> = ({ href, title, className }) => {
  const navigate = useNavigate();
  return (
    <div className={cn('flex gap-2 items-center mx-3 mt-4', className)}>
      <button
        onClick={() => navigate(href ?? (-1 as To))}
        className='p-2'
      >
        <CircleArrowLeft strokeWidth={'1'} />
      </button>
      {title ?? (
        <h1 className='text-lg font-medium leading-6 text-gray-900'>
          AyeFace Rewards
        </h1>
      )}
    </div>
  );
};
export default SectionHeader;
