// @ts-check

// import { setupCache ,buildMemoryStorage} from "axios-cache-interceptor"
import axios from 'axios'
import { baseURL } from './url'

// Create an axios instance
export const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
})
// use this if ever needed to cache requests  (not needed for now)
// const cachedApi = setupCache(api, {
//   // Use localStorage for cache persistence
//   storage: buildWebStorage(localStorage, 'axios-cache:'),

//   // Default cache settings
//   ttl: 5 * 60 * 1000, // 5 minutes

//   // Cache configuration
//   interpretHeader: true, // Read cache headers
//   methods: ['get'], // Only cache GET requests

//   // Cache key generation
//   generateKey: req => {
//     return `${req.method}:${req.url}:${JSON.stringify(req.params)}`
//   },

//   // Debug mode for development
//   debug: (msg) => {
//     if (process.env.NODE_ENV === 'development') {
//       console.log(msg)
//     }
//   }
// })

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const newToken = await refreshAccessToken()
      if (newToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
        return api(originalRequest)
      }
    }
    return Promise.reject(error)
  },
)

// Fetch customer payment methods
export async function fetchCustomerMethods() {
  try {
    const response = await api.get('/payment-methods')
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

// Fetch customer transactions

export async function fetchCustomerTransactions(page: string) {
  try {
    const response = await api.get('/user-transactions', { params: { page } })
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

export async function requestOtp(
  phone_number: string,
  country_code: string,
): Promise<{ message: string; otp: string }> {
  try {
    const response = await api.post('/auth/otp', {
      user_phone_number: phone_number,
      country_code,
    })
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

// Login request

export async function loginRequest(
  phone_number: string,
  otp: string | number,
  country_code = '+880',
): Promise<{ auth_token: string; refresh_token: string }> {
  try {
    const response = await api.post('/auth/login', {
      user_phone_number: phone_number,
      country_code: country_code,
      otp: otp,
    })
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

// Get general info of the customer
export async function getInfo() {
  try {
    const response = await api.get('/user-info')
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

export async function saveSettings(settings: { email: string; name: string }) {
  try {
    const response = await api.post('/save-info', {
      email: settings.email,
      name: settings.name,
    })
    return response.data
  } catch (error) {
    console.error('Fetching data failed: ', error)
    throw error
  }
}

export async function terminateMethod(pin: string, consentRef: string) {
  const response = await api.post('/method/termination', {
    pin: pin,
    consent_ref: consentRef,
  })
  return response.data
}

/**
 *
 * @returns string
 */
export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refresh_token')
    if (!refreshToken) {
      throw new Error('No refresh token found')
    }
    const response = await axios.post(`${baseURL}/auth/refresh`, {
      refresh_token: refreshToken,
    })
    if (response.status === 200) {
      localStorage.setItem('token', response.data.auth_token)
      localStorage.setItem('refresh_token', response.data.refresh_token)
      return response.data.auth_token
    } else {
      throw new Error('Failed to refresh token')
    }
  } catch (error) {
    console.error('Token refresh failed:', error)
    return null
  }
}
