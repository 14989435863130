import SectionHeader from './ui/section-header'
import Branches from './branch'
import { useState } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

const dataState = [
  {
    name: 'CHAGEE - SS2 Branch',
    address: '5, Jalan SS 2/67, SS 2, 47300 Petaling Jaya, Selangor',
  },
  {
    name: 'CHAGEE - Times Square',
    address:
      'Lot LG-18C-03A, Berjaya Times Square, 1 Jln Imbi, Bukit Bintang, 55100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
  },
  {
    name: 'CHAGEE - Mid Valley Megamall',
    address:
      'Lot F-012, Level 1, Mid Valley Megamall, Lingkaran Syed Putra, 58000 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
  },
  {
    name: 'Café Aroma - Bangsar Village',
    address:
      'Lot G-18, Bangsar Village 1, 1 Jalan Telawi 1, Bangsar Baru, 59100 Kuala Lumpur',
  },
  {
    name: 'Café Aroma - Sunway Pyramid',
    address:
      'Lot LG2-125, Sunway Pyramid Mall, No. 3, Jalan PJS 11/15, Bandar Sunway, 47500 Subang Jaya, Selangor',
  },
  {
    name: 'Snacko Delight - 1 Utama Shopping Centre',
    address:
      'Lot LG-123, 1 Utama Shopping Centre, Lebuh Bandar Utama, 47800 Petaling Jaya, Selangor',
  },
  {
    name: 'Snacko Delight - Paradigm Mall',
    address:
      'Lot UG-09, Paradigm Mall, No. 1, Jalan SS7/26A, Kelana Jaya, 47301 Petaling Jaya, Selangor',
  },
  {
    name: 'HealthyLife - The Gardens Mall',
    address:
      'Lot S-231, Level 2, The Gardens Mall, Lingkaran Syed Putra, Mid Valley City, 59200 Kuala Lumpur',
  },
  {
    name: 'HealthyLife - Pavilion KL',
    address:
      'Lot 5.16.00, Level 5, Pavilion KL, 168, Jalan Bukit Bintang, 55100 Kuala Lumpur',
  },
  {
    name: 'CHAGEE - Queensbay Mall',
    address:
      'Lot LG-21, Queensbay Mall, Persiaran Bayan Indah, 11900 Bayan Lepas, Penang',
  },
  {
    name: 'Café Aroma - Gurney Plaza',
    address:
      'Lot 170-06-12, Gurney Plaza, 170, Persiaran Gurney, 10250 George Town, Penang',
  },
  {
    name: 'Snacko Delight - Komtar JBCC',
    address:
      'Lot L1-33, Level 1, Komtar JBCC, Jalan Wong Ah Fook, 80000 Johor Bahru, Johor',
  },
  {
    name: 'HealthyLife - AEON Tebrau City',
    address:
      'Lot G-50, AEON Tebrau City, Jalan Desa Tebrau, Taman Desa Tebrau, 81100 Johor Bahru, Johor',
  },
]

const AllLocations = () => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(dataState)

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    setData(
      dataState.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase()),
      ),
    )
  }

  return (
    <section className="flex flex-col  gap-y-4">
      <SectionHeader
        title={
          <h1 className="text-lg font-semibold">
            Locations
          </h1>}
      />
      <div className="max-w-[calc(100%_-_2rem)] w-full mx-auto">
        <div className="relative mb-6">
          <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Search"
            className="w-full pl-10 pr-4 py-2 bg-gray-100 border-0 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
          />
        </div>
        <Branches branches={data} className={'px-2'} />
      </div>

    </section>
  )
}

export default AllLocations