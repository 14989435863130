import { cn } from '../../lib/utils';

interface Campaign {
  campaignId: string;
  campaignImageUrl: string;
  title: string;
  description: string;
}

export default function CampaignCard({
  campaign,
  className,
}: {
  campaign: Campaign;
  className?: string;
}) {
  return (
    <div
      className={cn(
        'h-full  overflow-hidden rounded-2xl bg-white shadow-lg drop-shadow-lg',
        className
      )}
    >
      <div className='flex relative items-center w-full h-24 justify-center p-0'>
        <img
          src={campaign.campaignImageUrl}
          className='absolute inset-0 w-full h-full'
          alt={campaign.title}
        />
      </div>
      <div className='flex flex-col text-xs font-semibold items-center space-y-4 p-3 pb-4 text-start'>
        <p className=' font-semibold  line-clamp-2'>{campaign.description}</p>
      </div>
    </div>
  );
}
