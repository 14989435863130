/**
 * The type `ResponseError` represents an error response with a `detail` property of type string.
 * @property {string} detail - The `detail` property is a string that provides additional information
 * about the error that occurred in the response.
 */
export type ResponseError = {
  detail: string;
};
/* The line `export const baseURL = 'http://49.0.200.187/api/v1';` is exporting a constant variable
named `baseURL`. This variable holds the base URL for an API endpoint. It is typically used as a
prefix for constructing complete URLs for making API requests. */
export const baseURL = 'https://dev-api.ayeface.com';
// export const baseURL = `http://localhost:3003`; // this is backend
export const wsURL = `wss://${URL}/api/v2/ws`;
