import React, { useState } from 'react';
import { VISA_LOGO2_PATH } from '../../../constants/logo';
import { UNION_PAY_LOGO } from '../../../constants/logo';
import { MASTERCARD_LOGO_PATH } from '../../../constants/logo';
import { AMERICAN_EXPRESS_LOGO_PATH } from '../../../constants/logo';
import { TrashIcon } from '@heroicons/react/24/outline';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import DeleteConfirmation from './DeleteConfirmation';
import SetPreferedConfirmation from './SetPreferredConfirmation';
import type { PaymentConsent } from '../../../core/api/http-methods/get-payment-methods';

interface MethodProps {
  method: PaymentConsent;
  handlePaymentMethodsModified: () => void;
}

// Rename IOSSwitch to SwitchButton
const SwitchButton = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 50,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#9A9A9A',
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(26px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#5CB891',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const CardMethod: React.FC<MethodProps> = ({
  method,
  handlePaymentMethodsModified,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [consentId, setConsentId] = useState<string>(
    method?.payment_consent_id
  );
  const [isPreferredMethodModelOpen, setIsPreferredMethodModelOpen] =
    useState<boolean>(false);
  const handleChangePreferredMethod = () => {
    setIsPreferredMethodModelOpen(true);
  };

  const deleteClicked = (consentId: string) => {
    setConsentId(consentId);
    setIsModalOpen(true);
  };
  const logoPathMap: Record<string, string> = {
    visa: VISA_LOGO2_PATH,
    unionpay: UNION_PAY_LOGO,
    mastercard: MASTERCARD_LOGO_PATH,
    amex: AMERICAN_EXPRESS_LOGO_PATH,
  };
  const logoHeightMap: Record<string, string> = {
    visa: 'h-[27px]',
    unionpay: 'h-[27px]',
    mastercard: 'h-[40px]',
    amex: 'h-[33px]',
  };

  return (
    <div
      className='sm:w-100 md:w-1/2 lg:w-1/2 bg-gradient-to-r rounded-3xl px-6 pt-4 pb-1 text-white shadow-lg sm:mx-auto md:mx-1 my-4 min-w-[340px] max-w-[370px]'
      style={{
        background: `linear-gradient(-55.329669692156095deg, rgba(248, 99, 64, 1) 0.000005960464117517737%, rgba(254, 199, 69, 1) 100.00000000000234%)`,
      }}
    >
      <div className='flex justify-between items-center '>
        <h6 className='text-xs'>Your Preferred Payment Method</h6>
        <div className='flex items-center space-x-2'>
          <button
            type='button'
            onClick={() => deleteClicked(method?.payment_consent_id)}
            aria-label='Delete'
          >
            <TrashIcon
              className='h-6 w-6 text-white'
              aria-hidden='true'
            />
          </button>
        </div>
      </div>
      <div className='px-3 mt-1'>
        <FormControlLabel
          control={
            <SwitchButton
              checked={method?.preferred}
              onChange={handleChangePreferredMethod}
            />
          }
          label=''
        />
      </div>
      <div className='flex justify-between items-center'>
        <div>
          <span className='block text-xs font-light mt-4'>Method</span>
          <h2 className='text-xl font-bold tracking-wider uppercase'>
            {method?.method}
          </h2>
        </div>
        <div>
          <img
            src={logoPathMap[method?.card_brand?.toLocaleLowerCase() || '']} // Default to empty string if the brand is not found
            alt={method?.card_brand || 'Payment Brand'}
            className={`inline-block object-contain ${
              logoHeightMap[method?.card_brand?.toLocaleLowerCase() || ''] ||
              'h-[50px]'
            }  align-middle`}
          />
        </div>
      </div>
      <div className='my-2'>
        <h5
          className='text-2xl tracking-widest ps-2 shadow-xs'
          style={{ textShadow: '0px 3px #9A9A9A' }}
        >
          <span className='text-3xl'>**** **** **** </span>
          <span>{method?.card_last4}</span>
        </h5>
      </div>
      <div className='flex justify-start items-center mb-2 gap-x-10'>
        <div>
          <h4 className='text-xs font-light'>Status</h4>
          <h3 className='text-md font-bold tracking-wide'>{method?.status}</h3>
        </div>
        <div>
          <h4 className='text-xs font-light'>Added</h4>
          <h3 className='text-md font-bold tracking-wide'>
            {method?.created_at.toString()}
          </h3>
        </div>
      </div>

      {isModalOpen && (
        <DeleteConfirmation
          open={isModalOpen}
          setOpen={setIsModalOpen}
          consentId={consentId}
          handlePaymentMethodsModified={handlePaymentMethodsModified}
        />
      )}
      {isPreferredMethodModelOpen ? (
        <SetPreferedConfirmation
          isAlreadyPreferred={method?.preferred}
          open={isPreferredMethodModelOpen}
          setOpen={setIsPreferredMethodModelOpen}
          consentId={method?.payment_consent_id}
          method={method?.method || ''}
          handlePaymentMethodsModified={handlePaymentMethodsModified}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardMethod;
