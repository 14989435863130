import { useCallback, useContext } from "react";
export type GuardedReturn<T> = {
  isOk: () => boolean;
  response?: T;
  error?: { detail: string };
};
export default function catchAsync<TArgs extends any[], TReturn>(
  cb: (...args: TArgs) => TReturn,
  loader?: React.Dispatch<React.SetStateAction<boolean>>,
  setStateVariable?: React.Dispatch<React.SetStateAction<any>>
) {
  return async function (...args: TArgs) {
    if (loader) {
      loader(true);
    }
    try {
      const response = await cb(...args);
      if (loader) {
        loader(false);
      }
      return {
        isOk: () => true,
        response,
      };
    } catch (e: any) {
      if (loader) {
        loader(false);
      }
      setStateVariable?.(undefined);
      return {
        isOk: () => false,
        error: e,
      };
    }
  } as (...args: TArgs) => Promise<GuardedReturn<Awaited<TReturn>>>;
}
