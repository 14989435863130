import { myRewards } from '../../dummy/my-rewards';
import Brand from './brand';
import { cn } from '../../lib/utils';

interface Props {
  className?: string;
  hideTitle?: boolean;
}

export default function JoinedRewards({ className, hideTitle = false }: Props) {
  const joinedRewards = myRewards.joinedRewards;
  return (
    <div className={cn('flex flex-col gap-y-4', className)}>
      {!hideTitle && (
        <h3 className='text-lg font-normal mx-4'>My Joined Rewards</h3>
      )}
      <div className='flex flex-wrap  w-full gap-y-2 md:gap-y-4 items-stretch justify-start max-w-full'>
        {joinedRewards.map((brand, index) => (
          <Brand
            key={index}
            {...brand}
            className={'basis-1/5'}
          />
        ))}
      </div>
    </div>
  );
}
