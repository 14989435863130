import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import { loginRequest } from '../api/calls'
import { getUser, updateUser } from '../api/user.api'
import { AppContextI, UserI } from '../types/user'

export const UserContext = createContext<AppContextI | undefined>(undefined)

interface UserProviderProps {
  children: ReactNode
}

export function UserProvider({ children }: UserProviderProps) {
  const [userInfo, setUserInfo] = useState<UserI | undefined>(undefined)
  const [token, setToken] = useState<string | null>(null)
  const [counter, setCounter] = useState<number>(0)

  const userLogin: AppContextI['userLogin'] = async (id, otp, token) => {
    console.log(
      `🚀 ~ file: appContext.js:40 ~ userLogin ~ (id, otp, token):`,
      id,
      otp,
      token,
    )

    try {
      const data = await loginRequest(id, otp)
      console.debug('ℹ️ ~ file: appContext.js:49 ~ userLogin ~ data:', data)
      setToken(data.auth_token)
      localStorage.setItem('token', data.auth_token)
      localStorage.setItem('refresh_token', data.refresh_token)
    } catch (err: any) {
      const error = new Error(err.message)
      //@ts-expect-error - No check
      error.status = err.status
      throw error
    }
  }

  const updateUserInfo: AppContextI['updateUserInfo'] = async (newInfo) => {
    const prevState = userInfo
    if (!userInfo) return
    setUserInfo({ ...userInfo, ...newInfo })
    await updateUser({ ...userInfo, ...newInfo }).catch((e) => {
      console.error(e)
      setUserInfo(prevState)
    })
  }

  useEffect(() => {
    const savedToken = localStorage.getItem('token')
    console.log(
      `🚀 ~ file: appContext.js:15 ~ useEffect ~ savedToken:`,
      savedToken,
    )

    if (savedToken) {
      setToken(savedToken)

      async function fetchData() {
        try {
          const data = await getUser()
          console.debug('ℹ️ ~ file: appContext.js:52 ~ fetchData ~ data:', data)
          setUserInfo(data.user)
        } catch (error: any) {
          if (error.status === 401) {
            // localStorage.removeItem('token')
            // localStorage.removeItem('refresh_token')
            setToken(null)
            setUserInfo(undefined)
          } else {
            console.error('Failed to fetch user data:', error)
          }
        }
      }

      fetchData()
    }
  }, [token, counter])
  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        updateUserInfo,
        userLogin,
        token,
      }}>
      {children}
    </UserContext.Provider>
  )
}

export const useApp = (): AppContextI => {
  const context = useContext(UserContext)
  if (!context) {
    throw Error('useApp should be used within UserProvider.')
  }
  return context
}
