import React, { useState } from 'react';
import { PhoneNumberInput } from './PhoneNumberInput';
import { PAYNET_LOGO2_PATH } from "../../../constants/logo";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
type CreditDebitComponentProps = {
  setPhoneNumber: (value:string)=>void;
  phoneNumberError: () => boolean;
  idTypeError: () => boolean;
  idList: any[];
  idType?: string;
  handleChange: (event: any) => void;
  setID: (value:string)=>void;
  handleSubmit: () => void;
  goBackToSelectMethod: () => void;
};
export function DuitnowPopup({
  setPhoneNumber,
  phoneNumberError,
  idList,
  idType,
  handleChange,
  setID,
  idTypeError,
  handleSubmit,
  goBackToSelectMethod,
}: CreditDebitComponentProps) {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOptionId,setSelectedOptionId] = useState(idList[0].value)
  return (
    <div>
      <button type="button" className='h-5  relative top-[-23px]  text-black w-6 mb-1' onClick={goBackToSelectMethod} >  <ChevronLeftIcon /></button>
      <h2 className='font-bold text-black text-2xl'>
        Duitnow ID
      </h2  >
      <h4 className='text-black text-md my-2' >Please enter the ID you used to link with your  <span className='font-bold' >DuitNow</span> in your banking
        app
      </h4>
      <form action="">
        <label htmlFor="" className='relative p-1 text-sm font-bold top-3 z-10 bg-white left-2'>
          Payment ID <span className="text-red-500">*</span>
        </label>
        <br />
        <select id="paymentId" name="paymentId" className='p-3 border-1 shadow-sm border-gray-300 focus:outline-none focus:ring-gray-400 focus:ring-1  rounded-lg w-full' value={idList.find((e) => e.label === idType)?.label} onChange={(e)=>{ setSelectedOptionId(e.target.value); handleChange(e);}} >
          {idList?.map((id) => {
            return <option key= {id.value}  value={id.value} className='p-2 bg-white text-black hover:bg-white hover:text-black ' >{id.label}</option>;
          })}
        </select>
        {
          selectedOptionId ? (
            selectedOptionId !== '5' ? (
              <>
                <label htmlFor="" className='relative p-1 text-sm font-bold top-3 z-10  bg-white left-2 mt-5'>
                  {selectedOptionId === '3' ? 'Passport' : 'IC'} <span className="text-red-500">*</span>
                </label>
                <br />
                <input
                  type="text"
                  placeholder={selectedOptionId === '3' ? 'Passport Number' : 'IC'}
                  className='p-3 border-1 shadow-sm border-gray-300 focus:outline-none focus:ring-gray-400 focus:ring-1 rounded-lg w-full'
                  onChange={(e) => setID(e.target.value)}
                />
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

        <PhoneNumberInput setPhoneNumber={setPhoneNumber} />
        <div className='flex justify-center items-center my-3' onClick={() => setIsChecked(!isChecked)} >
          <input type="checkbox" placeholder='IC' className='border-1 shadow-sm   focus:outline-none   rounded-lg ' checked={isChecked} />
          <label htmlFor="" className='p-1 font-semibold text-xs left-2'>I have read and agree to the  <a className="underline" href = 'https://aye-ai.org/privacy-legal'  target='_blank' >terms and conditions</a> </label>
        </div>
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();  
            isChecked && handleSubmit();
          }}
          className='bg-green-800 mx-auto rounded-3xl py-3 px-9 mt-8 text-center text-white font-bold block'
          style={{ opacity: isChecked ? 1 : 0.5 }}
        >
          Continue
        </button>
      </form>

      <div className='text-center text-sm font-bold text-gray-600 mb-5 mt-6' >
        Authorised by {' '}
        <img src={PAYNET_LOGO2_PATH} alt="Paynet" className='inline h-[14px] align-middle' />
      </div>
    </div>
  );
}

