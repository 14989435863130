import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useApp } from '../../../context/usePortal';
import type { PaymentConsent } from '../../../core/api/http-methods/get-payment-methods';

type SetPreferedConfirmationProps = {
  isAlreadyPreferred?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  consentId: string;
  method: string;
  handlePaymentMethodsModified: () => void;
};
function SetPreferedConfirmation({
  isAlreadyPreferred,
  open,
  setOpen,
  consentId,
  method,
  handlePaymentMethodsModified,
}: SetPreferedConfirmationProps) {
  const { paymentMethods: ayePaymentMethods, setDefault } = useApp();
  const [paymentMethods, setPaymentMethods] = useState<
    readonly PaymentConsent[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetPreferred = async () => {
    // Put your delete logic here
    try {
      setLoading(true);
      const res = await setDefault(consentId);
      handlePaymentMethodsModified();
      setPaymentMethods(res?.response);
      console.log(res);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {isAlreadyPreferred ? 'Oops!' : 'Switch default payment method?'}{' '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {isAlreadyPreferred ? (
              'You cannot deactivate this as at least one payment method must be set as default'
            ) : (
              <>
                <strong>{method}</strong> will be set as your preferred payment
                method for all purchases
              </>
            )}{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {' '}
            {isAlreadyPreferred ? 'Close' : 'No'}
          </Button>
          {isAlreadyPreferred ? (
            <></>
          ) : (
            <Button
              disabled={loading}
              onClick={handleSetPreferred}
              color='primary'
              autoFocus
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default SetPreferedConfirmation;
