import React, { useEffect, useState } from 'react';

interface OtpInputFieldProps {
  length: number;
  onChangeOtp: (otp: string) => void;
  editable:boolean;
  resetOtp:boolean;
}

const OtpInputField: React.FC<OtpInputFieldProps> = ({ length, onChangeOtp,editable,resetOtp }) => {
  const [otp, setOtp] = useState<string[]>(new Array(length).fill(""));

  useEffect(()=>{
        setOtp(Array(length).fill(""));
      onChangeOtp('');
      const prevInput = document.getElementById(`otp-0`) as HTMLInputElement;
      prevInput?.focus();
      prevInput.blur();
  
  },[resetOtp]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = event.target.value;
    const numValue = Number(value);
    // Check if it's not a non-negative integer
    if (!Number.isInteger(numValue) || numValue < 0) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move focus to the next input
    if (index < length - 1 && value) {
      const nextInput = document.getElementById(`otp-${index + 1}`) as HTMLInputElement;
      nextInput?.focus();
    }

    onChangeOtp(newOtp.join(""));
  };

  // Handle backspace key press
  const handleBackspace = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = "";
    setOtp(newOtp);

    // Move focus to the previous input
    if (index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`) as HTMLInputElement;
      prevInput?.focus();
    }

    onChangeOtp(newOtp.join(""));
  };

  return (
    <div className='flex justify-center items-center  mb-8' >
      {otp.map((data, index) => (
        <input
          key={index}
          id={`otp-${index}`}
          readOnly= {editable}
          type="text"
          maxLength={1}
          value={data}
          className="w-[35px] h-[35px] border-1 mr-2 text-lg  rounded-xl text-center border-gray-600"
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              handleBackspace(e, index);
            }
          }}
        />
      ))}
    </div>
  );
};

export default OtpInputField;
