export const PAYMENTS_METHODS_DATA = [
    {
      id: 1,
      cardType: "Visa",
      ending: "4242",
      expiry: "12/20",
      status: "Active",
      lastUpdated: "22 Aug 2017",
    },
    {
      id: 2,
      cardType: "MasterCard",
      ending: "5678",
      expiry: "06/23",
      status: "Active",
      lastUpdated: "15 Jan 2020",
    },
    // Add more payments as needed
  ];

export const CURRENCY = "RM"