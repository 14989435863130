// @ts-check
import type { PaymentConsents } from '../types/payment';
import { api } from './calls';
import { handleAxiosError } from './utils';

const getPaymentMethod = async () =>
  await api.get<PaymentConsents | null>('/me/payments');

const updatePaymentMethod = async (
  id: string
): Promise<{ updated: boolean; message?: string } | null | void> => {
  try {
    if (!id) {
      throw Error("Payment method's consent id  is required. ");
    }
    const res = await api.put(`/me/payments/${id}`);
    if (res.status === 200) {
      return res.data;
    } else {
      return { updated: false, message: "Couldn't set default value" };
    }
  } catch (e) {
    handleAxiosError(e);
  }
};

const deletePaymentMutation = async (id?: string) => {
  try {
    if (!id) {
      throw Error("Payment method's consent id  is required. ");
    }
    const res = await api.delete(`/me/payments/${id}`);
    if (res.status === 200) {
      return res.data;
    } else {
      return { updated: false, message: "Couldn't set default value" };
    }
  } catch (e) {
    handleAxiosError(e);
  }
};

export { getPaymentMethod, updatePaymentMethod, deletePaymentMutation };
