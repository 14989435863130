import { Link } from 'react-router-dom'
import CampaignCard from '../../components/campaign/card'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '../../components/ui/carousel'
import MembershipCard from '../../components/membership/card'
import HeaderList from '../../components/reward/header-list'
import SectionHeader from '../../components/ui/section-header'
import { ayefaceCampaigns } from '../../dummy/campaignData'
import MyRewards from './components/my-aye-rewards'
import { useApiFetch } from '../../hooks/useApiFetch'
import { getMembershipTierById } from '../../api/tiers.api'

export default function AyeFaceRewardPage() {
  const { data, refetch } = useApiFetch(getMembershipTierById, {
    params: {
      membership_id: 's',
    },
  })
  return (
    <section className="flex flex-col gap-y-8">
      <SectionHeader />
      <Link
        to="/aye-face-reward/tiers"
        className="w-full px-2 md:max-w-[60%] xl:max-w-[40%]">
        <MembershipCard noImage={false} />
      </Link>
      <MyRewards />
      <div className="mx-auto flex w-full max-w-[calc(100%-2rem)] flex-col gap-y-4">
        <HeaderList
          title="Active Campaigns"
          href={'active-campaigns?type=active'}
          description={undefined}
        />
        <Carousel
          opts={{
            align: 'start',
          }}
          className="z-20 max-w-full bg-inherit">
          <CarouselContent className={'items-stretch'}>
            {ayefaceCampaigns.map((campaign, index) => (
              <CarouselItem
                key={index}
                className={`basis-[45%] md:basis-[65%] md:pl-6 lg:basis-1/5`}>
                {/* <div className="h-full"> */}

                <CampaignCard campaign={campaign} />
                {/* </div> */}
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
      {/*<div className="flex gap-y-4 mx-auto pb-4 w-full max-w-[calc(100%-2rem)] flex-col">*/}
      {/*  <HeaderList title="Locations" href={'/all-locations'}*/}
      {/*              description={`Find AyeFace's stores near you that accept AyeFace`} />*/}
      {/*  <Branches className={'px-2'} />*/}
      {/*</div>*/}
    </section>
  )
}
