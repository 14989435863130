import React, { createContext, useContext } from 'react';
import { usePortalHook } from '../hooks/usePortalHook';
import { GuardedReturn } from '../utils/useGuardedCallback';
import { GetOtpResponse } from '../core/api/http-methods/get-otp';
import { LoginPortalResponse } from '../core/api/http-methods/login';
import { GetUserResponse } from '../core/api/http-methods/get-user';
import { SaveUserResponse } from '../core/api/http-methods/save-user-info';
import {
  AddPaymentMethodRequest,
  AddPaymentMethodResponse,
} from '../core/api/http-methods/add-payment';
import { DeletePaymentMethodRequestResponse } from '../core/api/http-methods/delete-payment-method';
import { GetPaymentMethodsResponse } from '../core/api/http-methods/get-payment-methods';
import { SetDefaultResponse } from '../core/api/http-methods/set-default';
import { VerifyConsentResponse } from '../core/api/http-methods/verify-consent';
import { UpdatePINResponse } from '../core/api/http-methods/update-pin';
import { ResetPINResponse } from '../core/api/http-methods/reset-pin';

// create context
const AppContext = createContext<
  | {
      getCode: (phoneNumber: string) => Promise<GuardedReturn<GetOtpResponse>>;
      login: (
        phoneNumber: string,
        code: string
      ) => Promise<GuardedReturn<LoginPortalResponse>>;
      token: string | undefined;
      user: GetUserResponse | undefined;
      loading: boolean;
      logout: () => void;
      saveUser: (args_0: {
        name: string;
        email: string;
        sendNewsletter: boolean;
        sendMarketing: boolean;
      }) => Promise<GuardedReturn<SaveUserResponse | undefined>>;
      addPaymentMethod: (
        args_0: AddPaymentMethodRequest['body']
      ) => Promise<GuardedReturn<AddPaymentMethodResponse | undefined>>;
      deletePaymentMethod: (args_0: {
        consentId: string;
      }) => Promise<
        GuardedReturn<DeletePaymentMethodRequestResponse | undefined>
      >;
      transactions: () => Promise<
        | readonly {
            id: string;
            amount: string | null;
            ref: string | null;
            customerId: string | null;
            createdAt: Date;
            refundAmount: string;
            deviceId: string | null;
            storeName: string | null;
            paymentMethod: string | null;
            paid: boolean;
          }[]
        | undefined
      >;
      paymentMethods: () => Promise<
        GetPaymentMethodsResponse['payment_consents'] | undefined
      >;
      verifyConsent: (
        token: string
      ) => Promise<GuardedReturn<VerifyConsentResponse | undefined>>;
      setDefault: (
        consentId: string
      ) => Promise<GuardedReturn<SetDefaultResponse | undefined>>;
      updatePIN: (
        oldPin: string,
        newPin: string,
        transactionLimit: number
      ) => Promise<GuardedReturn<UpdatePINResponse | undefined>>;
      resetPIN: (
        newPin: string,
        otp: string,
        phoneNumber: string
      ) => Promise<GuardedReturn<ResetPINResponse | undefined>>;
    }
  | undefined
>(undefined);

const AyeProvider = ({ children }: any) => {
  // the value that will be given to the context

  const {
    getCode,
    login,
    token,
    user,
    loading,
    logout,
    saveUser,
    addPaymentMethod,
    deletePaymentMethod,
    transactions,
    paymentMethods,
    verifyConsent,
    setDefault,
    updatePIN,
    resetPIN,
  } = usePortalHook();

  return (
    // the Provider gives access to the context to its children
    <AppContext.Provider
      value={{
        getCode,
        login,
        token,
        user,
        loading,
        logout,
        saveUser,
        addPaymentMethod,
        deletePaymentMethod,
        transactions,
        paymentMethods,
        verifyConsent,
        setDefault,
        updatePIN,
        resetPIN,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// context consumer hook
const useApp = () => {
  // get the context
  const context = useContext(AppContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('useApp was used outside of its Provider');
  }

  return context;
};
export { useApp, AyeProvider };
