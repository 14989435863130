import chageeLogo from '../assets/logos/brand/chargee.png'
import mixueLogo from '../assets/logos/brand/mixue.png'
import zusCoffeeLogo from '../assets/logos/brand/zus.png'
import starbucksLogo from '../assets/logos/brand/starbucks.png'
import coffeeBeanLogo from '../assets/logos/brand/coffee-bean.png'
import mcdonaldsLogo from '../assets/logos/brand/mc-donald.png'
import johnnysLogo from '../assets/logos/brand/johnnys.png'
import dominosLogo from '../assets/logos/brand/dominos.png'
import tealiveLogo from '../assets/logos/brand/tealive.png'
import famousAmosLogo from '../assets/logos/brand/famous-amos.png'
import kenanganCoffeeLogo from '../assets/logos/brand/kenangan.png'
import domlogo from '../assets/logos/brand/dome.png'
import insideScoopLogo from '../assets/logos/brand/inside_scoop.png'
import redRoosterLogo from '../assets/logos/brand/red-rooster.png'

const myRewards = {
  "joinedRewards": [
    {
      "id": "jr-1",
      "brand": "CHAGEE",
      "logo": chageeLogo
    },
    {
      "id": "jr-2",
      "logo": mixueLogo,
      "brand": "MIXUE"
    },
    {
      "id": "jr-3",
      "logo": zusCoffeeLogo,
      "brand": "ZUS Coffee"
    },
    {
      "id": "jr-4",
      "brand": "Starbucks",
      "logo": starbucksLogo
    },
    {
      "id": "jr-5",
      "brand": "The Coffee Bean & Tea Leaf",
      "logo": coffeeBeanLogo
    },
    {
      "id": "jr-6",
      "brand": "McDonald's",
      "logo": mcdonaldsLogo
    },
    {
      "id": "jr-7",
      "brand": "Johnny's",
      "logo": johnnysLogo
    },
    {
      "id": "jr-8",
      "brand": "Domino's",
      "logo": dominosLogo
    },
    {
      "id": "jr-9",
      "brand": "Tealive",
      "logo": tealiveLogo
    },
    {
      "id": "jr-10",
      "brand": "Famous Amos",
      "logo": famousAmosLogo
    },
    {
      "id": "jr-11",
      "brand": "Kenangan Coffee",
      "logo": kenanganCoffeeLogo
    }
  ],
  "participatingBrands": [
    {
      "id": "pb-1",
      "brand": "CHAGEE",
      "logo": chageeLogo
    },
    {
      "id": "pb-2",
      "brand": "MIXUE",
      "logo": mixueLogo
    },
    {
      "id": "pb-3",
      "brand": "ZUS Coffee",
      "logo": zusCoffeeLogo
    },
    {
      "id": "pb-4",
      "brand": "Starbucks",
      "logo": starbucksLogo
    },
    {
      "id": "pb-5",
      "brand": "The Coffee Bean & Tea Leaf",
      "logo": coffeeBeanLogo
    },
    {
      "id": "pb-6",
      "brand": "McDonald's",
      "logo": mcdonaldsLogo
    },
    {
      "id": "pb-7",
      "brand": "Johnny's",
      "logo": johnnysLogo
    },
    {
      "id": "pb-8",
      "brand": "Domino's",
      "logo": dominosLogo
    },
    {
      "id": "pb-9",
      "brand": "Tealive",
      "logo": tealiveLogo
    },
    {
      "id": "pb-10",
      "brand": "Famous Amos",
      "logo": famousAmosLogo
    },
    {
      "id": "pb-11",
      "brand": "Kenangan Coffee",
      "logo": kenanganCoffeeLogo
    },
    {
      "id": "pb-12",
      "brand": "Nando's",
      "status": "New",
      "logo": dominosLogo
    },
    {
      "id": "pb-13",
      "brand": "Dome",
      "status": "New",
      "logo": domlogo
    },
    {
      "id": "pb-14",
      "brand": "Inside Scoop",
      "status": "New",
      "logo": insideScoopLogo
    },
    {
      "id": "pb-15",
      "brand": "Red Rooster",
      "status": "New",
      "logo": redRoosterLogo
    }
  ]
}
export {myRewards}