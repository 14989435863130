import type { ApiErrorResponse } from '../types/api'

export const handleAxiosError = (error: any | Error): ApiErrorResponse => {
  if (error.response) {
    // Server responded with error status
    return {
      status: error.response.status,
      message: error.response.data.message || 'Server error occurred',
      data: error.response.data,
    }
  } else if (error.request) {
    // Request was made but no response received
    return {
      status: 503,
      message: 'No response from server',
      data: null,
    }
  } else {
    if (error instanceof Error) {
      return {
        status: 400,
        message: error.message || 'Request configuration error',
        data: null,
      }
    }
    return {
      status: 400,
      message: 'Request configuration error',
      data: null,
    }
    // Error in request configuration
  }
}

/**
 * Constructs a query string from the given `PaginationQuery` object.
 *
 * This function takes an optional `PaginationQuery` object and converts its
 * properties into URL search parameters. It filters out properties that are
 * `undefined`, `null`, empty strings, or have keys 'refetching' or 'queryArg'.
 * The resulting query string is prefixed with a '?'.
 *
 * @param data - An optional `PaginationQuery` object containing query parameters.
 * @returns A query string starting with '?' followed by the encoded parameters.
 */
export function queryFunction(data: Record<string, unknown> | undefined) {
  const searchParams = new URLSearchParams()
  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        key !== 'refetching' &&
        key !== 'queryArg'
      ) {
        searchParams.append(key, value?.toString() ?? '')
      }
    })
  }
  return `?${searchParams.toString()}`
}
