import {myRewards} from '../../dummy/my-rewards'
import Brand from "./brand";

export default function ParticipatingBrands() {
  const participatingBrands = myRewards.participatingBrands
  return (
    <div className={'flex flex-col gap-y-4'}>
      <h3 className="text-lg font-normal mx-2">Participating Brands</h3>
      <div className="flex flex-wrap  w-full gap-y-2 items-stretch justify-start max-w-full">
        {participatingBrands.map((brand, index) => <Brand key={index} {...brand}
                                                          className={'basis-1/5'}/>)}
      </div>
    </div>
  )
}