import { Slider } from '@mui/material';
import React, { useState } from 'react';
import { useApp } from '../../../context/usePortal';

interface TransactionRangePopupProps {
    handleTransactionLimitChange: (value: number) => void;
    handleTransactionLimitStatusChange: (status: boolean) => void;
    transactionLimit:number
}

const TransactionRangePopup: React.FC<TransactionRangePopupProps> = ({ handleTransactionLimitChange, handleTransactionLimitStatusChange ,transactionLimit}) => {
    const { user } = useApp();
    const [val, setVal] = useState<number>(transactionLimit);
    const handleChange = (_: Event, newValue: number | number[]) => {
        setVal(newValue as number);
        handleTransactionLimitChange(newValue as number);
    };

    const handleConfirm = () => {
        handleTransactionLimitStatusChange(true);
    };

    return (
        <div className='p-1'>
            <h3 className='text-md font-bold py-2'>Transaction limit</h3>
            <p>Drag the slider to adjust your transaction limit</p>
            <h2 className='text-lg font-bold p-2 text-center'>RM {val}</h2>
            <Slider
                className='min-w-[200px]'
                size="medium"
                value={val}  // Use value instead of defaultValue for controlled slider
                aria-label="Cumulative limit"
                valueLabelDisplay="auto"
                max={250}
                min={0}
                step={50}
                onChange={handleChange}
            />
            <div className='flex justify-between items-center'>
                <span className='text-xs'>RM 0</span>
                <span className='text-xs'>RM 250</span>
            </div>
            <p className='text-xs mt-4'>
                Note: For purchases above the transaction limit, PIN will still be required</p>
            <button
                disabled={val === 0}
                type="button"
                onClick={handleConfirm}  // Add onClick handler to call handleConfirm
                className='bg-green-800 mx-auto w-full rounded-3xl py-3 px-9 mt-8 text-center text-white font-bold block'
                style={{ opacity: val === 0 ? 0.5 : 1 }}  // Adjust opacity as needed
            >
                Confirm
            </button>
        </div>
    );
}

export default TransactionRangePopup;
