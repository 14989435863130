

const CoinIcon = (props: import('react').ComponentProps<'svg'>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    fill="none"
    viewBox="0 0 21 21"
    { ...props }
  >
    <circle
      cx="10.5"
      cy="10.5"
      r="10.5"
      fill="url(#paint0_linear_279_5214)"
    ></circle>
    <circle
      cx="10.5"
      cy="10.5"
      r="8.313"
      fill="url(#paint1_linear_279_5214)"
    ></circle>
    <defs>
      <linearGradient
        id="paint0_linear_279_5214"
        x1="10.5"
        x2="10.5"
        y1="0"
        y2="21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE926"></stop>
        <stop offset="1" stopColor="#FFC226"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_279_5214"
        x1="10.5"
        x2="10.5"
        y1="2.188"
        y2="18.813"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA826"></stop>
        <stop offset="1" stopColor="#FFE926"></stop>
      </linearGradient>
    </defs>
  </svg>
)

export default CoinIcon