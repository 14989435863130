 export function getCurrentDateAndTime(): string {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Use 12-hour clock
    };

    // Create a new instance of Intl.DateTimeFormat with options
    const formatter = new Intl.DateTimeFormat('en-US', options);

    // Format the date using the formatter
    return formatter.format(now);
}

