import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import { ChevronDownIcon } from 'lucide-react';
import transactionData from '../dummy/transactions.json';
import type { ElementType } from 'react';

const disclosureConfig = {
  className: 'p-0 bg-gray-50',
  as: 'div' as ElementType,
  defaultOpen: false,
};

const AllPointsHistory = () => {
  return (
    <Disclosure {...disclosureConfig}>
      <DisclosureButton className='group  p-6 pr-3 py-3 flex w-full items-center justify-between'>
        <span className='text-sm/6 font-medium '>All</span>
        <ChevronDownIcon className='size-5 group-data-[open]:rotate-180' />
      </DisclosureButton>
      <DisclosurePanel className='mt-2 text-sm/5 '>
        {transactionData.data.map((transaction) => (
          <TransactionDetails
            key={transaction.transaction}
            {...transaction}
          />
        ))}
      </DisclosurePanel>
    </Disclosure>
  );
};

const ReceivedPointsHistory = () => {
  return (
    <Disclosure {...disclosureConfig}>
      <DisclosureButton className='group  p-6 pr-3 py-3 flex w-full items-center justify-between'>
        <span className='text-sm/6 font-medium '>Received</span>
        <ChevronDownIcon className='size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180' />
      </DisclosureButton>
      <DisclosurePanel className='mt-2 text-sm/5 text-white/50'>
        {transactionData.data
          .filter((t) => t.type === 'received')
          .map((transaction) => (
            <TransactionDetails
              key={transaction.transaction}
              {...transaction}
            />
          ))}
      </DisclosurePanel>
    </Disclosure>
  );
};

const UsedPointsHistory = () => {
  return (
    <Disclosure {...disclosureConfig}>
      <DisclosureButton className='group  p-6 pr-3 py-3 flex w-full items-center justify-between'>
        <span className='text-sm/6 font-medium  '>Used</span>
        <ChevronDownIcon className='size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180' />
      </DisclosureButton>
      <DisclosurePanel className='mt-2 text-sm/5 text-white/50'>
        {transactionData.data
          .filter((t) => t.type === 'used')
          .map((transaction) => (
            <TransactionDetails
              key={transaction.transaction}
              {...transaction}
            />
          ))}
      </DisclosurePanel>
    </Disclosure>
  );
};

interface Props {
  transaction: string;
  date: string;
  points: number;
  type: 'received' | 'used' | string;
  branch: string;
}

const TransactionDetails = ({
  transaction = '',
  date,
  points,
  type = 'received',
  branch,
}: Props) => {
  return (
    <div className='flex items-center p-6 pr-4 py-3 bg-white h-14'>
      <div className='flex-1 flex flex-col'>
        <p className={'text-gray-600 font-normal'}>{transaction}</p>
        <p className={'text-gray-400 text-sm flex items-center gap-x-2'}>
          <span>{date}</span>
          {/*Insert a bullet point here*/}
          {' - '}
          <span>{branch}</span>
        </p>
      </div>
      {points > 0 ? (
        <p className='text-md font-semibold text-green-500'>+ {points}</p>
      ) : (
        <p className='text-md  font-semibold'> {points}</p>
      )}
    </div>
  );
};

export { AllPointsHistory, ReceivedPointsHistory, UsedPointsHistory };
