import { useCallback, useRef } from 'react';

type CallbackFunction<T> = (...args: T[]) => void;

export function useDebounce<T>(
  callback: CallbackFunction<T>,
  delay: number
): CallbackFunction<T> {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const debouncedCallback = useCallback(
    (...args: T[]) => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay]
  );

  return debouncedCallback;
}
