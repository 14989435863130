import React from 'react';
import { FAILURE_LOGO_PATH } from "../../../constants/logo";


interface Props {
  onClose: () => void;
  tryAgain: ()=> void;
}

const FailedPopup: React.FC<Props> = ({ onClose,tryAgain }) => {
  return (
    <div className="failed">
     <img src={FAILURE_LOGO_PATH}  className='h-32  mx-auto' alt="" />

      <h5 className="text-xl pb-0 text-center text-red-500 my-2 font-semibold">Failed</h5>
      <p className="text-center px-10 text-wrap font-semibold">
        Sorry, the payment wasn't successfully linked. Please try again.
      </p>

      <div className="text-center">
        <button
          className="my-5 mx-1 rounded-full px-5 py-2 text-base font-medium text-green-800 shadow-sm border-solid border-green-800 border-2 focus:outline-none focus:ring-offset-2"
          onClick={onClose}
        >
          Maybe Later
        </button>

        <button
          className="my-5 mx-1 bg-green-800 rounded-full px-10 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2"
          onClick={tryAgain}
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default FailedPopup;
