import React, { useState } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
interface PhoneNumberInputProps {
  setPhoneNumber: (value:string)=>void;
}

export function PhoneNumberInput({ setPhoneNumber }: PhoneNumberInputProps) {
  const [phone, setPhone] = useState("");

  const handlePhoneChange = (value: string, country: CountryData) => {
    setPhone(value)
    // Ensure the value is trimmed and remove non-digit characters
    const formattedValue = value.trim().replace(/[^\d]/g, "");
    
    // Extract the country code and phone number parts
    const countryCode = `${country.dialCode}`;
    const phoneNumber = formattedValue.substring(country.dialCode.length);

    // Format the phone number as desired (e.g., +60 - 9435490864354)
    const formattedPhoneNumber = `${countryCode}-${phoneNumber}`;

    // Update state and pass formatted phone number to parent component
    setPhoneNumber(formattedPhoneNumber);
  };

  return (
    <>
      <label htmlFor="" className='relative p-1 text-sm font-bold top-3 z-10  bg-white left-2'>Phone Number  <span className="text-red-500" >*</span> </label>
      <PhoneInput
        inputProps={{
          className: "p-3 ps-12 border border-gray-300 focus:outline-none focus:ring focus:ring-gray-400 focus:ring-1 rounded-lg w-full"
        }}
        country={"my"} // Default country
        enableSearch={true} // Enable country search
        value={phone} // Controlled input value
        onChange={handlePhoneChange} // Handle input change
        countryCodeEditable={false}
      />
    </>
  );
}
