import image1 from '../assets/campaign/chagee-c-1.png'
import image2 from '../assets/campaign/chagee-c-2.png'
import campaignImage1 from '../assets/campaign/ayeface-c-1.png'
import campaignImage2 from '../assets/campaign/ayeface-c-2.png'
import campaignImage3 from '../assets/campaign/ayeface-c-3.png'
// import image3 from '../assets/campaign/chagee-c-3.png'
export const campaignData = [
  {
    campaignId: 'CAMP001',
    brandId: 'BRAND001',
    brandName: 'CHAGEE',

    title:
      'Reach the Gold Tier by this month and unlock a 15% discount on all purchases',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Till End of Month',
    expiryDate: '2024-06-30',
    description:
      'Unlock a 15% discount when you reach the Gold Tier this month. This exclusive offer is valid for all purchases made during the campaign period. Enjoy a 15% discount on all items in-store and online. Offer valid for unlimited use. Discount will be applied at checkout.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP002',
    brandId: 'BRAND001',
    brandName: 'CHAGEE',
    title: 'Double the Deals: Enjoy a 50% Discount on Your 2nd Cup!',
    isActive: true,
    useLimit: 'One use per transaction',
    offerValidity: 'July 20–31, 2024',
    expiryDate: '2024-07-31',
    description:
      'Enjoy a 50% discount on your 2nd cup during the campaign period. This offer is valid for all beverages, including hot and cold drinks. Discount will be applied to the item of equal or lesser value. Offer valid for one use per transaction.',
    campaignImageUrl: image2,
  },
  {
    campaignId: 'CAMP003',
    brandId: 'BRAND001',
    brandName: 'CHAGEE',
    title: 'Purchase any 3 items and get a free Gift Item',
    isActive: true,
    useLimit: '1 gift per transaction',
    offerValidity: 'Ongoing',
    expiryDate: '2024-08-31',
    description:
      'Get a free gift item when you purchase any 3 items (limit: 1 gift per transaction). Offer valid on all items in-store and online. Gift item will be added to your cart automatically. No promo code required.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP004',
    brandId: 'BRAND001',
    brandName: 'CHAGEE',
    title:
      'Reach the Gold Tier by this month and unlock a 15% discount on all purchases',
    isActive: false,
    useLimit: 'Unlimited',
    offerValidity: 'Expired',
    expiryDate: '2024-05-31',
    description:
      'Unlock a 15% discount when you reach the Gold Tier this month. This exclusive offer is valid for all purchases made during the campaign period. Enjoy a 15% discount on all items in-store and online. Offer valid for unlimited use. Discount will be applied at checkout.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP005',
    brandId: 'BRAND002',
    brandName: 'Café Aroma',
    title: 'Free Coffee with Every Sandwich Combo',
    isActive: true,
    useLimit: 'Once per user',
    offerValidity: 'Valid until August 10, 2024',
    expiryDate: '2024-08-10',
    description:
      'Get a free coffee with every sandwich combo purchased. Offer valid for one use per user. Discount will be applied at checkout. No promo code required.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP006',
    brandId: 'BRAND002',
    brandName: 'Café Aroma',
    title: '10% Off Your First Order',
    isActive: true,
    useLimit: 'First-time users only',
    offerValidity: 'Ongoing',
    expiryDate: '2024-09-01',
    description:
      'First-time customers enjoy 10% off their first order. Discount will be applied at checkout. No promo code required.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP007',
    brandId: 'BRAND003',
    brandName: 'Snacko Delight',
    title: 'Buy 2 Get 1 Free on Select Snacks',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Till July 15, 2024',
    expiryDate: '2024-07-15',
    description:
      'Buy any 2 snacks and get 1 free.  Offer valid on select snack categories. Discount will be applied to the item of equal or lesser value. Offer valid for unlimited use.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP008',
    brandId: 'BRAND003',
    brandName: 'Snacko Delight',
    title: 'Flat 20% Off on Orders Above $20',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'July 1–July 31, 2024',
    expiryDate: '2024-07-31',
    description:
      'Enjoy a flat 20% discount on all orders above $20 during the campaign period. Discount will be applied at checkout. No promo code required.',
    campaignImageUrl: image2,
  },
  {
    campaignId: 'CAMP009',
    brandId: 'BRAND004',
    brandName: 'HealthyLife',
    title: 'Free Wellness Consultation with Orders Above $50',
    isActive: true,
    useLimit: 'Once per customer',
    offerValidity: 'Valid until August 20, 2024',
    expiryDate: '2024-08-20',
    description:
      'Spend $50 or more and get a free 30-minute wellness consultation. Offer valid for one use per customer. Consultation will be scheduled after order completion.',
    campaignImageUrl: image1,
  },
  {
    campaignId: 'CAMP010',
    brandId: 'BRAND004',
    brandName: 'HealthyLife',
    title: '25% Off All Organic Products This Week',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'June 24–June 30, 2024',
    expiryDate: '2024-06-30',
    description:
      'Get 25% off all organic products this week only.  Discount will be applied at checkout. No promo code required.',
    campaignImageUrl: image2,
  },
]

export const ayefaceCampaigns = [
  {
    campaignId: 'CAMP001',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'Spend to Earn: Crypto Rewards with AyeFace',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      "Earn crypto rewards with every transaction through AyeFace's unique spend-to-earn program.",
    campaignImageUrl: campaignImage1,
  },
  {
    campaignId: 'CAMP002',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'What are round-ups?',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      'Round Up, Save More: Smarter Spending with AyeFace by rounding up your purchases.',
    campaignImageUrl: campaignImage2,
  },
  {
    campaignId: 'CAMP003',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'Cashback Rewards',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      "Cashback Rewards Every Time You Spend with AyeFace's loyalty program.",
    campaignImageUrl: campaignImage3,
  },
  {
    campaignId: 'CAMP004',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'Exclusive Discounts',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      'Unlock exclusive discounts with partner merchants when using AyeFace.',
    campaignImageUrl: campaignImage2,
  },
  {
    campaignId: 'CAMP005',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'Save for Goals',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      'Set financial goals and let AyeFace help you achieve them with automated savings.',
    campaignImageUrl: campaignImage1,
  },
  {
    campaignId: 'CAMP006',
    brandId: 'BRAND001',
    brandName: 'AyeFace',
    title: 'Track Your Spending',
    isActive: true,
    useLimit: 'Unlimited',
    offerValidity: 'Ongoing',
    expiryDate: '2024-12-31',
    description:
      'Detailed analytics and insights on your spending habits with AyeFace.',
    campaignImageUrl: campaignImage3,
  },
]