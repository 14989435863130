// @ts-check
import type { UserI } from '../types/user';
import { api } from './calls';
import { handleAxiosError } from './utils';

/**
 * Fetches the current user's information
 * @returns {Promise<{user:import('../types/user').UserI}>} User information
 * @throws {import('../types/api.ts').ApiErrorResponse} When API request fails
 * @throws {Error} When response data is invalid
 */
const getUser = async () => {
  try {
    const res = await api.get('/me', {
      timeout: 5000, // 5 second timeout
    });

    // Basic validation of response data
    if (!res.data || typeof res.data !== 'object') {
      throw new Error('Invalid response data');
    }

    return res.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

/**
 * @param {import('../types/user.ts').UserI} user
 * @returns {Promise<{user:import('../types/user').UserI}>}
 * @throws {import('../types/api.ts').ApiErrorResponse} When API request fails
 * @throws {Error} When response data is invalid
 */
const updateUser = async (user: UserI): Promise<{ user: UserI } | null> => {
  try {
    const res = await api.put('/me', user);

    if (!res.data || typeof res.data !== 'object') {
      throw new Error('Invalid response data');
    }

    return res.data;
  } catch (e) {
    throw handleAxiosError(e);
  }
};

export { getUser, updateUser };
