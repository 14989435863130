import type React from 'react';
import { cn } from '../../lib/utils';
import { Link } from 'react-router-dom';

interface Props {
  brand: string;
  logo: string;
  id: string;
  className?: string;
}

const Brand: React.FC<Props> = ({ brand: brandName, logo, id, className }) => {
  return (
    <Link
      to={`/my-rewards-hub/${id}`}
      className={cn(
        'flex max-w-[calc(100%/5)] md:max-w-[calc(100%/4)] items-center flex-col md:flex-row md:gap-x-3 gap-y-1',
        className
      )}
    >
      <div className='max-w-[100%/2]'></div>
      <div className='size-12 md:size-20 shadow-lg rounded-2xl bg-white p-2 '>
        <img
          src={logo}
          alt={brandName}
          className='size-full
         object-contain'
        />
      </div>
      <p className='text-xs md:text-base md:font-normal font-normal line-clamp-1 max-w-[calc(100%-5%)] mx-auto md:m-0 '>
        {brandName}
      </p>
    </Link>
  );
};
export default Brand;
