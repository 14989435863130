import { PAYNET_LOGO_PATH } from "../constants/logo";

export default function Footer() {
  return(
    <footer className="py-4 text-center text-sm text-gray-500"> 
      <div className="mt-2 mb-1 font-bold">
        Supported by{" "}
        <img
          src={PAYNET_LOGO_PATH}
          alt="Paynet"
          className="inline-block h-10 align-middle"
        />
      </div>
      © {new Date().getFullYear()} Aye Solutions. All rights reserved.
      <div className="flex justify-center items-center"></div>
    </footer>
  );
};
