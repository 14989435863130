import { Link } from 'react-router-dom';

interface Props {
  title?: string;
  href?: string;
  description?: string;
}

const HeaderList: React.FC<Props> = ({
  title = 'My 🧡 Rewards',
  href = '/aye-face-reward/my-rewards',
  description,
}) => {
  return (
    <div className='flex flex-col p-1 gap-y-0'>
      <div className='flex items-center'>
        <h2 className='text-lg flex-1 flex flex-nowrap items-center font-semibold text-gray-900'>
          {title}
        </h2>
        <Link
          to={href}
          className='!text-gray-500 items-center gap-x-1 text-nowrap flex flex-nowrap text-sm/6 hover:text-gray-400'
        >
          See all
          <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M5.25 10.5L8.75 7L5.25 3.5'
              stroke='currentColor'
              strokeOpacity='0.7'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </Link>
      </div>
      {description && (
        <p className='text-gray-400 font-base text-xs'>{description}</p>
      )}
    </div>
  );
};
export default HeaderList;
