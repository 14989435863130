import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { RewardCard } from '../../../components/reward/card';
import SectionHeader from '../../../components/ui/section-header';

const categories = [
  {
    name: 'Active Rewards',
    href: '/tiers',
  },
  {
    name: 'Past Rewards',
    href: '/tiers/history',
  },
];
export default function MyAyeFaceRewards() {
  // const navigate = useNavigate()
  return (
    <section className='flex flex-col  gap-y-8'>
      <div className={'flex flex-col gap-y-4'}>
        <SectionHeader
          title={
            <>
              <h1 className='text-lg font-medium leading-6 text-gray-900'>
                My 🧡 Rewards
              </h1>
            </>
          }
        />

        <TabGroup
          className={'flex flex-col gap-y-2'}
          //   onChange={(index) => {
          //   const tab = categories[index]
          //   // navigate(tab.href)
          // }}
        >
          <TabList className='flex mx-4 gap-x-4'>
            {categories.map(({ name }) => (
              <Tab
                key={name}
                className='rounded-none py-1 px-2 text-sm/6 font-semibold  focus:outline-none  data-[selected]:text-primary data-[selected]:border-b-2 data-[selected]:border-primary'
              >
                {name}
              </Tab>
            ))}
          </TabList>
          <TabPanels className={' flex flex-col'}>
            <TabPanel
              as={'div'}
              className='flex flex-wrap px-2'
            >
              <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
                <RewardCard type='cashback' />
              </div>
              <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
                <RewardCard type='discount' />
              </div>
              <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
                <RewardCard type='cashback' />
              </div>
            </TabPanel>
            <TabPanel
              as={'div'}
              className='flex flex-wrap px-2'
            >
              <div className='basis-full py-2 sm:basis-1/2 lg:basis-1/3 sm:pr-2'>
                <RewardCard type='cashback' />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </section>
  );
}
