import React from 'react'
import { FAILURE_LOGO_PATH } from '../../../constants/logo'
import { RenameInfoFailure } from 'typescript'
import { getCurrentDateAndTime } from '../../../utils/getCurrentDateAndTime'
interface FailurePopupParams {
  hasDisablingLimitFailed: boolean,
  onClose: () => void
}
const FailurePopup: React.FC<FailurePopupParams> = ({ hasDisablingLimitFailed, onClose }) => {
  return (
    <div>
      <img src={FAILURE_LOGO_PATH} className='h-32  mx-auto' alt="" />
      {
        hasDisablingLimitFailed ? (
          <h2 className=' text-sm  md:text-lg text-center font-bold mt-2 mb-5 ' >Failed to disable transactions without a PIN due to an incorrect PIN. Please try again</h2>
        ) : (
          <h2 className=' text-sm  md:text-lg text-center font-bold mt-2 mb-5 ' >Failed to enable transactions without a PIN due to an incorrect PIN. Please try again</h2>
        )
      }

      <div className='flex justify-between items-center' >
        <span className=' text-sm md:text-md' >Status</span>
        <span className='font-bold text-sm md:text-md' >Failed</span>
      </div>
      <div className='flex justify-between items-center' >
        <span className=' text-sm md:text-md' >Date & Time</span>
        <span className='font-bold text-sm md:text-md' >{getCurrentDateAndTime()}</span>
      </div>

      <button
        type="button"
        className=' bg-green-800 text-white capitalize mx-auto rounded-3xl py-3 px-9 mt-10 text-center font-bold block border-green-800 border-1 w-full'
        style={{ opacity: true ? 1 : 0.5 }} // Adjust opacity condition as needed
        onClick={onClose}
      >
        Done
      </button>
    </div>
  )
}

export default FailurePopup
