import { createBrowserRouter, Outlet, redirect } from 'react-router-dom'
import Layout from './shared/Layout'
import Home from './pages/home/home'
import Login from './pages/login/login'
import Settings from './pages/settings/settings'
import ProtectedRoute from './components/proteted-route'
import CampaignDetails from './components/campaign/details'
import SectionHeader from './components/ui/section-header'
import { ayefaceCampaigns } from './dummy/campaignData'
import AyeFaceRewardPage from './pages/aye-face-rewards'
import MyAyeFaceRewards from './pages/aye-face-rewards/my-rewards'
import ActiveCampaigns from './pages/my-rewards-hub/campaigns'
import TiersBenefits from './pages/tiers'
import TiersHistory from './pages/tiers/tiers-history'
import TiersLayout from './pages/tiers/tiers-layout'
import AllLocations from './components/all-locations'
import MyRewardsHub from './pages/my-rewards-hub'
import RewardTierDetails from './pages/my-rewards-hub/reward-tier-details'
import MyBrandRewards from './pages/my-rewards-hub/rewards'

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '/:token?',
          children: [
            {
              index: true,
              element: <Home />,
            },
          ],
        },
        {
          path: '/all-locations',
          element: <AllLocations />,
        },
        {
          path: '/my-rewards-hub',
          element: <MyRewardsHub />,
        },
        {
          path: '/my-rewards-hub/:id',
          children: [
            {
              index: true,
              element: <RewardTierDetails />,
            },
            {
              path: 'rewards',
              element: <MyBrandRewards />,
            },
            {
              path: 'active-campaigns',
              element: (
                <>
                  <div className="flex flex-col gap-y-4">
                    <SectionHeader
                      // href={'/'}
                      title={
                        <>
                          <h1 className="text-lg font-bold leading-6 text-gray-900">
                            Active Campaigns
                          </h1>
                        </>
                      }
                    />
                    <Outlet />
                  </div>
                </>
              ),
              children: [
                { index: true, element: <ActiveCampaigns /> },
                {
                  path: ':campaignId',
                  element: <CampaignDetails />,
                },
              ],
            },
          ],
        },
        {
          path: '/my-rewards-hub/:id/tiers/*',
          async loader({ params, request, ...data }) {
            const paths = ['benefits', 'history']
            const currentPath =
              new URL(request.url).pathname.split('/').pop() ?? 'benefits'
            console.log(currentPath, params)
            if (paths.indexOf(currentPath) === -1) {
              return redirect(`/my-rewards-hub/${params.id}/tiers/benefits`)
            }
            console.log(
              'Current path: ',
              currentPath,
              data,
              'Path Index',
              paths.indexOf(currentPath),
            )
            // return path index
            return paths.indexOf(currentPath)
          },
          element: <TiersLayout />,
          children: [
            { path: 'benefits', element: <TiersBenefits /> },
            { path: 'history', element: <TiersHistory /> },
          ],
        },
        {
          path: 'aye-face-reward',
          children: [
            {
              index: true,
              element: <AyeFaceRewardPage />,
            },
            {
              path: 'active-campaigns',
              element: (
                <>
                  <div className="flex flex-col gap-y-4">
                    <SectionHeader
                      // href={'/'}
                      title={
                        <>
                          <h1 className="text-lg font-bold leading-6 text-gray-900">
                            Active Campaigns
                          </h1>
                        </>
                      }
                    />
                    <Outlet />
                  </div>
                </>
              ),
              children: [
                {
                  index: true,
                  element: <ActiveCampaigns campaignData={ayefaceCampaigns} />,
                },
                {
                  path: ':campaignId',
                  element: <CampaignDetails campaignData={ayefaceCampaigns} />,
                },
              ],
            },
            {
              path: 'tiers/*',
              async loader({ params, request, ...data }) {
                const paths = ['benefits', 'history']
                const currentPath =
                  new URL(request.url).pathname.split('/').pop() ?? 'benefits'
                console.log(currentPath)
                if (paths.indexOf(currentPath) === -1) {
                  return redirect('/aye-face-reward/tiers/benefits')
                }
                console.log(
                  'Current path: ',
                  currentPath,
                  data,
                  'Path Index',
                  paths.indexOf(currentPath),
                )
                // return path index
                return paths.indexOf(currentPath)
              },
              element: <TiersLayout />,
              children: [
                { path: 'benefits', element: <TiersBenefits /> },
                { path: 'history', element: <TiersHistory /> },
              ],
            },
            {
              path: 'my-rewards',
              element: <MyAyeFaceRewards />,
            },
            {
              path: 'active-campaigns',
              children: [
                { index: true, element: <ActiveCampaigns /> },
                {
                  path: ':campaignId',
                  element: <CampaignDetails />,
                },
              ],
            },
          ],
        },
        {
          path: '/tiers/*',
          async loader({ params, request, ...data }) {
            const paths = ['benefits', 'history']
            const currentPath =
              new URL(request.url).pathname.split('/').pop() ?? 'benefits'
            console.log(currentPath)
            if (currentPath && paths.indexOf(currentPath) === -1) {
              return redirect('/tiers/benefits')
            }

            // return path index
            return paths.indexOf(currentPath)
          },
          element: <TiersLayout />,
          children: [
            { path: 'benefits', element: <TiersBenefits /> },
            { path: 'history', element: <TiersHistory /> },
          ],
        },
        {
          path: '/settings',
          element: <Settings />,
        },
        // {
        //   path: '/old-home',
        //   element: <OldHome />,
        // },
      ],
    },
    {
      path: '/login',
      element: <Login />,
    },
  ],
  {
    basename: '/',
  },
)
