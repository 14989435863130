import React, { useState } from 'react';

const Spinner: React.FC = () => {   
    return (

        <div className='submiting'>
            <div className='spinner   mx-auto mt-10  '>
            </div>

            <h5 className='text-xl p-10 pb-0 text-center text-green-800 my-2 font-semibold' >Redirecting  </h5>
            <p className='text-center  text-[15px] text-wrap font-semibold' >please do not <span className='text-red-500' >close</span  > or <span className='text-red-500' >refresh</span> the window, we are redirecting you to link the payments selected</p>
        </div>

    );
};

export default Spinner;
