export const RewardCard = ({ title = 'Discount 10%', type = 'cashback' }) => {
  return (
    <div className="inner-curve bg-primary/10 relative overflow-hidden rounded-lg shadow-sm drop-shadow-sm">
      <div className="flex items-center gap-4 p-4 py-2">
        <div className="flex size-12 shrink-0 items-center justify-center rounded-full">
          {type !== 'cashback' ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink">
              <rect width="24" height="24" fill="url(#pattern0_279_5932)" />
              <defs>
                <pattern
                  id="pattern0_279_5932"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1">
                  <use
                    xlinkHref="#image0_279_5932"
                    transform="scale(0.0208333)"
                  />
                </pattern>
                <image
                  id="image0_279_5932"
                  width="48"
                  height="48"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAThSURBVHgB7VlNctNIFH4t25nU1BSVzcyY1YgbODdITkDYTU0BMTUHIJwg4gQkB6CwgaLYEU5AbpDcIGKFgQVZsAjE1uP1a/10S225W1Jgk68qsSw9d7+n9/8a4BrXaAUBLYH/DkPoQ8hfvl6ciqPz81r6nY0N+H0QQtDbgDnE4vUshhZoLAD+9+cIer0ntMKW+QAm9DcVL2fHxu27wy2i3QeBI9p2Q6M/hkt40FSQRgIw8/3gncFIFTEgHqldxA79D5eTinOYz7fFq8+n4IlmAtwfnpkMYbqxGDmucApITJvai8Xz2S3wRACewHvDMRTMx7TCLfH846b8k9d0b0pUFj+Q9/AQ5gumFS9m24o+pw3Ttb3grQG8//dJ/qaRbPfFbGKlu/vXDgSBokvIzvvk4JOqg5M2I/rYT9c7ZsE84CWAsv3eSX5Dvv1JuyiCY4pKyW9nuT8lsF0OAHXwMyEZdTIImLRlnpdhrQRTbd19n987CSBDINnnO8PpBDyGrhDggbYu78Vh1wG1JpQ61W4l1gM8pogRQYcwfCGHoGiFh8v8jCmsiylbfwP22N058/m+ViEYMUWvO7Y8URGAS4M1PDGTFIe6KQTioAu7rwOOaf8EIpCaN2BPdlUBDFuXjItDCC4ObCHwKsGCLGArdepQ3ayGWUMA9fbhLL/RQZhsi1QjBU8DEYqnH95nX80o1NdsXkr7i5mXUDxgYTbf0Cg3TAGSRWEmAusKtQrIAfcpS3/Bezef1NMMUX5CRzAFWLuMi9pEjDgauSNixxe4h//f/Kf8MGU6KmjdwGatF4lUkujPDQHYUVEUBP1gDK5IIMumsW6jzITJvIR7EhxoYZWzvxlMqpkY9cWDXa5VHED1yxi+U3UZXGway1mY98ojAnfy6wVMK49tvzHqfc/iqrROK+bTivZN+tXaLyyphcRbjWILGqD1m+e9A80H8a2VBK4AnTDviCUC4O38Mkm8+tROmZeNUA5x20ZSESAtY8P0R+fi5acjcMQq5uVIRYVFR3DI1FpOS4ld1YDQiiiBnTHPuLF+IksVqreegQNcmh1DAA6ZAsbaD3yalki7rjDPNU2mWX2PVQjmk/xaUHIthXVTA/N13etPPWuhTFirzau1qLJVJuH8YsREls9ZLSTLG5rqaehDR0iZjuppPuzRxx74Qs2QFOa9Gg30qfMptht5OdwVgXnQW1qDx5UNjRwPkrovqbR+/XNLax4C31h/SFd7eXe4qqFhmmVzTzm0vST7vmJB+I0P8KFy9FJbG4jNsl82aeojsvfuRir6vtVQnIFGmNTUTxyaemNBOVYR9DaqQ9vOhbAyj5yJp95jlcritrMAKp27MqdKLy4ZJ99zqYKdwmg6ytg2HHwNm4VEG/SmxXPA61eNNmx2apdUYXKc3+gtHoEHvBKZVClp4VhpgbJisi41EJXpOAT+QVk96yWool1aFA70fkNmf79TGv/zAXZsSIsxypDfcTPzBeUrwW41BPJT2W8f6aE4tX0K2fng6kGdw3YiAO9TPmKSTickw+g4xcgGBwZ9oyOmZrUQDVqNZKdMqkwVF20gNyNh8agsqExSyR1ogObHrGp2+cwcvaMay1hCIDcjATurObSV2iPH9bX9DO0PuqUgc3q7cqpHg7FVQ2COXFnZ3sFB9zWu0RI/AItzQYDby3n7AAAAAElFTkSuQmCC"
                />
              </defs>
            </svg>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink">
              <rect width="23" height="22" fill="url(#pattern0_279_5945)" />
              <defs>
                <pattern
                  id="pattern0_279_5945"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1">
                  <use
                    xlinkHref="#image0_279_5945"
                    transform="matrix(0.0142857 0 0 0.0149351 0 -0.000324675)"
                  />
                </pattern>
                <image
                  id="image0_279_5945"
                  width="70"
                  height="67"
                  xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABDCAIAAACugeRgAAAEaUlEQVRoBe2aPW7qQBCAcwwuQC7ABcIFuEAQrUVHGqLQQklDJF4JLUVeEUpokZKG0rQuaJIOUXvWT4PZ8T6bHXv9Q0xkyYoWm+zOt/O7Y+7c4+GXXXe/jMc9HiqkW7DSSkuVln4k9lSGl5PhwdceNn/FrC/Gj+KpgdegKcaPsJ6DY2fU7ZW0hAzbFSwnPoPXqTGXmHazgBWFFDBMu+KpwQBcftRrwO4jnbpyQ0KG3QfqIRkDGtu0C8sJbN5QgScdej0F3rpPR5UJCRnWc3SJQdOz6pf3W9oYMozbyLBduV97nQZg+RpMZd2nsEBjJNiukGHYChaWQoeRrLoYt8ViBJs3hiHKBrtPmlwMW9Ev8HcMkOB9QiuFpfeprLoYtsSsj7aULXDB5o2WQK2aRNpESODY3kuT1ggGxJBH8A3JjYZw2imY90OP+I/xSGgGqteSQgZNjATFXRLJGzzwDKGnMUionyiPznkKuy+GLVjPQ6LrPsYglYGH7DxhCuaQYD2n6dSBGDxgDFhOsH4p7lpOTunhQV3a6zViAw+HFFURGoBh/NGZh8F9xxbTbgDWa7jf2rTGHdRh9xHMcnISsRgZyGESdpNMKxYjkodPVlotYRZS3P1neXxmLCykSIyxaJHEn0DXYtpNspFX+I4Yt30qRlF6JEoLnZqbrRTIE9WxYxUVj8TsR56yJva9oKp4f70oQDySaT1ycZkcb5KT69whAVLitJ2j3MxUlC115lMhJbZ4ZpszProxLfnHdViMgisSbG8GCQW9VPKL8WNIq7eBBEpap7RzTqmRLJ8BSeZp7BwU6UIwfw4w6Jjs295yEm1apEeC3Sc2Esy7GUb8ao9BjNtRgOhs6ZGicxVxh0qB5HtXbqTvPZmcWkaCYwPT6JMHU90uaFNtEToJzQnb1RlJaZiAPBeBpoQrtZYISd1vaqBHw7e/I+VGooOzdU8KPDeQrTps/tJNdVBqJFfxJeaUqvK4x0O5kY6HIOINmnyThMDKjkTuhHEiQUMrk5bg1GrShR3as+wDtfXjdWr4hvOU4nV/qRRUg4oqhjaIU+us6ILIPR5CVJSs+IE5kmyn6JrR+NrPsY0udS/DY8cmv+JJ6GnOSNj+j3vtR2vTQCdEgOfY+G6KbUqT+ehm0xseqyUKOyRuwkHCsBZARg4BtHTOSO73Xgz+b8DLPijDlkvLNgOSPJbpfInZyEIfpUeik1np+niyrtUVgVpfos0wfa9YqIow4pOTa0p1LVK6AqxoHoy00mnVI5a6rh5J2Q9dbFEnus6YVMSIxCGpBZiYPV9HaGYVOh16nRpTuXNIaLhK++ZnqShcYR3I/hIiBkl1RzTiXuP6MR2NRf0hyUuTbyTFI2FWlUHm7Jqhbpva781v7P8AMVx2xfFwr59DNp2uWKbolMuAtzcSOIGWqMryX9abV6tZeay6mPV1IZtIaGCCJNkuvF/Iz96CdxaLUeyP9whDHaRBUv+/hOMKSdpeCZVDIlVaqrRExnDNwT/oOBpDc3PhbAAAAABJRU5ErkJggg=="
                />
              </defs>
            </svg>
          )}
        </div>
        <div className="flex flex-col space-y-1">
          <h3 className="text-md mb-1 font-semibold">{title}</h3>
          <div className="flex flex-col gap-y-1">
            <p className="text-sm font-medium">Max Capped at RM10.00</p>
            <p className="text-xs text-gray-400">Valid Until: 20/07/2024</p>
          </div>
        </div>
        <div
          className="absolute right-0 top-0 h-full w-[1px] bg-white/20"
          style={{
            WebkitMask:
              'linear-gradient(to bottom, transparent 50%, black 50%)',
            WebkitMaskSize: '1px 8px',
            mask: 'linear-gradient(to bottom, transparent 50%, black 50%)',
            maskSize: '1px 8px',
          }}
        />

        {/* Clip path for rounded corners */}
        <div
          className="pointer-events-none absolute inset-0"
          style={{
            clipPath: 'inset(0 0 0 0 round 8px)',
          }}
        />
      </div>
    </div>
  )
}
