import React, { createContext, useReducer, useContext } from 'react'

type CacheState = {
  loading: Record<string, boolean>
  cache: Record<string, any>
  error: Record<string, any>
}

type Action =
  | { type: 'SET_DATA'; key: string; data: any }
  | { type: 'SET_ERROR'; key: string; error: any }
  | { type: 'SET_LOADING'; key: string; loading: boolean }

const initialState: CacheState = {
  loading: {},
  cache: {},
  error: {},
}

function apiCacheReducer(state: CacheState, action: Action): CacheState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: { ...state.loading, [action.key]: action.loading },
      }
    case 'SET_DATA':
      return {
        ...state,
        loading: { ...state.loading, [action.key]: false },
        cache: { ...state.cache, [action.key]: action.data },
      }
    case 'SET_ERROR':
      return {
        ...state,
        loading: { ...state.loading, [action.key]: false },
        error: { ...state.error, [action.key]: action.error },
      }
    default:
      return state
  }
}

type ApiCacheContextType = {
  state: CacheState
  dispatch: React.Dispatch<Action>
}

const ApiCacheContext = createContext<ApiCacheContextType | undefined>(
  undefined,
)

export const ApiCacheProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(apiCacheReducer, initialState)

  return (
    <ApiCacheContext.Provider value={{ state, dispatch }}>
      {children}
    </ApiCacheContext.Provider>
  )
}

export const useApiCache = (): ApiCacheContextType => {
  const context = useContext(ApiCacheContext)
  if (!context)
    throw new Error('useApiCache must be used within ApiCacheProvider')
  return context
}
