import { LOGO_PATH } from "../constants/logo";


export function Loader() {
  return (
    <div className="relative flex justify-center items-center">
      <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-orange-400"></div>
      <img
        src={LOGO_PATH}
        alt="Aye"
        className="rounded-full h-12 w-22"
      />
    </div>
  );
}

export const SkeletonLoader = () => {
  return (
    <div className="flex animate-pulse space-x-4">
      <div className="flex-1 py-4">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
      <div className="flex-1 py-4">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
      <div className="flex-1 py-4">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
      <div className="flex-1 py-4">
        <div className="h-4 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

