import type { AxiosResponse } from 'axios'
import { useEffect, useCallback, useRef } from 'react'
import { useApiCache } from '../context/api-context'

interface HookReturn<T, Q> {
  data: T | null | undefined
  setData: import('react').Dispatch<
    import('react').SetStateAction<T | null | undefined>
  >
  isLoading: boolean
  isFetching: boolean
  isError: boolean
  response: AxiosResponse<T> | undefined
  error: Error | null | unknown
  refetch: (queryParams?: Q) => Promise<void>
  mutate: (
    newData: T | ((prevData: T | null | undefined) => T | null | undefined),
  ) => void
}

interface UseApiFetchOptions {
  cacheKey?: string
  refetchOnFocus?: boolean
  refetchOnMount?: boolean
  refetchOnWindowFocus?: boolean
}
const getQueryParamsAsString = (
  q?: Record<string, string | boolean | number>,
) => {
  if (!q) return '_undefined'
  return Object.entries(q).flatMap(([key, value]) =>
    typeof value === 'boolean'
      ? `_${key}=${value}_`
      : `_${key}=${encodeURIComponent(value)}_`,
  )
}

/**
 * Custom hook to fetch data from an API with caching, loading, and error handling.
 *
 * @template T - The type of the data returned by the API.
 * @template Q - The type of the query parameters for the API function.
 *
 * @param {<K extends Q>(queryParams?: K) => Promise<AxiosResponse<T>>} apiFn - The API function to fetch data.
 * @param {Q} [queryParams] - Optional query parameters for the API function.
 * @param {UseApiFetchOptions} [options] - Optional configuration options for the hook.
 * @param {string} [options.cacheKey] - Custom cache key to store the fetched data.
 * @param {boolean} [options.refetchOnFocus] - Whether to refetch data when the window gains focus.
 * @param {boolean} [options.refetchOnMount] - Whether to refetch data when the component mounts.
 * @param {boolean} [options.refetchOnWindowFocus] - Whether to refetch data when the window visibility changes to visible.
 *
 * @returns {HookReturn<T, Q>} An object containing the fetched data, loading state, error state, and utility functions.
 * @returns {T | null | undefined} data - The fetched data.
 * @returns {import('react').Dispatch<import('react').SetStateAction<T | null | undefined>>} setData - Function to manually set the data.
 * @returns {boolean} isLoading - Whether the data is currently being loaded.
 * @returns {boolean} isFetching - Whether the data is currently being fetched.
 * @returns {boolean} isError - Whether there was an error fetching the data.
 * @returns {AxiosResponse<T> | undefined} response - The Axios response object.
 * @returns {Error | null | unknown} error - The error object if an error occurred.
 * @returns {(queryParams?: Q) => Promise<void>} refetch - Function to refetch the data with optional query parameters.
 * @returns {(newData: T | ((prevData: T | null | undefined) => T | null | undefined)) => void} mutate - Function to manually update the cached data.
 */
export function useApiFetch<
  T,
  Q extends
    | {
        query?: Record<string, string | boolean>
        params?: Record<string, string | number | boolean>
      }
    | undefined,
>(
  apiFn: <K extends Q>(
    queryParams?: K,
  ) => Promise<AxiosResponse<T>> | Promise<T>,
  queryParams?: Q,
  options?: UseApiFetchOptions,
): HookReturn<T, Q> {
  const paramsString = getQueryParamsAsString(queryParams?.params)
  const queryString = getQueryParamsAsString(queryParams?.query)
  const renderCount = useRef(0)
  const { state, dispatch } = useApiCache()
  const derivedCacheKey =
    options?.cacheKey ??
    (paramsString !== '_undefined'
      ? apiFn.name + paramsString + queryString
      : apiFn.name + queryString)

  const cachedData = derivedCacheKey
    ? (state.cache[derivedCacheKey] as T)
    : null
  const cachedLoading = derivedCacheKey ? state.loading[derivedCacheKey] : false
  const cachedError = derivedCacheKey ? state.error[derivedCacheKey] : null

  const fetchData = useCallback(
    async (refetchQuery?: Q) => {
      if (derivedCacheKey) {
        dispatch({ type: 'SET_LOADING', key: derivedCacheKey, loading: true })
      }
      try {
        const response = await apiFn(refetchQuery ?? queryParams)
        if (derivedCacheKey) {
          dispatch({
            type: 'SET_DATA',
            key: derivedCacheKey,
            //@ts-expect-error
            data: response.data ?? response,
          })
        }
      } catch (err) {
        if (derivedCacheKey) {
          dispatch({ type: 'SET_ERROR', key: derivedCacheKey, error: err })
        }
      } finally {
        if (derivedCacheKey) {
          dispatch({
            type: 'SET_LOADING',
            key: derivedCacheKey,
            loading: false,
          })
        }
      }
    },
    [apiFn, queryParams, derivedCacheKey, dispatch],
  )

  const refetch = useCallback(
    (queryParams?: Q) => {
      return fetchData(queryParams)
    },
    [fetchData],
  )

  const mutate = useCallback(
    (
      newData: T | ((prevData: T | null | undefined) => T | null | undefined),
    ) => {
      if (derivedCacheKey) {
        dispatch({
          type: 'SET_DATA',
          key: derivedCacheKey,
          //@ts-expect-error- new shouuld be infered
          data: typeof newData === 'function' ? newData(cachedData) : newData,
        })
      }
    },
    [derivedCacheKey, dispatch, cachedData],
  )

  useEffect(() => {
    if (renderCount.current === 0 && !state.cache[derivedCacheKey]) {
      fetchData()
    }
    return () => {
      renderCount.current += 1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (options?.refetchOnMount && !cachedData && !cachedLoading) {
      fetchData()
    }
  }, [fetchData, cachedData, cachedLoading, options?.refetchOnMount])

  useEffect(() => {
    if (options?.refetchOnFocus) {
      const handleFocus = () => {
        fetchData()
      }
      window.addEventListener('focus', handleFocus)
      return () => {
        window.removeEventListener('focus', handleFocus)
      }
    }
  }, [fetchData, options?.refetchOnFocus])

  useEffect(() => {
    if (options?.refetchOnWindowFocus) {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          fetchData()
        }
      }
      document.addEventListener('visibilitychange', handleVisibilityChange)
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange)
      }
    }
  }, [fetchData, options?.refetchOnWindowFocus])

  return {
    data: cachedData,
    isLoading: cachedLoading,
    isFetching: cachedLoading,
    setData: (data) => {
      if (derivedCacheKey) {
        dispatch({ type: 'SET_DATA', key: derivedCacheKey, data })
      }
    },
    response: undefined,
    isError: !!cachedError,
    error: cachedError,
    refetch,
    mutate,
  }
}
