import React, { useState, useEffect } from 'react';
import { BanknotesIcon } from '@heroicons/react/20/solid';
import { useApp } from '../../../context/usePortal';
import { SkeletonLoader } from '../../../shared/Loader';
import { CURRENCY } from '../../../constants/data';
const statusStyles = {
    success: 'bg-green-100 text-green-800',
    processing: 'bg-yellow-100 text-yellow-800',
    failed: 'bg-gray-100 text-gray-800',
};
const rowsPerPage = 10; // Set the number of rows per page
function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}
const RecentActivities: React.FC = () => {
    let { transactions: ayeTransactions } =
        useApp();
    const [transactions, setTransactions] =
        useState<Awaited<ReturnType<typeof ayeTransactions>>>();
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil((transactions?.length || 0) / rowsPerPage);
    // const user = { name: 'John Doe', email: 'test@test.org' };
    const displayedTransactions = transactions?.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );
    const [tableLoading, setTableLoading] = useState(false);
    useEffect(() => {
        const fetchAndSortTransactions = async () => {
            setTableLoading(true); // Start loading
            try {
                const fetchedTransactions = await ayeTransactions();

                if (Array.isArray(fetchedTransactions)) {
                    // Create a new array to sort, avoiding mutation of the original
                    const sortedTransactions = [...fetchedTransactions].sort((a, b) => {
                        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(); // Replace 'createdAt' with your date property
                    });
                    setTransactions(sortedTransactions);
                } else {
                    console.warn("Fetched transactions are undefined or not an array");
                    setTransactions([]); // Optionally set to an empty array or handle as needed
                }
            } catch (error) {
                console.error("Error fetching transactions:", error);
            } finally {
                setTableLoading(false); // Stop loading
            }
        };

        fetchAndSortTransactions();
    }, [ayeTransactions]);
    const goToNextPage = () => {
        setCurrentPage((prevPage) =>
            prevPage < totalPages ? prevPage + 1 : prevPage
        );
    };

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
    };
    const convertToLocalTime = (inputTime: string): string => {
        // Create a Date object from the input time
        const date = new Date(inputTime);
        // Check if the date is valid
        if (isNaN(date.getTime())) {
            throw new Error("Invalid time format");
        }
        // Convert to local time string
        const localTimeString = date.toLocaleString();
        return localTimeString;
    }
    const formatNumber = (input: number): string => {
        // Round the number to two decimal places
        const formattedNumber = input.toFixed(2);
        // // Pad with leading zeros if necessary
        // const parts = formattedNumber.split('.');
        // const integerPart = parts[0].padStart(2, '0'); // Ensure the integer part has at least 2 digits
        // const decimalPart = parts[1]; // Keep the decimal part as is
        return formattedNumber;
    }
    return (
        <div>
            <h2 className="mx-auto mt-8 pb-6 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                Recent activity
            </h2>

            {/* Activity list (smallest breakpoint only) */}
            <div className="shadow sm:hidden">
                <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {displayedTransactions?.map((transaction: any) => (
                        <li key={transaction.id}>
                            <span
                                className="block bg-white px-4 py-4 hover:bg-gray-50"
                            >
                                <span className="flex items-center space-x-4">
                                    <span className="flex flex-1 space-x-2 truncate">
                                        <BanknotesIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="flex flex-col truncate text-sm text-gray-500">
                                            <span className="truncate">
                                                {transaction.storeName}
                                            </span>
                                            <span>
                                                {transaction.paymentMethod} :{' '}
                                                <span className="font-medium text-gray-900">
                                                    RM
                                                </span>{' '}
                                                {formatNumber(Number(transaction.amount))}
                                            </span>
                                            <time dateTime={convertToLocalTime(transaction.createdAt.toString())}>
                                                {convertToLocalTime(transaction.createdAt.toString())}
                                            </time>
                                        </span>
                                    </span>
                                    {/* <button
                  className="inline-flex items-center justify-center rounded border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  aria-label="Share"
                >
                  <ShareIcon className="h-5 w-5 text-gray-400" />
                </button> */}
                                </span>
                            </span>
                        </li>
                    ))}
                </ul>

                <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    aria-label="Pagination"
                >
                    <div className="block pe-3">
                        <p className="text-sm text-gray-700">
                            Showing{' '}
                            <span className="font-medium">{(currentPage - 1) * rowsPerPage + 1}</span>{' '}
                            to{' '}
                            <span className="font-medium">{Math.min(currentPage * rowsPerPage, transactions?.length || 0)}</span>{' '}
                            of{' '}
                            <span className="font-medium">{transactions?.length || 0}</span> results
                        </p>
                    </div>
                    <div className="flex flex-1 justify-between sm:justify-end">
                        <button
                            type="button"
                            onClick={goToPreviousPage}
                            disabled={currentPage === 1}
                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            onClick={goToNextPage}
                            disabled={currentPage === totalPages}
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                            Next
                        </button>
                    </div>
                </nav>
                {/* 
                <nav
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                    aria-label="Pagination"
                >
                    <div className="flex flex-1 justify-between">
                        <a
                            href="#"
                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                        >
                            Previous
                        </a>
                        <a
                            href="#"
                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500"
                        >
                            Next
                        </a>
                    </div>
                </nav> */}
            </div>

            {/* Activity table (small breakpoint and up) */}
            <div className="hidden sm:block">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                    {tableLoading ? (
                        <div className="mb-5">
                            <SkeletonLoader />
                            <SkeletonLoader />
                        </div>
                    ) : (
                        <div
                            id="transaction-history"
                            className="mt-2 flex flex-col"
                        >
                            <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Transaction
                                            </th>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Method
                                            </th>

                                            <th
                                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Amount
                                            </th>
                                            <th
                                                className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                                                scope="col"
                                            >
                                                Status
                                            </th>
                                            <th
                                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                                scope="col"
                                            >
                                                Date
                                            </th>
                                            {/* <th
                      className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                      scope="col"
                    >
                      Actions
                    </th> */}
                                        </tr>
                                    </thead>

                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {displayedTransactions?.map((transaction) => (
                                            <tr key={transaction.id} className="bg-white">
                                                <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                                    <div className="flex">
                                                        <span
                                                            className="group inline-flex space-x-2 truncate text-sm"
                                                        >
                                                            <BanknotesIcon
                                                                className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="truncate text-gray-500 group-hover:text-gray-900">
                                                                Payment to {transaction.storeName}
                                                            </p>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500">
                                                    <span className="font-medium text-gray-900">
                                                        {transaction.paymentMethod}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                    {CURRENCY}{' '}
                                                    <span className="font-medium text-gray-900">
                                                        {formatNumber(Number(transaction.amount))}
                                                    </span>
                                                </td>
                                                <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                                    <span
                                                        className={classNames(
                                                            statusStyles['success'],
                                                            'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                                        )}
                                                    >
                                                        Success
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                                    <time
                                                        dateTime={convertToLocalTime(transaction.createdAt.toString())}
                                                    >
                                                        {convertToLocalTime(transaction.createdAt.toString())}
                                                    </time>
                                                </td>
                                                {/* <td className="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500">
                        <button
                          id="share-invoice"
                          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        >
                          Share Invoice
                        </button>
                      </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Pagination */}
                                <nav
                                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                                    aria-label="Pagination"
                                >
                                    <div className="hidden sm:block">
                                        <p className="text-sm text-gray-700">
                                            Showing{' '}
                                            <span className="font-medium">{(currentPage - 1) * rowsPerPage + 1}</span>{' '}
                                            to{' '}
                                            <span className="font-medium">{Math.min(currentPage * rowsPerPage, transactions?.length || 0)}</span>{' '}
                                            of{' '}
                                            <span className="font-medium">{transactions?.length || 0}</span> results
                                        </p>
                                    </div>
                                    <div className="flex flex-1 justify-between sm:justify-end">
                                        <button
                                            type="button"
                                            onClick={goToPreviousPage}
                                            disabled={currentPage === 1}
                                            className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                        >
                                            Previous
                                        </button>
                                        <button
                                            type="button"
                                            onClick={goToNextPage}
                                            disabled={currentPage === totalPages}
                                            className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                        >
                                            Next
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecentActivities;
