import { clsx, ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 *
 * @param  {string} inputs
 * @returns
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const debounce = (fn: () => void, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(fn, delay);
  };
};
