import React from 'react';
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { DUITNOW_LOGO_PATH } from "../../../constants/logo";
import { RHB_LOGO_PATH } from "../../../constants/logo";
import { UOB_LOGO_PATH } from "../../../constants/logo";
import { MASTERCARD_LOGO_PATH } from "../../../constants/logo";
import { AMERICAN_EXPRESS_LOGO_PATH } from "../../../constants/logo";
import { TNG_EWALLET_LOGO_PATH } from "../../../constants/logo";
import { VISA_LOGO2_PATH } from "../../../constants/logo";
import { UNION_PAY_LOGO } from "../../../constants/logo";

interface Props {
    selectMethod: (methodId: number) => void; // Define the type for selectMethod prop
}

const FailedPopup: React.FC<Props> = ({ selectMethod }) => {
    const handleSelectMethod = (methodId: number) => {
        selectMethod(methodId);
    };

    return (
        <div>
            <button className='w-full flex justify-between items-center border-2 border-slate-400 rounded-xl p-4 my-2 mb-0' onClick={() => handleSelectMethod(0)} >
                <div>
                    <div className='flex justify-start items-center'>
                        <img src={DUITNOW_LOGO_PATH} alt="Duitnow" className="inline-block h-10 align-middle" />
                        <h5 className='text-md font-bold text-black' >AutoDebit</h5>
                    </div>
                    <div className='flex justify-start items-center mt-2 gap-x-1'>
                        <p className='text-black text-xs font-bold inline-block align-middle '>supported banks:</p>
                        <img src={RHB_LOGO_PATH} alt="Duitnow" className="inline-block  align-middle" />
                        <img src={UOB_LOGO_PATH} alt="Duitnow" className="inline-block  align-middle" />
                    </div>
                </div>
                <div className='w-5 ms-10'>
                    <ChevronRightIcon />
                </div>
            </button>
            <div>
                <p className='text-sm ps-6 mb-4 italic' >more banks available soon</p>
            </div>
            <button className=' w-full flex justify-between items-center border-2 border-slate-400 rounded-xl p-4 my-2' onClick={() => handleSelectMethod(1)} >
                <div>
                    <div className='flex justify-start items-center mb-4 gap-x-1 shadow-sm'>
                        <img src={TNG_EWALLET_LOGO_PATH} alt="Duitnow" className="inline-block h-5  bg-transparent  align-middle" />
                        <img src={MASTERCARD_LOGO_PATH} alt="Duitnow" className="inline-block h-5 rounded-sm  px-2  align-middle" />
                        <img src={VISA_LOGO2_PATH} alt="Duitnow" className="inline-blockh-6 h-5 rounded-sm bg-white px-2 py-1  align-middle" />
                        <img src={UNION_PAY_LOGO} alt="Duitnow" className="inline-block h-5  align-middle" />
                        <img src={AMERICAN_EXPRESS_LOGO_PATH} alt="Duitnow" className="inline-block h-5 align-middle" />
                    </div>

                    <div className='flex justify-start items-center'>
                        <h5 className='text-md font-bold text-black' >eWallet or Credit / Debit Card</h5>
                    </div>
                </div>
                <div className='w-5 ms-10'>
                    <ChevronRightIcon />
                </div>
            </button>
        </div>
    );
};

export default FailedPopup;
