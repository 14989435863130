import React, { useState } from 'react';
import { PhoneNumberInput } from './PhoneNumberInput';
import { styled, } from '@mui/material';
import { PAYNET_LOGO2_PATH } from "../../../constants/logo";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import Switch, { SwitchProps } from '@mui/material/Switch';



const SwitchButton = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    color: '#9A9A9A',
    margin: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#45d62c',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 13,
    height: 13,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));




type CreditDebitComponentProps = {
  setPhoneNumber: (value:string)=>void;
  phoneNumberError: () => boolean;
  handleSubmit: () => void;
  goBackToSelectMethod: () => void;
};
export function DebitCardEWalletPopup({
  setPhoneNumber,
  phoneNumberError,
  handleSubmit,
  goBackToSelectMethod,
}: CreditDebitComponentProps) {
  const [isChecked, setIsChecked] = useState(false);
  // const [switchChecked, setSwitchChecked] = useState(false);
  // const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSwitchChecked(event.target.checked);
  // };
  return (
    <div>
      <button type="button" className='h-5  text-black w-6 mb-1  relative top-[-23px] ' onClick={goBackToSelectMethod} >  <ChevronLeftIcon /></button>
      <h2 className='font-bold text-black text-2xl'>
        eWallet or Credit / Debit Card      </h2  >
      
      <h4 className='text-black text-md my-6' > If you choose Card, make sure to tick this box on the next page to enable payment linking      </h4>
      <div className="flex justify-center items-center my-5">
        <SwitchButton checked={true}  />
        <p className='italic text-sm font-bold ps-3 text-gray-500 '>Save my card details for next purchase</p>
      </div>
      <p className='italic text-xs my-4 ' >Note that a RM1.00 nomimal fee will be charged (and refunded) for authentication purpose</p>
      <form action="" className='my-5' >

        {/* <PhoneNumberInput setPhoneNumber={setPhoneNumber} /> */}
        <div className='flex justify-center items-center my-3' onClick={() => setIsChecked(!isChecked)} >
          <input type="checkbox" placeholder='IC' className='border-1 shadow-sm   focus:outline-none   rounded-lg ' checked={isChecked} />
          <label htmlFor="" className='p-1 font-semibold text-xs left-2'>I have read and agree to the  <a href='https://aye-ai.org/privacy-legal/' className="underline"  target='_blank' >terms and conditions</a> </label>
        </div>
        <button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            isChecked && handleSubmit();
          }}
          className='bg-green-800 mx-auto rounded-3xl py-3 px-9 mt-8 text-center text-white font-bold block'
          style={{ opacity: isChecked ? 1 : 0.5 }}
        >
          Continue
        </button>
      </form>

      <div className='text-center text-sm font-bold text-gray-600 mb-5 mt-6' >
        Authorised by {' '}
        <img src={PAYNET_LOGO2_PATH} alt="Paynet" className='inline h-[14px] align-middle' />
      </div>
    </div>
  );
}

