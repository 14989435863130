import type { MembershipTiers } from '../types/tier'
import { api } from './calls'

/**
 * List membership tiers
 *
 *
 */
const getUserMembershipTiers = async () =>
  await api.get<MembershipTiers | null>(`/tiers`)

/**
 * Get Tier Details by id
 */
const getMembershipTierById = async (paramsQuery?: {
  params: { membership_id: string }
}) => await api.get(`/tiers/${paramsQuery?.params.membership_id}`)

export { getUserMembershipTiers, getMembershipTierById }
