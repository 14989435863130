import { ResponseError, baseURL } from './http-methods'

import { AddPaymentMethodAPI } from './http-methods/add-payment'
import { DeletePaymentMethodAPI } from './http-methods/delete-payment-method'
import { GetOtpAPI } from './http-methods/get-otp'
import { GetPaymentMethodsAPI } from './http-methods/get-payment-methods'
import { GetUserAPI } from './http-methods/get-user'
import { GetUserTransactionsAPI } from './http-methods/get-user-transactions'
import { LoginPortalAPI } from './http-methods/login'
import type { RefreshTokenAPI } from './http-methods/refresh-token'
import { ResetPINAPI } from './http-methods/reset-pin'
import { SaveUserAPI } from './http-methods/save-user-info'
import { SetDefaultAPI } from './http-methods/set-default'
import { UpdatePINAPI } from './http-methods/update-pin'
import { VerifyConsentAPI } from './http-methods/verify-consent'

/* The `export interface API` is defining an interface named `API` that extends multiple other
interfaces (`SetupAccountAPI`, `DetectFaceAPI`, `RegisterCredentialsAPI`, `ConfirmCustomerAPI`,
`OpenGateAPI`, `GetSuggestionsAPI`, `GetItemsAPI`, `SubmitOrderAPI`, `GetVouchersAPI`,
`PayOrderAPI`). This interface represents the collection of all the API endpoints available in the
code. By extending these interfaces, the `API` interface inherits all the properties and methods
defined in each of the individual interfaces. */

export interface API
  extends GetOtpAPI,
    LoginPortalAPI,
    GetUserAPI,
    GetPaymentMethodsAPI,
    GetUserTransactionsAPI,
    SaveUserAPI,
    AddPaymentMethodAPI,
    DeletePaymentMethodAPI,
    VerifyConsentAPI,
    SetDefaultAPI,
    UpdatePINAPI,
    RefreshTokenAPI,
    ResetPINAPI {}

/**
 * The type `HttpDataParams` is a generic type that represents the parameters for making an HTTP POST
 * request with a specific API endpoint.
 * @property method - The `method` property specifies the HTTP method to be used for the request. In
 * this case, it is set to `'POST'`, indicating that the request should be made using the HTTP POST
 * method.
 * @property {string} path - The `path` property is a string that represents the URL path for the HTTP
 * request. It specifies the endpoint or resource that the request should be sent to.
 * @property body - The `body` property is an optional field that represents the request body for the
 * HTTP request. It is of type `Partial<Parameters<API[K]>[0]>['body']`, which means it is a partial
 * type of the first parameter of the corresponding API endpoint function.
 * @property headers - The `headers` property is an optional property that specifies the headers to be
 * included in the HTTP request. It is of type `Partial<Parameters<API[K]>[0]>['headers']`, which means
 * it should be a partial subset of the headers defined in the first parameter of the corresponding API
 */
export type HttpDataParams<K extends keyof API> = {
  method: 'POST' | 'GET' | 'PUT'
  path: string
  body?: Partial<Parameters<API[K]>[0]>['body']
  headers?: Partial<Parameters<API[K]>[0]>['headers']
}

export type HttpResponsePatcher<T> = T & {
  readonly _id: unique symbol
  status: number
}
/**
 * The `HttpRequestData` type is a mapped type that represents the HTTP request data for a given API.
 * @property {HttpDataParams} [: HttpDataParams] - The `HttpDataParams` type represents the parameters
 * that can be passed in an HTTP request
 */
export type HttpRequestData<T extends API> = {
  [K in keyof T]: HttpDataParams<keyof API>
}

// Default Headers
const headers = new Headers()

headers.append('Accept', 'application/json')
headers.append('Content-Type', 'application/json')
/* The `defaults` constant is an object of type `HttpRequestData<API>`. It contains default values for
the HTTP requests that can be made to the API endpoints defined in the `API` interface. */
export const defaults: HttpRequestData<API> = {
  getOTP: {
    method: 'POST',
    path: '/portal/auth/otp',
    headers,
  },
  refreshToken: {
    method: 'POST',
    path: '/portal',
  },
  loginPortal: {
    method: 'POST',
    path: '/portal/auth/login',
    headers,
  },
  getUser: {
    method: 'GET',
    path: '/portal/user',
    headers,
  },
  getPaymentMethods: {
    method: 'GET',
    path: '/portal/payments',
    headers,
  },
  getUserTransactions: {
    method: 'POST',
    path: '/portal/user-transactions',
    headers,
  },
  saveUser: {
    method: 'POST',
    path: '/portal/save-info',
    headers,
  },
  addPaymentMethod: {
    method: 'POST',
    path: '/portal/add-ewallet-payment',
    headers,
  },
  deletePaymentMethod: {
    method: 'POST',
    path: '/portal/payment-methods/delete',
    headers,
  },
  verifyConsent: {
    method: 'POST',
    path: '/rms/consent/verify',
    headers,
  },
  setDefault: {
    method: 'POST',
    path: '/portal/set-default-payment-method',
    headers,
  },
  UpdatePIN: {
    method: 'POST',
    path: '/portal/update-pin',
    headers,
  },
  ResetPIN: {
    method: 'POST',
    path: '/portal/reset-pin',
    headers,
  },
}

/**
 * The `apiHandler` function returns a proxy object that intercepts method calls and performs a request if
 * the method does not exist on the target object.
 * @returns The `apiHandler` function returns a new `Proxy` object that acts as a proxy for the `API`
 * interface.
 */

/**
 * The `apiHandler` function returns a proxy object that intercepts method calls and performs a request if
 * the method does not exist on the target object.
 * @returns The `apiHandler` function returns a new `Proxy` object that acts as a proxy for the `API`
 * interface.
 */
export function apiHandler(API_URL?: string): API {
  return new Proxy<API>({} as API, {
    get<TMethodName extends keyof API>(target: API, p: TMethodName) {
      return async function (
        params: NonNullable<Parameters<API[TMethodName]>[0]>,
      ): Promise<HttpResponsePatcher<ReturnType<API[TMethodName]>>> {
        return parseRequest(target[p], params, defaults[p], API_URL)
      }
    },
  })
}

/**
 * The `parseRequest` function is a TypeScript function that takes in an API method, request arguments,
 * and default parameters, and returns a promise that resolves to a patched HTTP response.
 * @param TMethod - TMethod is a function from the API object. It represents the specific API endpoint
 * that will be called.
 * @param args - The `args` parameter is an object that contains the request body and headers. It has
 * the following structure:
 * @param defaults - The `defaults` parameter is an object that contains default values for the HTTP
 * request. It has the following properties:
 * @returns a Promise that resolves to an object of type `HttpResponsePatcher<ReturnType<typeof
 * TMethod>>`.
 */
export async function parseRequest<T extends keyof API>(
  TMethod: API[T],
  args: Parameters<typeof TMethod>[0],
  defaults: HttpDataParams<T>,
  API_URL?: string,
): Promise<HttpResponsePatcher<ReturnType<typeof TMethod>>> {
  let { body, headers: inputHeaders } = args || {}
  const { path, method, headers: defaultHeaders, body: defaultBody } = defaults
  const headers = new Headers()
  let resolvedPath = path

  if (args && 'pathParams' in args && args.pathParams) {
    // replace  paths params placeholder with actual value
    for (const [key, value] of Object.entries(args.pathParams)) {
      resolvedPath = resolvedPath.replace(`:${key}`, encodeURIComponent(value))
    }
  }

  const url = new URL((API_URL || baseURL) + resolvedPath)
  if (args && 'queryParams' in args && args.queryParams) {
    for (const [key, value] of Object.entries(args.queryParams)) {
      url.searchParams.append(key, value)
    }
  }

  if (inputHeaders) {
    console.log(inputHeaders)
    for (const input of inputHeaders!.entries()) {
      console.log('input method provided')
      headers.append(input[0], input[1])

      console.log(headers)
    }
  }
  if (!headers.get('authorization')) {
    headers.append('Authorization', `Bearer ${localStorage.getItem('token')}`)
  }
  for (const def of defaultHeaders!.entries()) {
    headers.append(def[0], def[1])
  }
  // const token = localStorage.getItem('token');
  // if (token) {
  //   defaultHeaders?.set('Authorization', `Bearer ${token}`);
  // }
  if (defaultBody) {
    body = { ...defaultBody, ...body }
  }
  return new Promise(async (resolve, reject) => {
    console.log((baseURL || API_URL) + path)

    await fetch(url.toString(), {
      method,
      body:
        body! || defaults?.body
          ? JSON.stringify({ ...body!, ...defaults?.body })
          : undefined,
      headers,
    })
      .then(async (response) => {
        const jRes = JSON.parse(await response.text())
        console.log({ jRes })
        if (jRes.detail !== undefined) {
          return reject({
            ...jRes,
            status: response.status,
          } as HttpResponsePatcher<ResponseError>)
        }
        resolve(jRes as HttpResponsePatcher<ReturnType<typeof TMethod>>)
      })
      .catch((error) => {
        console.error(
          'ℹ️ ~ file: http-processor.ts:225 ~ returnnewPromise ~ error:',
          error,
        )
        reject(error.detail || ({ detail: 'unknown error' } as ResponseError))
      })
  }) as HttpResponsePatcher<ReturnType<typeof TMethod>>
}
