//@ts-check
import { cn } from '../../lib/utils';
import fatAssChicken from '../../assets/chick_image.png';
import CoinIcon from '../coinIcon';

interface Props {
  className?: string;
  brandName?: string | undefined;
  isBrand?: boolean | undefined;
  rewardName?: string | undefined;
  rewardPoints?: number | undefined;
  rewardTotalPoints?: number | undefined;
  rewardAvailablePoints?: number | undefined;
  description?: string | undefined;
  image?: string;
  tier?: string;
  noImage?: boolean | undefined;
}

function MembershipCard({
  className,
  brandName = 'AyeFace',
  isBrand = false,
  rewardName = 'Chicken Little',
  rewardPoints = 330,
  rewardTotalPoints = 1000,
  rewardAvailablePoints = 50,
  description = 'Earn points when you shop at AyeFace',
  image,
  noImage = true,
}: Props) {
  return (
    <div className={cn('mx-auto w-full max-w-[calc(100%-2rem)]', className)}>
      <div className='relative overflow-hidden rounded-3xl bg-white px-4 pl-8 shadow-sm drop-shadow-lg'>
        {/* Left gradient bar */}
        <div className='absolute left-0 top-0 h-full w-8 bg-gradient-to-b from-orange-200 to-orange-400'>
          <div className='flex h-full rotate-180 items-center justify-center'>
            <p className='text-md text-center font-medium text-white [writing-mode:vertical-lr]'>
              Membership
            </p>
          </div>
        </div>

        {/* Main content */}
        <div className='relative w-full p-2 py-2 pb-8'>
          <div className='z-20 space-y-2'>
            {/* Title */}
            <div className='flex flex-1 items-center justify-between'>
              <h2 className='flex flex-nowrap items-center gap-x-1 text-lg font-normal text-gray-700 md:text-xl'>
                <span className='text-gray-500'>{brandName}: </span>
                <span className='font-semibold'>{rewardName}</span>
              </h2>
              {/* Points badge */}
              <div className='flex min-w-20 items-center justify-center rounded-lg bg-gray-100 p-1 px-2 text-xs'>
                <span className='flex-1 font-semibold text-gray-700'>
                  RM 1 ={' '}
                </span>
                <div className='relative flex items-center rounded-lg'>
                  <span
                    className={cn(
                      'absolute left-[0.35rem] top-[0.20rem] z-20 text-[0.55rem] font-bold text-white',
                      isBrand && 'text-black'
                    )}
                  >
                    +1
                  </span>
                  {isBrand ? (
                    <CoinIcon />
                  ) : (
                    <svg
                      viewBox='0 0 24 24'
                      fill='currentColor'
                      className='size-6 text-orange-500'
                    >
                      <path d='M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z' />
                    </svg>
                  )}
                </div>
              </div>
            </div>

            <div className=''>
              <div className='relative z-20 flex flex-wrap items-center gap-2'>
                {/* Progress bar */}
                <div className='w-[70%] flex-1 '>
                  <div className='h-1.5 overflow-hidden rounded-full bg-gray-200'>
                    <div
                      className={`h-full w-[${
                        (rewardPoints / rewardTotalPoints) * 100
                      }%] rounded-full bg-gradient-to-r from-orange-400 to-orange-200`}
                      role='progressbar'
                      aria-valuenow={rewardPoints}
                      aria-valuemin={0}
                      aria-valuemax={rewardTotalPoints}
                    />
                  </div>
                </div>
                <div className='font-semibold text-gray-600'>
                  {rewardPoints} / {rewardTotalPoints}
                </div>
              </div>
              <p className='z-10 font-semibold text-gray-500'>{description}</p>

              {/* Points info */}
            </div>
          </div>
          {!noImage && (
            <img
              src={image ?? fatAssChicken}
              alt='Cute baby chick hatching from a lavender egg'
              className='absolute -bottom-4 right-5 z-10 h-full w-32 object-contain'
            />
          )}
          <div className='absolute bottom-2 right-2 z-10 space-y-1 text-sm'>
            <div className='flex items-center justify-end gap-2 text-gray-400'>
              <p className='font-semibold'>Available Points:</p>
              <p className='font-bold text-gray-500'>{rewardAvailablePoints}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipCard;
