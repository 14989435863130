import { useCallback } from 'react';
export type GuardedReturn<T> = {
  isOk: () => boolean;
  response?: T;
  error?: { detail: string; limitReached?: boolean };
};
/**
 * A custom hook that wraps a callback function with additional logic for handling loading state and error state.
 *
 * @template TArgs - The type of the arguments that the callback function accepts.
 * @template TReturn - The type of the return value of the callback function.
 *
 * @param {(...args: TArgs) => TReturn} cb - The callback function to be wrapped.
 * @param {any[]} [dependencies] - An optional array of dependencies for the useCallback hook.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} [loader] - An optional state setter function for managing loading state.
 * @param {React.Dispatch<React.SetStateAction<any>>} [setStateVariable] - An optional state setter function for managing error state.
 *
 * @returns {(...args: TArgs) => Promise<GuardedReturn<Awaited<TReturn>>>} A wrapped version of the callback function that handles loading and error states.
 */
export default function useGuardedCallback<TArgs extends any[], TReturn>(
  cb: (...args: TArgs) => TReturn,
  dependencies?: any[],
  loader?: React.Dispatch<React.SetStateAction<boolean>>,
  setStateVariable?: React.Dispatch<React.SetStateAction<any>>
) {
  return useCallback(
    async (...args: TArgs) => {
      if (loader) {
        loader(true);
      }
      try {
        const response = await cb(...args);
        if (loader) {
          loader(false);
        }
        return {
          isOk: () => true,
          response,
        };
      } catch (e: any) {
        if (loader) {
          loader(false);
        }
        setStateVariable?.(undefined);
        return {
          isOk: () => false,
          error: e,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies || []
  ) as (...args: TArgs) => Promise<GuardedReturn<Awaited<TReturn>>>;
}
