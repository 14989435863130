import React from 'react';
import { useApp } from '../../../context/usePortal';

const Greeting: React.FC = () => {
    // Function to get the current greeting based on local time
    const { user  } = useApp();
    const getGreeting = (): string => {
        const hours = new Date().getHours();
        if (hours < 12) {
            return "Good Morning";
        } else if (hours < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    };

    // Get the greeting message
    const greetingMessage = getGreeting();

    return (
        <h1 className='ml-3 mb-5 xs:text-2xl sm:text-3xl  font-bold leading-7 text-gray-900 sm:truncate sm:leading-9'>             {greetingMessage+ " "+ (user?.name || '') + "!"}
        </h1>

    );
};

export default Greeting;
