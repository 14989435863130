//@ts-check
import { useApp } from '../../context/app-context'
import { useEffect, useState } from 'react'
import { useDebounce } from '../../hooks/useDebounce'
import type { UserI } from '../../types/user'

export const UserProfile = () => {
  const { updateUserInfo, userInfo } = useApp()

  const debounceTrigger = useDebounce(async (value: Partial<UserI>) => {
    await updateUserInfo({ ...userInfo, ...value })
  }, 1000)
  const [userData, setUserData] = useState<Partial<UserI> | null | undefined>(
    userInfo,
  )
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const fieldName = e.target.name

    const fieldValue = e.target.value.trim()
    console.debug(
      'ℹ️ ~ file: user-profile.tsx:23 ~ handleInputChange ~ fieldValue:',
      fieldName,
      fieldValue,
      {
        ...userData,
        [fieldName]: fieldValue,
      },
    )
    if (!userData) return
    setUserData({
      ...userData,
      [fieldName]: fieldValue,
    })
    debounceTrigger({
      ...userData,
      [fieldName]: fieldValue,
    })
  }

  useEffect(() => {
    setUserData({ ...userInfo })
    return () => {
      setUserData({ ...userInfo })
    }
  }, [userInfo])

  return (
    <div className="px-4 py-6 sm:p-6 lg:pb-8">
      <div>
        <h2 className="text-lg font-medium leading-6 text-gray-900">Profile</h2>
        <p className="mt-1 text-sm text-gray-500">
          Some of this information will be displayed on Aye Face App when you
          use it so be careful what you share.
        </p>
      </div>

      <div className="mt-6 flex flex-col lg:flex-row">
        <div className="flex-grow space-y-6">
          <div className="col-span-12 sm:col-span-6">
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700">
              Your name
            </label>
            <input
              type="text"
              name="name"
              id="first-name"
              value={userData?.name}
              onChange={handleInputChange}
              placeholder={userInfo?.name}
              autoComplete="given-name"
              className="mt-1 block w-64 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
            />
          </div>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-12 gap-6">
        <div className="col-span-12 w-64 sm:col-span-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            value={userData?.email}
            id="email"
            onChange={handleInputChange}
            placeholder={
              userInfo?.email ? userInfo?.email : 'Please add your email'
            }
            autoComplete="given-name"
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="mt-2 grid grid-cols-12 gap-6">
        <div className="col-span-12 w-64 sm:col-span-6">
          <label
            htmlFor="first-name"
            className="block text-sm font-medium text-gray-700">
            Birthday
          </label>
          <input
            type="date"
            name="user_birthday"
            value={userData?.user_birthday}
            // value={userData?.email}
            id="date"
            onChange={handleInputChange}
            // onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-sky-500 sm:text-sm"
          />
        </div>
      </div>
    </div>
  )
}
