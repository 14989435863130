import React from 'react';
import { SUCCESS_LOGO_PATH } from "../../../constants/logo";

interface Props {
    onClose: () => void;
    addedPaymentMethod:string;
  }
const SucceedPopup: React.FC<Props> = ({ onClose, addedPaymentMethod }) => {
    return (
        <div className="succeed">
            <img src={SUCCESS_LOGO_PATH}  className='h-32  mx-auto' alt="" />
            <h5 className='text-xl  pb-0 text-center text-green-800 my-2 font-semibold' >Added Successfully  </h5>
            <p className='text-center text-wrap font-semibold' >You have successfully added a new <span className='uppercase text-gray-500 font-bold' >{addedPaymentMethod}</span> payment method to your account.</p>
            <div className="text-center">

                <button className='  mt-5 bg-green-800  rounded-full  order border-transparent  px-6 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-offset-2'   onClick={onClose} >Finish</button>
            </div>

        </div>
    );
};

export default SucceedPopup;
