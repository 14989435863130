import { Fragment, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3CenterLeftIcon,
  BellIcon,
  CogIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  ArrowLeftStartOnRectangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useApp } from '../context/usePortal';

const navigation = [
  { name: 'Payment', href: '/', icon: HomeIcon, current: true },
];

const secondaryNavigation = [
  {
    name: 'Settings',
    href: 'settings',
    id: 'settings',
    icon: CogIcon,
    target: '_self',
  },
  {
    name: 'Help',
    href: 'https://api.whatsapp.com/send?phone=60162666192',
    icon: QuestionMarkCircleIcon,
    target: '_blank',
  },
  {
    name: 'Terms & Conditions',
    href: 'https://aye-ai.org/user-tc/',
    icon: ShieldCheckIcon,
    target: '_blank',
  },
];

const thirdNavigation = [
  { name: 'Logout', icon: ArrowLeftStartOnRectangleIcon },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const logo = process.env.PUBLIC_URL + '/logo.png';

export default function Nav() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useApp();

  const handleLogout = (event: any) => {
    logout();
    navigate('/login');
  };

  const isCurrent = (itemHref: any) => {
    return location.pathname === itemHref;
  };

  return (
    <>
      {/* <div className="min-h-full"> */}
      <Transition.Root
        show={sidebarOpen}
        as={Fragment}
      >
        <Dialog
          as='div'
          className='relative z-40 lg:hidden'
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-50 pt-5 pb-4'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon
                        className='h-6 w-6 text-white'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <img
                    className='h-8 w-auto'
                    src={logo}
                    alt='Aye logo'
                  />
                </div>
                <nav
                  className='mt-5 h-full flex-shrink-0 divide-y divide-gray-100 overflow-y-auto'
                  aria-label='Sidebar'
                >
                  <div className='space-y-1 px-2'>
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        key={item.name}
                        // href={item.href}
                        id={item.name}
                        className={classNames(
                          isCurrent(item.href)
                            ? 'bg-gray-50 text-orange-600 hover:bg-white'
                            : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                          'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                        )}
                        aria-current={isCurrent(item.href) ? 'page' : undefined}
                      >
                        <item.icon
                          className={classNames(
                            isCurrent(item.href)
                              ? 'text-orange-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                          )}
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className='mt-6 pt-6'>
                    <div className='space-y-1 px-2'>
                      {secondaryNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          target={item.target}
                          id={item.id}
                          className={classNames(
                            isCurrent(item.href)
                              ? 'bg-gray-50 text-orange-600 hover:bg-white'
                              : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                            'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent(item.href)
                                ? 'text-orange-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className='mt-6 pt-6'>
                    <div className='space-y-1 px-2'>
                      {thirdNavigation.map((item) => (
                        <a
                          key={item.name}
                          onClick={
                            item.name === 'Logout' ? handleLogout : () => {}
                          }
                          // to={item.name}
                          className={classNames(
                            // item.current
                            //   ? "bg-gray-50 text-orange-600 hover:bg-white"
                            //   :
                            'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                            'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              //   item.current
                              //     ? "text-orange-500"
                              //     :
                              'text-gray-400 group-hover:text-gray-500',
                              'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                            )}
                            aria-hidden='true'
                          />
                          {item.name}
                        </a>
                      ))}
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div
              className='w-14 flex-shrink-0'
              aria-hidden='true'
            ></div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-grow flex-col overflow-y-auto bg-gray-10 pt-5 pb-4'>
          <div className='flex flex-shrink-0 items-center px-4'>
            <img
              className='h-8 w-auto'
              src={logo}
              alt='Aye logo'
            />
          </div>
          <nav
            className='mt-5 flex flex-1 flex-col divide-y divide-gray-100 overflow-y-auto'
            aria-label='Sidebar'
          >
            <div className='space-y-1 px-2'>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    isCurrent(item.href)
                      ? 'bg-gray-50 text-orange-600 hover:bg-white'
                      : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                    'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                  )}
                  aria-current={isCurrent(item.href) ? 'page' : undefined}
                >
                  <item.icon
                    className={classNames(
                      isCurrent(item.href)
                        ? 'text-orange-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                    )}
                    aria-hidden='true'
                  />
                  <span className='truncate'>{item.name}</span>
                </Link>
              ))}
            </div>
            <div className='mt-6 pt-6'>
              <div className='space-y-1 px-2'>
                {secondaryNavigation.map((item) => (
                  <Link
                    key={item.name}
                    id={item.id}
                    to={item.href}
                    target={item.target}
                    className={classNames(
                      isCurrent(item.href)
                        ? 'bg-gray-50 text-orange-600 hover:bg-white'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                      'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        isCurrent(item.href)
                          ? 'text-orange-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className='mt-6 pt-6'>
              <div className='space-y-1 px-2'>
                {thirdNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to=''
                    onClick={item.name === 'Logout' ? handleLogout : () => {}}
                    className={classNames(
                      //   item.current
                      //     ? "bg-gray-50 text-orange-600 hover:bg-white"
                      //     :
                      'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                      'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        // item.current
                        //   ? "text-orange-500"
                        //   :
                        'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                      )}
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className='flex flex-1 flex-col lg:pl-64'>
        <div className='flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none'>
          <button
            type='button'
            className='border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-500 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3CenterLeftIcon
              className='h-6 w-6'
              aria-hidden='true'
            />
          </button>
          <div className='flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8'>
            <div className='flex flex-1'></div>
            <div className='flex justify-between justify-end items-center md:ml-6'>
              <div className='flex items-center'>
                <button
                  type='button'
                  className='rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2'
                >
                  <span className='sr-only'>View notifications</span>
                  <BellIcon
                    className='h-6 w-6'
                    aria-hidden='true'
                  />
                </button>

                {/* <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                      {/* <img
                        className="h-8 w-8 rounded-full"
                        src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      /> 
                      <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                        <span className="sr-only">Open user menu for </span>
                        Profile
                      </span>
                      <ChevronDownIcon
                        className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Logout
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
