import { cn } from '../lib/utils'

const dummyBranchs = [
  {
    name: 'Branch Name',
    address: '5, Jalan SS 2/67, SS 2, 47300 Petaling Jaya, Selangor',
  },
  {
    name: 'Branch Name',
    address: 'Lot LG-18C-03A, Berjaya Times Square, 1' +
      'Jln Imbi, Bukit Bintang, 55100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
  },
  {
    name: 'Branch Name',
    address: '5, Jalan SS 2/67, SS 2, 47300 Petaling Jaya, Selangor',

  },
]

interface Props {
  className?: string
  branches?: typeof dummyBranchs
}

export default function Branches({
  className,
  branches = dummyBranchs,
}: Props) {
  return (
    <div className={ cn('divide-y divide-gray-300', className) }>

      { branches.map((branch, index) =>
        <div key={ index } className="py-4 first:pt-0 last:pb-0">
          <h4 className="font-medium text-md">{ branch.name }</h4>
          <p className="text-sm text-gray-600">
            { branch.address }
          </p>
        </div>) }


    </div>
  )
}