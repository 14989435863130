//@ts-check
import React, {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react'
import {
  deletePaymentMutation,
  updatePaymentMethod,
} from '../../api/payment.api'
import { useApiFetch } from '../../hooks/useApiFetch'
import GeneralModal from '../modal'
import { Carousel, CarouselContent, CarouselItem } from '../ui/carousel'
import { Trash } from 'lucide-react'
import { format } from 'date-fns/format'

import type {
  PaymentConsent,
  PaymentConsents,
} from '../../core/api/http-methods/get-payment-methods'
import DuitnowMethod from '../../pages/home/components/DuitnowMethod'
import EWalletMethod from '../../pages/home/components/EWalletMethod'
import CardMethod from '../../pages/home/components/CardMethod'
import { ayeApi } from '../../core'

interface Props {
  method: PaymentConsent
  showRemove?: boolean
  setData: Dispatch<SetStateAction<PaymentConsents | null>>
  openModal?: (id: string) => void
  handlePreferredMethod: (id?: string, checked?: boolean) => Promise<void>
  handleDelete: (id?: string) => Promise<void>
}

const PaymentMethodCard: React.FC<Props> = ({
  method,
  showRemove = false,
  setData,
  handlePreferredMethod,
  handleDelete,
  openModal = () => {},
}) => {
  console.log('this is the method', method)
  const [isPreferred, setIsPreferred] = useState(method.preferred)

  const handlePreferredChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event.target.checked
    const id = method.payment_consent_id
    console.log('Changing preferred status:', { id, checked })
    setIsPreferred(checked)
    await handlePreferredMethod(id, checked)
  }

  useEffect(() => {
    setIsPreferred(method.preferred)
  }, [method.preferred])

  return (
    <div className="h-full min-h-[13rem] select-none snap-center rounded-xl bg-gradient-to-br from-[#febf45] to-[#f97941] p-4 text-white shadow-lg">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex flex-col gap-y-2">
          <p className="text-xs font-medium">Your Preferred Card</p>
          <div className="flex items-center gap-2">
            {/* Custom Switch */}
            <label className="relative inline-flex cursor-pointer">
              <input
                type="checkbox"
                value=""
                id={`toggle-${method.payment_consent_id}`}
                className="peer sr-only"
                checked={isPreferred}
                disabled={isPreferred}
                onChange={handlePreferredChange}
              />
              <div className="h-5 w-9 rounded-full bg-black/20 after:absolute after:start-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:bg-green-500 peer-checked:after:translate-x-full after:peer-checked:bg-white rtl:peer-checked:after:-translate-x-full"></div>
            </label>
          </div>
        </div>
        <button
          disabled={isPreferred}
          onClick={async (e) => {
            e.preventDefault()
            e.stopPropagation()
            await handleDelete(method.payment_consent_id)
          }}
          className="self-start transition-opacity hover:opacity-80 disabled:hidden">
          <Trash className="h-4 w-4" />
        </button>
      </div>

      <div className="space-y-2">
        <div>
          <p className="text-xs opacity-80">Method</p>
          <p className="font-semibold uppercase">{method.method}</p>
        </div>

        <div className="grid grid-cols-4 gap-2 pl-2 font-mono text-xl">
          <span>****</span>
          <span>****</span>
          <span>****</span>
          <span>{method.card_last4}</span>
        </div>

        <div className="flex space-x-16 text-xs">
          <div>
            <p className="opacity-80">Status</p>
            <p className="font-semibold uppercase">{method.status}</p>
          </div>
          <div>
            <p className="opacity-80">Added</p>
            <p className="font-semibold uppercase">
              {format(new Date(method.created_at), 'yyyy-MM')}
            </p>
          </div>
          <div>
            <p className="opacity-80">Expiry</p>
            <p className="font-semibold uppercase">{method.method}</p>
          </div>
        </div>
      </div>

      {/*<div className="mt-4 flex justify-end">*/}
      {/*  <img*/}
      {/*    src="https://raw.githubusercontent.com/aaronfagan/svg-credit-card-payment-icons/main/flat/visa.svg"*/}
      {/*    alt="Visa"*/}
      {/*    className="h-6 opacity-80"*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  )
}

const PaymentMethods = memo(() => {
  const { data, isLoading, setData } = useApiFetch(
    () => {
      return ayeApi.getPaymentMethods({})
    },
    undefined,
    {
      cacheKey: 'payment_consents',
    },
  )
  const [showRemove, setShowRemove] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [modal, setModal] = useState(false)

  const handleTerminate = () => {
    setShowRemove(!showRemove)
    // Add your close logic here
  }

  const handleModalClose = useCallback(() => {
    setModal(false)
  }, [])

  /**
   * @param {string} id
   * */
  const handleOpen = useCallback((id = '') => {
    setModal(true)
    setSelectedCard(id)
  }, [])

  /**
   * @param {string} id
   * @param  {boolean} checked
   */
  const handlePreferredMethod = useCallback(
    async (id = '', checked = true) => {
      console.debug(
        'ℹ️ ~ file: payment-card.js:204 ~ handlePreferredMethod ~ id:',
        id,
      )
      const prevData = data

      if (data?.payment_consents) {
        const updatedConsents = data.payment_consents?.map((consent) => ({
          ...consent,
          preferred: consent.payment_consent_id === id,
        }))
        setData({ payment_consents: [...updatedConsents] })
      }
      await updatePaymentMethod(id)
        .then(() => {
          console.info('Methods updated.')
          // update the methods optimisticly
        })
        .catch((e) => {
          console.error(e)
          if (prevData) {
            setData({ payment_consents: [...prevData.payment_consents] })
          }
        })
    },
    [data],
  )

  // TODO - Show confirmation dialog before deleting
  const handleDelete = async (id?: string) => {
    const prevData = data
    if (data?.payment_consents) {
      const updatedConsents = data.payment_consents.filter(
        (consent) => consent.payment_consent_id !== id,
      )
      setData({ payment_consents: [...updatedConsents] })
    }
    await deletePaymentMutation(id)
      .then(() => {
        console.info('Methods updated.')
        // update the methods optimisticly
      })
      .catch((e) => {
        console.error(e)
        if (prevData) {
          setData({ payment_consents: [...prevData.payment_consents] })
        }
      })
  }

  if (isLoading) {
    return (
      <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
        <div className="animate-pulse">
          <div className="mb-6 h-6 w-52 rounded bg-gray-200"></div>
          <div className="mb-6 h-8 w-48 rounded bg-gray-200"></div>
          <div className="flex flex-row flex-nowrap space-x-4 overflow-x-auto">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="h-56 min-w-[20rem] basis-[calc(80%_-_0.5rem)] rounded-xl bg-gray-200 sm:basis-[50%] md:basis-1/3 lg:basis-1/5"></div>
            ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-auto flex max-w-full flex-col gap-y-4 px-4 sm:px-6 lg:px-8">
      <Carousel
        opts={{
          align: 'start',
        }}
        className="z-20 max-w-full bg-inherit">
        <CarouselContent className={'items-stretch pl-2'}>
          {data?.payment_consents?.map((method) => (
            <CarouselItem
              key={method.payment_consent_id}
              className={`min-w-[20rem] basis-[calc(80%_-_0.5rem)] select-none sm:basis-[50%] md:basis-1/3 lg:basis-[30%]`}>
              {/* <PaymentMethodCard
                handlePreferredMethod={handlePreferredMethod}
                setData={setData}
                openModal={handleOpen}
                method={method}
                handleDelete={handleDelete}
              /> */}
              {(() => {
                const normalizedMethod = method.method.toLowerCase()
                const handlePaymentMethodsModified = () => {} // Define this function or import it if it's defined elsewhere
                switch (normalizedMethod) {
                  case 'duitnow':
                    return (
                      <DuitnowMethod
                        key={method.payment_consent_id}
                        method={method}
                        handlePaymentMethodsModified={
                          handlePaymentMethodsModified
                        }
                      />
                    )
                  case 'tng-ewallet':
                  case 'tng-grabpay':
                  case 'tng-shopeepay':
                  case 'tng-truemoney':
                  case 'tng-gcash':
                  case 'tng-wechatpay':
                  case 'tng-alipay':
                    return (
                      <EWalletMethod
                        key={method.payment_consent_id}
                        method={method}
                        handlePaymentMethodsModified={
                          handlePaymentMethodsModified
                        }
                      />
                    )
                  case 'card': // we use card for both debit and credit
                  case 'debit':
                  case 'credit':
                    return (
                      <CardMethod
                        key={method.payment_consent_id}
                        method={method}
                        handlePaymentMethodsModified={
                          handlePaymentMethodsModified
                        }
                      />
                    )
                  default:
                    return null
                }
              })()}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <Suspense>
        <GeneralModal
          consentRef={selectedCard}
          open={modal}
          setOpen={setModal}
          onClose={handleModalClose}
          // onAvatarSelect={ handleAvatarSelect }
        />
      </Suspense>
    </div>
  )
})

export { PaymentMethodCard, PaymentMethods }
