import { campaignData as cd } from '../../dummy/campaignData';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Branches from '../branch';

const CampaignDetails = ({ campaignData = cd }) => {
  const params = useParams();
  const getCampaignDetails = (id?: string) => {
    return campaignData.find((campaign) => campaign.campaignId === id);
  };
  const navigate = useNavigate();
  const campaign = getCampaignDetails(params.campaignId);

  useEffect(() => {
    if (!campaign) {
      console.log('Campaign not found');
      navigate(-1);
    }
  }, [campaign]);

  console.log(campaign);
  return campaign ? (
    <section className={'mx-auto w-full max-w-[calc(100%_-_2rem)]'}>
      <div className='flex flex-col gap-y-6'>
        <div className='aspect-video max-h-44 w-full overflow-clip rounded-2xl bg-white p-0 lg:max-h-64'>
          <img
            src={campaign.campaignImageUrl}
            className={'h-full w-full object-contain'}
            alt=''
          />
        </div>
        <div className='flex flex-col gap-y-2 px-4'>
          <p className='text-base font-semibold'>{campaign.title}</p>
          <p
            className={
              'flex items-center justify-start gap-x-2 text-base font-light'
            }
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <mask
                id='mask0_279_5351'
                style={{ maskType: 'alpha' }}
                maskUnits='userSpaceOnUse'
                x='2'
                y='0'
                width='16'
                height='18'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M13.6805 1.52734C13.6805 1.18217 13.4007 0.902344 13.0555 0.902344C12.7103 0.902344 12.4305 1.18217 12.4305 1.52734V2.4301H7.56948V1.52734C7.56948 1.18217 7.28966 0.902344 6.94448 0.902344C6.5993 0.902344 6.31948 1.18217 6.31948 1.52734V2.4301H4.65278C3.46383 2.4301 2.5 3.39394 2.5 4.58288V7.63844V15.2773C2.5 16.4663 3.46383 17.4301 4.65278 17.4301H15.3472C16.5362 17.4301 17.5 16.4663 17.5 15.2773V7.63844V4.58288C17.5 3.39394 16.5362 2.4301 15.3472 2.4301H13.6805V1.52734ZM16.25 7.01344V4.58288C16.25 4.08429 15.8458 3.6801 15.3472 3.6801H13.6805V4.5829C13.6805 4.92808 13.4007 5.2079 13.0555 5.2079C12.7103 5.2079 12.4305 4.92808 12.4305 4.5829V3.6801H7.56948V4.5829C7.56948 4.92808 7.28966 5.2079 6.94448 5.2079C6.5993 5.2079 6.31948 4.92808 6.31948 4.5829V3.6801H4.65278C4.15419 3.6801 3.75 4.08429 3.75 4.58288V7.01344H16.25ZM3.75 8.26344H16.25V15.2773C16.25 15.7759 15.8458 16.1801 15.3472 16.1801H4.65278C4.15419 16.1801 3.75 15.7759 3.75 15.2773V8.26344Z'
                  fill='#1D252D'
                />
              </mask>
              <g mask='url(#mask0_279_5351)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M20 0H0V20H20V0Z'
                  fill='black'
                  fillOpacity='0.5'
                />
              </g>
            </svg>
            <span>13/06/2024</span> - <span>{campaign.expiryDate}</span>
          </p>
        </div>
      </div>
      <div className='mt-4 flex flex-col gap-y-4 px-4'>
        <p className=' '>{campaign.description}</p>
        <p className='text-start text-base font-semibold'>
          Available at selected branches
        </p>
        <Branches />
      </div>
    </section>
  ) : null;
};
export default CampaignDetails;
